import React, { Component } from "react";
import { isEmpty } from "lodash";

import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
//css
import "@fullcalendar/bootstrap/main.css";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
// import DeleteModal from "./DeleteModal";
import { categoryTitles, units } from "../../../constants/diet";
import ResponsiveTables from "pages/Tables/ResponsiveTables";
import { axiosGet } from "helpers/api_helpers";
import { languages } from "constants/userRole";
import Select from "react-select";
import { defaultLanguageSelect } from "constants/common";
import { LIMIT } from "constants/pagination";
import Multiselect from "multiselect-react-dropdown";
import PortionGuide from "./portionGuide";
import DeleteModal from "components/Common/DeleteModal";

class DeitTable extends Component {
  constructor(props) {
    super(props);
    this.calendarComponentRef = React.createRef();

    this.state = {
      calendarWeekends: true,
      modal: false,
      modalcategory: false,
      isDragBind: false,
      deleteModal: false,
      selectedUnit: "ex",
      event: {},
      toEditDietData: {},
      redirectToDietTable: false,
      selectedLanguage: defaultLanguageSelect.value,
      isFilterChanged: false,
      titleFromCategories: "",
      languageOptions: [],
      dietTypeOptions: [],
      selectedDietTypeOptions: [],
      mealPlanData: {},
      showPortionGuide: false,
      deleteDietType: false,
      deletedDietType: "",
      selectedDietTypes: [],
      isDietTypeDeleted: false,
    };
  }

  async componentDidMount() {
    const userId = this.props.match.params.customerId;
    const dietId = this.props.match.params.dietId;
    this.getPortionGuide();
    await this.getLanguages();
    await this.getCategories();
    if (dietId) {
      this.getDietById(dietId);
    }
  }

  async getLanguages() {
    try {
      const languages = await axiosGet(`/languages?page=1&limit=${LIMIT}`);
      if (languages?.message) {
        this.setState({ errorMsg: languages?.message });
      } else {
        if (languages?.results) {
          const options = [
            { ...defaultLanguageSelect },
            ...languages?.results?.map(dietitian => ({
              value: dietitian?.key,
              label: dietitian?.name,
            })),
          ];
          this.setState({ languageOptions: options });
        }
      }
    } catch (error) {
      console.error("error getLanguages: ", error);
    }
  }

  async getPortionGuide() {
    try {
      const response = await axiosGet("/portion-guide");
      if (response?.message) {
        console.error("response error in getPortionGuide: ", response?.message);
      } else {
        this.setState({ mealPlanData: response });
      }
    } catch (error) {
      console.error("error getPortionGuide: ", error);
    }
  }

  async getCategories() {
    try {
      const categories = await axiosGet(`/categories?page=1&limit=${LIMIT}`);
      if (categories?.message) {
        this.setState({ errorMsg: languages?.message });
      } else {
        if (categories?.results) {
          const options = [
            ...categories?.results?.map((category, index) => ({
              value: category?.key,
              label: category?.name,
            })),
          ];
          this.setState({ dietTypeOptions: options });
        }
      }
    } catch (error) {
      console.error("error getCategories: ", error);
    }
  }

  handleRadioChange = unit => {
    this.setState({ selectedUnit: unit, isFilterChanged: true });
  };

  handleLanguageChange = language => {
    this.setState({ selectedLanguage: language, isFilterChanged: true });
  };

  getDietById = async id => {
    try {
      const response = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/userdiets/${id}`
      );
      if (!response?.message) {
        this.setState({ toEditDietData: response });
        if (response?.language) {
          this.setState({ selectedLanguage: response?.language });
        }
        if (response?.dietTypes)
          if (response.totalExValue) {
            this.setState({ selectedUnit: units.EX });
          } else if (response.totalCalories) {
            this.setState({ selectedUnit: units.CALORIES });
          }
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleRedirectToCustomerDiets = () => {
    this.props.history.push(`/customers/${this.props.match.params.customerId}`);
  };

  updateState = bool => {
    this.setState({ isFilterChanged: bool });
  };

  updateRemovedDeletedDietType = () => {
    this.setState({ deletedDietType: "" });
  };

  updateIsDietTypeDeleted = bool => {
    this.setState({ isDietTypeDeleted: bool });
  };

  updateSelectedOptions = options => {
    this.setState({ selectedDietTypeOptions: options });
  };

  handleTitleFromCategories = () => {
    const selectedTitles = this.state.selectedDietTypeOptions.map(
      dietType => dietType?.label
    );
    this.setState({
      titleFromCategories:
        selectedTitles.length !== 0 ? `${selectedTitles.join("-")} Diet` : "",
    });
  };

  portionGuideModalToggle = () => {
    this.setState({ showPortionGuide: !this.state.showPortionGuide });
  };

  onRemove = selectedList => {
    // const dietTypes = selectedList?.map(selectedItem => selectedItem?.value);
    this.setState(
      {
        // selectedDietTypeOptions: selectedList,
        // isFilterChanged: true,
        deleteDietType: false,
        isDietTypeDeleted: true,
      },
      () => this.handleTitleFromCategories()
    );
  };

  render() {
    //meta title
    document.title = "Create Diet | Diet Delights";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid={true}>
            {/* Render Breadcrumb */}
            <Breadcrumbs
              title="Diet Delights"
              breadcrumbItem={`${
                this.props.match.params.dietId
                  ? this.state.toEditDietData?.title
                  : "Diet-Table"
              }`}
            />
            <div className="ms-1 d-flex justify-content-between">
              <div>
                <label className="fs-4">
                  <i className="mdi mdi-filter-variant me-1"></i>
                  Filter
                </label>
                <div className="d-flex align-items-center my-3">
                  <label>Preferred Language :</label>
                  <div className="ms-4 ps-2 ml-2rem" style={{ width: "40%" }}>
                    <Select
                      name="language"
                      value={this.state.languageOptions.reduce(
                        (acc, val) => {
                          return val?.value === this.state.selectedLanguage
                            ? val
                            : acc;
                        },
                        { ...defaultLanguageSelect }
                      )}
                      onChange={selectedOption => {
                        this.handleLanguageChange(selectedOption.value);
                      }}
                      options={this.state.languageOptions}
                    />
                  </div>
                  <div className="text-sm-end ms-5">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary py-1 px-2 d-flex align-items-center"
                      onClick={() => this.props.history.push("/settings")}
                    >
                      <i className="mdi mdi mdi-plus fs-5" />
                      <span>Add Language</span>
                    </Button>
                  </div>
                </div>
                <div className="d-flex align-items-center my-2">
                  <label className="pe-3">Diet Type :</label>
                  {/* <Label className="form-label me-5 pe-3"></Label> */}

                  {/* <div className="mb-3 d-flex align-items-center w-100"> */}
                  <Multiselect
                    className="ms-5 ps-5 w-75"
                    options={this.state.dietTypeOptions}
                    selectedValues={this.state.selectedDietTypeOptions}
                    onSelect={(selectedList, selectedItem) => {
                      const dietTypes = selectedList?.map(
                        selectedItem => selectedItem?.value
                      );
                      this.setState(
                        {
                          selectedDietTypeOptions: selectedList,
                          isFilterChanged: true,
                        },
                        () => this.handleTitleFromCategories()
                      );
                    }}
                    onRemove={(selectedList, removedItem) => {
                      // this.setState({
                      //   deleteDietType: true,
                      //   deletedDietType: selectedList,
                      // });
                      this.setState({ deletedDietType: removedItem });
                      const dietTypes = selectedList?.map(
                        selectedItem => selectedItem?.value
                      );
                      this.setState(
                        {
                          selectedDietTypeOptions: selectedList,
                          selectedDietTypes: this.state.selectedDietTypeOptions,
                          deleteDietType: true,
                          isFilterChanged: true,
                        },
                        () => this.handleTitleFromCategories()
                      );
                    }}
                    displayValue="label"
                    showCheckbox={true}
                    showArrow={true}
                  />
                  <DeleteModal
                    show={this.state.deleteDietType}
                    onDeleteClick={() =>
                      this.onRemove(this.state.deletedDietType)
                    }
                    onCloseClick={() => {
                      this.setState({
                        selectedDietTypeOptions: this.state.selectedDietTypes,
                        isDietTypeDeleted: false,
                        deleteDietType: false,
                      });
                    }}
                  />
                  <div className="text-sm-end ms-3">
                    <Button
                      color="primary"
                      className="font-16 btn-block btn btn-primary py-1 px-2 d-flex align-items-center"
                      onClick={() => this.props.history.push("/settings")}
                    >
                      <i className="mdi mdi mdi-plus fs-5" />
                      <span>Add Diet Type</span>
                    </Button>
                  </div>
                  {/* </div> */}
                </div>
              </div>

              <div className="">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary ms-2 me-2 "
                  onClick={() => this.setState({ showPortionGuide: true })}
                >
                  <i className="mdi mdi-information me-1" />
                  <span>Portion Guide</span>
                </Button>
                <Button
                  color="primary me-2"
                  className="font-16 btn-block btn btn-primary"
                  onClick={
                    () =>
                      this.props.history.push(
                        `/customers/${this.props.match.params.customerId}`
                      ) // Navigate to the root URL
                  }
                >
                  <i className="mdi mdi-arrow-left-bold-circle me-1" />
                  Back
                </Button>
              </div>
            </div>
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ResponsiveTables
                      // selectedUnit={this.state.selectedUnit}
                      titleFromCategories={this.state.titleFromCategories}
                      selectedLanguage={this.state.selectedLanguage}
                      selectedDietTypeOptions={
                        this.state.selectedDietTypeOptions
                      }
                      userId={this.props.match.params.customerId}
                      dietId={this.props.match.params.dietId}
                      isFilterChanged={this.state.isFilterChanged}
                      updateState={this.updateState}
                      redirectToCustomerDiet={
                        this.handleRedirectToCustomerDiets
                      } // Pass the function as a prop
                      updateOptions={this.updateSelectedOptions}
                      deletedDietType={this.state.deletedDietType}
                      updateRemovedDeletedDietType={
                        this.updateRemovedDeletedDietType
                      }
                      isDietTypeDeleted={this.state.isDietTypeDeleted}
                      updateIsDietTypeDeleted={this.updateIsDietTypeDeleted}
                      deleteDietType={this.state.deleteDietType}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={this.state.showPortionGuide}
          className="portion-guide-table"
        >
          <ModalHeader toggle={this.portionGuideModalToggle} tag="h4">
            Portion Guide
          </ModalHeader>
          <ModalBody>
            <PortionGuide mealPlanData={this.state.mealPlanData} />
          </ModalBody>
        </Modal>
      </React.Fragment>
    );
  }
}

export default DeitTable;
