import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { withRouter, Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

// users
// import user1 from "../../../assets/images/users/avatar-1.jpg";
import logoAvatar from "../../../assets/images/logo-avatar.png";

import { connect } from "react-redux";
import { userRoles } from "../../../constants/userRole";

const getUserName = () => {
  if (sessionStorage.getItem("authUser")) {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    return obj;
  }
};

const encodedData = new URLSearchParams();

class ProfileMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      name: "",
      encodedQuery: {},
      userData: {},
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu,
    }));
  }

  componentDidMount() {
    const user = getUserName();
    if (user) {
      this.setState({ name: user?.username });
    }

    const userData = JSON.parse(sessionStorage.getItem("user"));
    const accessToken = JSON.parse(sessionStorage.getItem("accessToken"));
    const refreshToken = JSON.parse(sessionStorage.getItem("refreshToken"));
    encodedData.append(
      "data",
      JSON.stringify({
        userData: {
          ...userData,
          role: userRoles.DIETITIAN,
        },
        accessToken,
        refreshToken,
      })
    );

    const encodedQuery = encodedData.toString();
    this.setState({ encodedQuery, userData });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.success !== this.props.success) {
      const userData = getUserName();
      if (userData) {
        this.setState({ name: userData.username });
      }
    }
  }

  render() {
    const { userData } = this.state;
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="d-inline-block"
        >
          <DropdownToggle
            className="btn header-item"
            id="page-header-user-dropdown"
            tag="button"
          >
            <img
              className="rounded-circle header-profile-user"
              src={logoAvatar}
              alt="Header Avatar"
            />{" "}
            <span className="d-none d-xl-inline-block ms-1">
              {this.state.name}
            </span>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem tag="a" href="/profile">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              {this.props.t("Profile")}
            </DropdownItem>
            {/* <DropdownItem tag="a" href="/crypto-wallet">
              <i className="bx bx-wallet font-size-16 align-middle me-1" />
              {this.props.t("My Wallet")}
            </DropdownItem>
            <DropdownItem tag="a" href="#">
              <span className="badge bg-success float-end">11</span>
              <i className="bx bx-wrench font-size-17 align-middle me-1" />
              {this.props.t("Settings")}
            </DropdownItem>
            <DropdownItem tag="a" href="auth-lock-screen">
              <i className="bx bx-lock-open font-size-16 align-middle me-1" />
              {this.props.t("Lock screen")}
            </DropdownItem> */}
            {userData?.role === userRoles.ADMIN ? (
              <>
                <div className="dropdown-divider" />
                <Link
                  to={`/login?data=${this.state.encodedQuery}`}
                  className="dropdown-item"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 1024 1024"
                    className="me-1"
                  >
                    <path
                      fill="currentColor"
                      d="M118.656 438.656a32 32 0 0 1 0-45.248L416 96l4.48-3.776A32 32 0 0 1 461.248 96l3.712 4.48a32.064 32.064 0 0 1-3.712 40.832L218.56 384H928a32 32 0 1 1 0 64H141.248a32 32 0 0 1-22.592-9.344zM64 608a32 32 0 0 1 32-32h786.752a32 32 0 0 1 22.656 54.592L608 928l-4.48 3.776a32.064 32.064 0 0 1-40.832-49.024L805.632 640H96a32 32 0 0 1-32-32z"
                    />
                  </svg>
                  <span>{this.props.t("Switch as Dietitian")}</span>
                </Link>
              </>
            ) : (
              <></>
            )}
            <div className="dropdown-divider" />
            <Link to="/logout" className="dropdown-item">
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
              <span>{this.props.t("Logout")}</span>
            </Link>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string,
};

const mapStateToProps = state => {
  const { success } = state.Profile;
  return { success };
};

export default withRouter(
  connect(mapStateToProps, {})(withTranslation()(ProfileMenu))
);
