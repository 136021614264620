import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../../assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { userRoles } from "constants/userRole";
import { LIMIT, SIZE_PER_PAGE } from "../../../constants/pagination";

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      user: "",
      userData: JSON.parse(sessionStorage.getItem("user")),
      customerList: [], // Initialize customerList state
      modal: false,
      deleteModal: false,
      errorMsg: "",
      selectUserBydietitian: "",
      contactListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        {
          dataField: "img",
          text: "#",
          formatter: (cellContent, user) => (
            <>
              {!user.img ? (
                <div className="avatar-xs">
                  <span className="avatar-title rounded-circle">
                    {user?.name.charAt(0)}
                  </span>
                </div>
              ) : (
                <div>
                  <img
                    className="rounded-circle avatar-xs"
                    src={images[user.img]}
                    alt=""
                  />
                </div>
              )}
            </>
          ),
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {user?.name}
                </Link>
              </h5>
              <p className="text-muted mb-0">{user.designation}</p>
            </>
          ),
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
        },
        {
          dataField: "phoneNumber",
          text: "Phone Number",
          sort: true,
        },
        {
          dataField: "city",
          text: "City",
          sort: true,
        },
        // {
        //   dataField: "role",
        //   text: "Role",
        // },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
    this.getCustomers();
    this.setState({ users });
  }

  async getCustomers() {
    const customerList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/users?page=1&limit=${LIMIT}&role=${userRoles.USER}`
    );
    this.setState({ customerList: customerList?.results }); // Set the customerList in the state
  }

  async handleFilter() {
    const customerList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/users?createdBy=${this.state.userData.id}&page=1&limit=${LIMIT}&role=${userRoles.USER}`
    );
    this.setState({ customerList: customerList?.results }); // Set the customerList in the state
  }

  handleCheckboxChange = () => {
    this.setState(
      prevState => ({
        selectUserBydietitian: !prevState.selectUserBydietitian,
      }),
      () => {
        this.handleFilter();
        if (!this.state.selectUserBydietitian) {
          this.getCustomers();
        }
      }
    );
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks() {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  /* Insert,Update Delete data */

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  onClickDelete(users) {
    this.setState({ users: users });
    this.setState({ deleteModal: true });
  }

  handleUserClick(arg) {
    const user = arg;

    this.setState({
      user: {
        id: user.id,
        name: user.name,
        designation: user.designation,
        email: user.email,
        tags: user.tags,
        projects: user.projects,
        phoneNumber: parseInt(user.phoneNumber, 10),
        role: user.role,
        password: user.password,
        weight: user.weight,
        address: user.address,
        city: user.city,
      },
      isEdit: true,
    });

    this.toggle();
  }

  async handleAddUser(data) {
    const response = await axiosPost(
      `${process.env.REACT_APP_BASE_URL}/users`,
      data
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if ((this, this.state.selectUserBydietitian)) {
        this.handleFilter();
      } else {
        this.getCustomers();
      }
      this.toggle();
    }
  }

  async handleUpdateUser(id, data) {
    const response = await axiosPatch(
      `${process.env.REACT_APP_BASE_URL}/users/${id}`,
      data
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if ((this, this.state.selectUserBydietitian)) {
        this.handleFilter();
      } else {
        this.getCustomers();
      }
      this.toggle();
    }
  }

  async handleDeleteUser(id) {
    const response = await axiosDel(
      `${process.env.REACT_APP_BASE_URL}/users/${id}`
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if ((this, this.state.selectUserBydietitian)) {
        this.handleFilter();
      } else {
        this.getCustomers();
      }
      this.setState({ deleteModal: false });
    }
  }

  render() {
    //meta title
    document.title = "User List";

    // const { users } = this.state
    const { SearchBar } = Search;

    const { isEdit, deleteModal, customerList: users } = this.state;

    const { onAddNewUser, onUpdateUser } = this.props;
    const user = this.state.user;

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: users?.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteUser(this.state.users.id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Diet Delights" breadcrumbItem="Customers" />
            <div className="ms-1">
              <label>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter :
              </label>
              <div className="d-flex align-items-center">
                <label>Created by me:</label>
                <input
                  type="checkbox"
                  className="ms-2 mb-2"
                  checked={this.state.selectUserBydietitian}
                  onChange={this.handleCheckboxChange}
                ></input>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.contactListColumns}
                      data={users}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.contactListColumns}
                          data={users}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col sm="4">
                                  <div className="search-box ms-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleUserClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Customer
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    {/* {this.state.customerList.length === 0 ? <div className="d-flex justify-content-center">
                                      <img src={emptyContacts} alt="empty-conatct"></img>
                                    </div> : */}
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      data={this.state.customerList} // Use customerList from the state
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                    {/* }  */}

                                    <Modal
                                      isOpen={this.state.modal}
                                      className={this.props.className}
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {!!isEdit ? "Edit User" : "Add User"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            name: (user && user.name) || "",
                                            email: (user && user.email) || "",
                                            phoneNumber:
                                              (user && user.phoneNumber) || "",
                                            weight: (user && user.weight) || "",
                                            address:
                                              (user && user.address) || "",
                                            city: (user && user.city) || "",
                                            password: "",
                                          }}
                                          validationSchema={
                                            isEdit
                                              ? Yup.object().shape({
                                                  name: Yup.string().required(
                                                    "Please Enter Customer Name"
                                                  ),
                                                  phoneNumber:
                                                    Yup.number().required(
                                                      "Please Enter Phone Number"
                                                    ),
                                                  weight: Yup.string().required(
                                                    "Please Enter weight"
                                                  ),
                                                  address:
                                                    Yup.string().required(
                                                      "Please Enter address"
                                                    ),
                                                  city: Yup.string().required(
                                                    "Please Enter city name"
                                                  ),
                                                })
                                              : Yup.object().shape({
                                                  name: Yup.string().required(
                                                    "Please Enter Customer Name"
                                                  ),
                                                  email: Yup.string().required(
                                                    "Please Enter Customer Email"
                                                  ),
                                                  phoneNumber:
                                                    Yup.number().required(
                                                      "Please Enter Phone Number"
                                                    ),
                                                  password:
                                                    Yup.string().required(
                                                      "Please Enter the password"
                                                    ),
                                                  weight: Yup.string().required(
                                                    "Please Enter weight"
                                                  ),
                                                  address:
                                                    Yup.string().required(
                                                      "Please Enter address"
                                                    ),
                                                  city: Yup.string().required(
                                                    "Please Enter city name"
                                                  ),
                                                })
                                          }
                                          onSubmit={values => {
                                            if (isEdit) {
                                              if (
                                                user?.phoneNumber !==
                                                  values.phoneNumber ||
                                                user?.name !== values?.name ||
                                                user?.weight !==
                                                  values?.weight ||
                                                user?.address !==
                                                  values?.address ||
                                                user?.city !== values?.city
                                              ) {
                                                const updateUser = {
                                                  name: values.name,
                                                  phoneNumber: `${values["phoneNumber"]}`,
                                                  weight: values["weight"],
                                                  address: values["address"],
                                                  city: values["city"],
                                                  updatedBy:
                                                    this.state.userData.id,
                                                };
                                                this.handleUpdateUser(
                                                  user?.id,
                                                  updateUser
                                                );
                                              } else {
                                                this.toggle();
                                              }
                                            } else {
                                              const newUser = {
                                                name: values["name"],
                                                email: values["email"],
                                                phoneNumber: `${values["phoneNumber"]}`,
                                                role: userRoles.USER,
                                                weight: values["weight"],
                                                address: values["address"],
                                                password: values["password"],
                                                city: values["city"],
                                                createdBy:
                                                  this.state.userData.id,
                                              };
                                              // save new user
                                              this.handleAddUser(newUser);
                                            }
                                            this.setState({
                                              selectedUser: null,
                                            });
                                            // this.toggle();
                                          }}
                                        >
                                          {({ errors, status, touched }) => (
                                            <Form>
                                              {this.state.errorMsg ? (
                                                <span className="text-danger ms-2">
                                                  {this.state.errorMsg}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Name
                                                    </Label>
                                                    <Field
                                                      name="name"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.name &&
                                                        touched.name
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="name"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  {isEdit ? (
                                                    ""
                                                  ) : (
                                                    <div className="mb-3">
                                                      <Label className="form-label">
                                                        Email
                                                      </Label>
                                                      <Field
                                                        name="email"
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors.email &&
                                                          touched.email
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </div>
                                                  )}
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Phone Number
                                                    </Label>
                                                    <Field
                                                      name="phoneNumber"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.phoneNumber &&
                                                        touched.phoneNumber
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="phoneNumber"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Weight (In kg)
                                                    </Label>
                                                    <Field
                                                      name="weight"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.weight &&
                                                        touched.weight
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="weight"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      City
                                                    </Label>
                                                    <Field
                                                      name="city"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.city &&
                                                        touched.city
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="city"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Address
                                                    </Label>
                                                    <Field
                                                      name="address"
                                                      as="textarea"
                                                      className={
                                                        "form-control" +
                                                        (errors.address &&
                                                        touched.address
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="address"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  {isEdit ? (
                                                    ""
                                                  ) : (
                                                    <div className="mb-3">
                                                      <Label className="form-label">
                                                        Password
                                                      </Label>
                                                      <Field
                                                        name="password"
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors.password &&
                                                          touched.password
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name="password"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      Save
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

ContactsList.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactsList));
