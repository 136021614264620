import React from "react";
import { Component } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { connect } from "react-redux";
import { setIngredients } from "../../../store/actions"; // Import your action
import PropTypes from "prop-types";

class Ingredients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredientsSaved: false,
      errorMsg: "",
      totalQuantity: 0,
      totalCalories: 0,
    };
  }

  calculateTotal = ingredients => {
    let totalQuantity = 0;
    let totalCalories = 0;

    // Iterate through items
    for (const key in ingredients) {
      if (ingredients.hasOwnProperty(key)) {
        const itemList = ingredients[key];

        // Iterate through items in each list
        // Extract numerical values from quantity and calories
        const quantityValue = parseFloat(itemList?.quantity);
        const caloriesValue = parseFloat(itemList?.calories);

        // Check if the extracted values are valid numbers
        if (!isNaN(quantityValue)) {
          totalQuantity += quantityValue;
        }

        if (!isNaN(caloriesValue)) {
          totalCalories += caloriesValue;
        }
      }
    }
    this.setState({ totalCalories, totalQuantity });
    return {
      totalQuantity,
      totalCalories,
    };
  };

  render() {
    const { ingredients, setIngredients } = this.props;

    return (
      <Formik
        enableReinitialize
        initialValues={{
          rows: ingredients || [{}],
        }}
        onSubmit={values => {
          // Validate if there is at least one ingredient
          if (
            values.rows.length === 0 ||
            Object.keys(values.rows[0]).length === 0
          ) {
            // this.setState({ ingredientsSaved: true });
            this.setState({
              errorMsg: "Please enter ingredients before submitting.",
              ingredients: false,
            });
          } else {
            // Dispatch the action to save the 'rows' data to Redux
            this.setState({ ingredientsSaved: true, errorMsg: "" });
            setIngredients(values.rows);
            this.calculateTotal(values.rows);
            // Handle form submission here
          }
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Table className="table table-striped table-bordered">
              <Thead>
                <tr>
                  <th data-priority="1">Ingredients</th>
                  <th data-priority="1">Quantity (in g)</th>
                  <th data-priority="1">Calories (in cal)</th>
                  <th data-priority="1">Actions</th>
                </tr>
              </Thead>
              <Tbody>
                {values.rows.map((row, index) => (
                  <Tr key={index}>
                    <Td>
                      <Field
                        type="text"
                        name={`rows[${index}].name`}
                        value={row.name}
                        onChange={handleChange}
                        disabled={this.state.ingredientsSaved}
                        className={
                          "form-control" +
                          (errors[`rows[${index}].name`] &&
                          touched[`rows[${index}].name`]
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name={`rows[${index}].name`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </Td>
                    <Td>
                      <Field
                        type="text"
                        name={`rows[${index}].quantity`}
                        value={row.quantity}
                        onChange={handleChange}
                        disabled={this.state.ingredientsSaved}
                        className={
                          "form-control" +
                          (errors[`rows[${index}].quantity`] &&
                          touched[`rows[${index}].quantity`]
                            ? " is-invalid"
                            : "")
                        }
                      />

                      <ErrorMessage
                        name={`rows[${index}].quantity`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </Td>
                    <Td>
                      <Field
                        type="text"
                        name={`rows[${index}].calories`}
                        value={row.calories}
                        onChange={handleChange}
                        disabled={this.state.ingredientsSaved}
                        className={
                          "form-control" +
                          (errors[`rows[${index}].calories`] &&
                          touched[`rows[${index}].calories`]
                            ? " is-invalid"
                            : "")
                        }
                      />

                      <ErrorMessage
                        name={`rows[${index}].calories`}
                        component="div"
                        className="invalid-feedback"
                      />
                    </Td>
                    <Td>
                      <i
                        className="mdi mdi-minus-box font-size-24 text-danger"
                        onClick={() => {
                          values.rows.splice(index, 1);
                          setFieldValue("rows", values.rows); // Update the 'rows' field
                        }}
                      ></i>
                    </Td>
                  </Tr>
                ))}
                <Tr>
                  <Th>Total</Th>
                  <Td>{this.state.totalQuantity}</Td>
                  <Td>{this.state.totalCalories}</Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
            <div className="d-flex justify-content-between mb-2">
              <div>
                <button
                  type="button"
                  className="btn btn-success me-2"
                  onClick={() => {
                    values.rows.push({
                      name: "",
                      quantity: "",
                      calories: "",
                    });
                    setFieldValue("rows", values.rows); // Update the 'rows' field
                  }}
                >
                  Add Row
                </button>
                {!this.state.ingredientsSaved ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.setState({ ingredientsSaved: false })}
                  >
                    Edit
                  </button>
                )}
              </div>
              <div>
                <span className="text-danger">
                  {this.state.errorMsg ? this.state.errorMsg : ""}
                </span>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

Ingredients.propTypes = {
  ingredients: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    ingredients: state.Recipe.ingredients, // Assuming your Redux state has a 'ingredients' property
  };
};

const mapDispatchToProps = {
  setIngredients, // This connects the setIngredients action to your component
};

export default connect(mapStateToProps, mapDispatchToProps)(Ingredients);
