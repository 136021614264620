import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import {
  setIngredients,
  setShowItemModal,
  editItems,
  setNutriInfo,
  setItems,
} from "../../store/actions";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { userRoles } from "constants/userRole";
import { LIMIT, SIZE_PER_PAGE } from "../../constants/pagination";
import { categoryShow } from "constants/diet";

class RecipeList extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      recipe: {},
      userData: JSON.parse(sessionStorage.getItem("user")),
      recipeList: [], // Initialize recipeList state
      deleteModal: false,
      errorMsg: "",
      selectRecipeByAdmin: "",
      contactListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, recipe) => <>{recipe.id}</>,
        },
        {
          text: "Title",
          dataField: "title",
          sort: true,
          formatter: (cellContent, recipe) => (
            <>
              <h5 className="font-size-14 mb-1">
                <Link to="#" className="text-dark">
                  {recipe?.title}
                </Link>
              </h5>
            </>
          ),
        },
        {
          dataField: "category",
          text: "Category",
          sort: true,
          formatter: (cellContent, recipe) => (
            <>
              <div className="font-size-14 mb-1">
                {categoryShow[recipe?.category]}
              </div>
            </>
          ),
        },
        {
          dataField: "subCategory",
          text: "Sub Category",
        },
        {
          dataField: "typeOfCuisine",
          text: "Type of Cuisine",
        },
        {
          dataField: "typeOfMeal",
          text: "Type of Meal",
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, recipe) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => {
                    this.props.history.push(`/recipe-list/edit/${recipe?._id}`);
                  }}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(recipe)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    this.getRecipes();
  }

  async getRecipes() {
    const recipeList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/recipes`
    );
    this.setState({ recipeList: recipeList?.recipes }); // Set the recipeList in the state
  }

  async handleFilter() {
    const recipeList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/recipes?createdBy=${this.state.userData.id}`
    );
    this.setState({ recipeList: recipeList?.recipes }); // Set the recipeList in the state
  }

  handleCheckboxChange = () => {
    this.setState(
      prevState => ({
        selectRecipeByAdmin: !prevState.selectRecipeByAdmin,
      }),
      () => {
        this.handleFilter();
        if (!this.state.selectRecipeByAdmin) {
          this.getRecipes();
        }
      }
    );
  };

  handleUserClicks() { }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  /* Insert,Update Delete data */

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  onClickDelete(recipe) {
    this.setState({ deleteModal: true, recipe });
  }

  async handleDeleteUser(id) {
    const response = await axiosDel(
      `${process.env.REACT_APP_BASE_URL}/recipes/${id}`
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if ((this, this.state.selectRecipeByAdmin)) {
        this.handleFilter();
      } else {
        this.getRecipes();
      }
      this.setState({ deleteModal: false });
    }
  }

  render() {
    //meta title
    document.title = "Recipe List";
    const { SearchBar } = Search;
    const { deleteModal, recipeList } = this.state;
    const { setItems, setIngredients, setNutriInfo } = this.props

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: recipeList?.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteUser(this.state.recipe._id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Diet Delights" breadcrumbItem="Recipes" />
            <div className="ms-1">
              <label>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter :
              </label>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <label>Created by me:</label>
                  <input
                    type="checkbox"
                    className="ms-2 mb-2"
                    checked={this.state.selectRecipeByAdmin}
                    onChange={this.handleCheckboxChange}
                  ></input>
                </div>
                <div className="text-sm-end">
                  <Link
                    color="primary"
                    className="font-16 btn-block btn btn-primary mb-3"
                    to="/recipe-list/create"
                    onClick={() => {
                      this.props.setIngredients("")
                      this.props.setItems("")
                      this.props.setNutriInfo("")
                    }}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New Recipes
                  </Link>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.contactListColumns}
                      data={recipeList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.contactListColumns}
                          data={recipeList}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  sm="4"
                                  className={`${this.state.recipeList.length === 0
                                    ? "display-none"
                                    : ""
                                    }`}
                                >
                                  <div className="search-box ms-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col sm="8">
                                  <div className="text-sm-end">
                                    <Link
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      to="/recipe-list/create"
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Recipes
                                    </Link>
                                  </div>
                                </Col> */}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={emptyContacts}
                                      alt="empty-conatct"
                                      className={`${this.state.recipeList.length === 0
                                        ? ""
                                        : "display-none"
                                        }`}
                                    ></img>
                                  </div>
                                  <div
                                    className={`table-responsive ${this.state.recipeList.length === 0
                                      ? "display-none"
                                      : ""
                                      }`}
                                  >
                                    {/* {this.state.recipeList.length === 0 ? <div className="d-flex justify-content-center">
                                      <img src={emptyContacts} alt="empty-conatct"></img>
                                    </div> : */}
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      data={this.state.recipeList} // Use recipeList from the state
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                    {/* }  */}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ingredients: state.Recipe.ingredients, // Assuming your Redux state has a 'rows' property
  moreItems: state.Recipe.items,
  nutriInfo: state.Recipe.nutriInfo,
});

const mapDispatchToProps = dispatch => ({
  setIngredients, // This connects the setIngredients action to your component
  setShowItemModal,
  setNutriInfo,
  setItems,
  editItems,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RecipeList));
