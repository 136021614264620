import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("accessToken")
      ? JSON.parse(sessionStorage.getItem("accessToken"))
      : "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error;
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.message === "Please authenticate" &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = sessionStorage.getItem("refreshToken")
        ? JSON.parse(sessionStorage.getItem("refreshToken"))
        : "";

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/auth/refresh-tokens`,
          {
            refreshToken: refreshToken,
          }
        );
        if (res.status === 200) {
          sessionStorage.setItem(
            "accessToken",
            JSON.stringify(res?.data?.tokens?.access?.token)
          );
          sessionStorage.setItem(
            "refreshToken",
            JSON.stringify(res?.data?.tokens?.refresh?.token)
          );

          error.config.headers[
            "Authorization"
          ] = `Bearer ${res.data.tokens.access.token}`;

          return axiosInstance(error.config);
        } else {
          sessionStorage.clear();
          window.location.replace("/login");
        }
      } catch (refreshError) {
        sessionStorage.clear();
        window.location.replace("/login");
      }
    } else {
      return Promise.reject(error);
    }
  }
);
