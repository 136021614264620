import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { axiosDel, axiosGet, axiosPost } from "helpers/api_helpers";
import { Link } from "react-router-dom";
import { customerData } from "common/data";
import emptyContacts from "../../../assets/images/emptyContact.png";
import DeleteModal from "components/Common/DeleteModal";
import Pdf from "./Pdf";
import { LIMIT, SIZE_PER_PAGE } from "constants/pagination";
import Toast from "components/Common/Toast";
import PortionGuide from "./portionGuide";

// Table data

class CustomerDietTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("user")),
      page: 1,
      sizePerPage: SIZE_PER_PAGE,
      productData: [],
      customerData: {},
      deleteModal: false,
      diet: {},
      errorMessage: "",
      showPortionGuide: false,
      mealPlanData: {},
    };
  }

  componentDidMount() {
    const userId = this.props.match.params.customerId;
    this.getCustomerData(userId);
    this.getCustomerDiets(userId);
  }

  async getCustomerData(userId) {
    const response = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/users/${userId}`
    );
    this.setState({ customerData: response });
  }

  async getCustomerDiets(userId) {
    const response = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/userdiets?limit=${LIMIT}&page=1&userId=${userId}`
    );
    if (response) {
      this.setState({ productData: response?.results });
    }
  }

  onClickDelete(diet) {
    this.setState({ diet: diet });
    this.setState({ deleteModal: true });
  }

  async deleteDiet(id) {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }
    const response = await axiosDel(
      `${process.env.REACT_APP_BASE_URL}/userdiets/${id}`
    );
    if (!response?.message) {
      this.setState({ deleteModal: false });
      this.getCustomerDiets(this.props.match.params.customerId);
    } else if (response?.message) {
      this.setState({
        deleteModal: false,
        errorMessage: response?.message,
      });
    }
  }

  async handleCloneDiet(diet) {
    try {
      if (this.state.errorMessage) {
        this.setState({ errorMessage: "" });
      }
      const userId = this.props.match.params.customerId;
      const response = await axiosPost(
        `${process.env.REACT_APP_BASE_URL}/userdiets/duplicate/${diet?.id}?userId=${userId}`
      );
      if (!response?.message) {
        this.getCustomerDiets(userId);
      } else if (response?.message) {
        this.setState({ errorMessage: response?.message });
      }
    } catch (error) {
      console.error(error);
    }
  }

  dateFormatter(cell, row) {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // Use 24-hour format
    };
    const formattedDate = new Date(cell).toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  portionGuideModalToggle = () => {
    this.setState({ showPortionGuide: !this.state.showPortionGuide });
  };

  render() {
    const { diet, deleteModal } = this.state;

    const columns = [
      {
        dataField: "title",
        text: "Name",
        sort: true,
      },
      {
        dataField: "totalCalories",
        text: "Total Calories",
        sort: true,
        formatter: (cellContent, diet) => (
          <>
            <span className="text-muted mb-0">
              {diet.totalCalories ? diet.totalCalories : "-"}
            </span>
          </>
        ),
      },
      // {
      //   dataField: "totalExValue",
      //   text: "Total Ex",
      //   sort: true,
      //   formatter: (cellContent, diet) => (
      //     <>
      //       <span className="text-muted mb-0">
      //         {diet.totalExValue ? diet.totalExValue : "-"}
      //       </span>
      //     </>
      //   ),
      // },
      {
        dataField: "createdAt",
        text: "CreatedAt",
        sort: true,
        formatter: (cellContent, diet) => (
          <>
            <span className="text-muted mb-0">
              {this.dateFormatter(diet.createdAt)}
            </span>
          </>
        ),
      },
      {
        dataField: "updatedAt",
        text: "UpdatedAt",
        sort: true,
        formatter: (cellContent, diet) => (
          <>
            <span className="text-muted mb-0">
              {this.dateFormatter(diet.updatedAt)}
            </span>
          </>
        ),
      },
      {
        dataField: "menu",
        isDummyField: true,
        editable: false,
        text: "Action",
        formatter: (cellContent, diet) => (
          <div className="d-flex gap-3">
            <Link className="text-success" to="#">
              <i
                className="mdi mdi-pencil font-size-18"
                id="edittooltip"
                onClick={() =>
                  this.props.history.push(
                    `/diet-table/${this.props.match.params.customerId}/edit/${diet.id}`
                  )
                }
              ></i>
            </Link>
            <Link className="text-danger" to="#">
              <i
                className="mdi mdi-delete font-size-18"
                id="deletetooltip"
                onClick={() => this.onClickDelete(diet)}
              ></i>
            </Link>
            <Link className="text-primary" to="#">
              <i
                className="mdi mdi-content-copy font-size-18"
                id="deletetooltip"
                onClick={() => this.handleCloneDiet(diet)}
              ></i>
            </Link>
            <Link className="text-secondary" to="#">
              <Pdf
                dietId={diet?.id}
                customerId={this.props.match.params.customerId}
              />
            </Link>
          </div>
        ),
      },
    ];

    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: this.state.productData?.length, // replace later with size(customers),
      custom: true,
    };

    // Select All Button operation
    const selectRow = {
      mode: "checkbox",
    };

    const { SearchBar } = Search;

    //meta title
    document.title = "Diet List | Diet-Delights";

    return (
      <React.Fragment>
        {this.state.errorMessage ? (
          <Toast isSuccess={false} message={this.state.errorMessage} />
        ) : (
          ""
        )}
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.deleteDiet(this.state.diet.id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs
              title="Customers"
              breadcrumbItem={this.state.customerData.name}
            />
            <div className="mb-3 d-flex">
              <Button
                color="primary me-2"
                className="font-16 btn-block btn btn-primary"
                onClick={
                  () => this.props.history.push(`/customers`) // Navigate to the root URL
                }
              >
                <i className="mdi mdi-arrow-left-bold-circle me-1" />
                Back
              </Button>
              <Button
                color="primary"
                className="font-16 btn-block btn btn-primary"
                onClick={
                  () =>
                    this.props.history.push(
                      `/diet-table/${this.state.customerData?.id}`
                    ) // Navigate to the root URL
                }
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New Diet
              </Button>
              {/* <Button
                color="primary"
                className="font-16 btn-block btn btn-primary ms-2"
                onClick={() => this.setState({ showPortionGuide: true })}
              >
                <i className="mdi mdi-book-open-page-variant me-1" />
                Portion Guide
              </Button> */}
            </div>
            {/* <Modal
              isOpen={this.state.showPortionGuide}
              className="portion-guide-table"
            >
              <ModalHeader toggle={this.portionGuideModalToggle} tag="h4">
                Portion Guide
              </ModalHeader>
              <ModalBody>
                <PortionGuide mealPlanData={this.state.mealPlanData} />
              </ModalBody>
            </Modal> */}
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  className={`${
                                    this.state.productData.length === 0
                                      ? "display-none"
                                      : ""
                                  }`}
                                >
                                  <div className="search-box me-2 mb-2 d-flex justify-content-between">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                    <div>
                                      {/* <Button
                                        color="primary me-2"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={
                                          () =>
                                            this.props.history.push(
                                              `/customers`
                                            ) // Navigate to the root URL
                                        }
                                      >
                                        <i className="mdi mdi-arrow-left-bold-circle me-1" />
                                        Back
                                      </Button>
                                      <Button
                                        color="primary"
                                        className="font-16 btn-block btn btn-primary"
                                        onClick={
                                          () =>
                                            this.props.history.push(
                                              `/diet-table/${this.state.customerData?.id}`
                                            ) // Navigate to the root URL
                                        }
                                      >
                                        <i className="mdi mdi-plus-circle-outline me-1" />
                                        Create New Diet
                                      </Button> */}
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={emptyContacts}
                                      alt="empty-conatct"
                                      className={`${
                                        this.state.productData.length === 0
                                          ? ""
                                          : "display-none"
                                      }`}
                                    ></img>
                                  </div>
                                  <div
                                    className={`table-responsive ${
                                      this.state.productData.length === 0
                                        ? "display-none"
                                        : ""
                                    }`}
                                  >
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CustomerDietTable;
