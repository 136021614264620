import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { LIMIT, SIZE_PER_PAGE } from "../../constants/pagination";
import Toast from "components/Common/Toast";

class Categories extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      category: {},
      userData: JSON.parse(sessionStorage.getItem("user")),
      categories: [], // Initialize categories state
      modal: false,
      deleteModal: false,
      errorMsg: "",
      successMsg: "",
      selectCategoryByAdmin: "",
      categoryListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, category) => <>{category.id}</>,
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, category) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleUserClick(category)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(category)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
    this.getCategories();
    this.setState({ users });
  }

  async getCategories() {
    try {
      const categories = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/categories?page=1&limit=${LIMIT}`
      );
      if (categories?.message) {
        this.setState({ errorMsg: categories?.message });
      } else {
        this.setState({ categories: categories?.results }); // Set the categories in the state
      }
    } catch (error) {
      console.error("error getCategories: ", error);
    }
  }

  async handleFilter() {
    try {
      const categories = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/categories?createdBy=${this.state.userData.id}&page=1&limit=${LIMIT}`
      );
      if (categories?.message) {
        this.setState({ errorMsg: categories?.message });
      } else {
        this.setState({ categories: categories?.results }); // Set the categories in the state
      }
    } catch (error) {
      console.error("error handleFilter: ", error);
    }
  }

  handleCheckboxChange = () => {
    this.setState(
      prevState => ({
        selectCategoryByAdmin: !prevState.selectCategoryByAdmin,
      }),
      () => {
        this.handleFilter();
        if (!this.state.selectCategoryByAdmin) {
          this.getCategories();
        }
      }
    );
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks() {
    this.setState({ category: {}, isEdit: false });
    this.toggle();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  /* Insert,Update Delete data */

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  onClickDelete(category) {
    this.setState({ category: category });
    this.setState({ deleteModal: true });
  }

  handleUserClick(category) {
    this.setState({
      category: {
        id: category?.id,
        name: category.name,
      },
      isEdit: true,
    });

    this.toggle();
  }

  async handleAddUser(data) {
    try {
      const response = await axiosPost(
        `${process.env.REACT_APP_BASE_URL}/categories`,
        data
      );
      if (response?.message) {
        this.setState({ errorMsg: response?.message });
      } else {
        this.setState({ successMsg: "Category Added Successfully" });
        if ((this, this.state.selectCategoryByAdmin)) {
          this.handleFilter();
        } else {
          this.getCategories();
        }
        this.toggle();
      }
    } catch (error) {
      console.error("error handleAddUser: ", error);
    }
  }

  async handleUpdateUser(id, data) {
    try {
      const response = await axiosPatch(
        `${process.env.REACT_APP_BASE_URL}/categories/${id}`,
        data
      );
      if (response?.message) {
        this.setState({ errorMsg: response?.message });
      } else {
        this.setState({ successMsg: "Category Updated Successfully" });
        if ((this, this.state.selectCategoryByAdmin)) {
          this.handleFilter();
        } else {
          this.getCategories();
        }
        this.toggle();
      }
    } catch (error) {
      console.error("error handleUpdateUser: ", error);
    }
  }

  async handleDeleteUser(id) {
    try {
      const response = await axiosDel(
        `${process.env.REACT_APP_BASE_URL}/categories/${id}`
      );
      if (response?.message) {
        this.setState({ errorMsg: response?.message });
      } else {
        this.setState({ successMsg: "Category Deleted Successfully" });
        if ((this, this.state.selectCategoryByAdmin)) {
          this.handleFilter();
        } else {
          this.getCategories();
        }
        this.setState({ deleteModal: false });
      }
    } catch (error) {
      console.error("error handleDeleteUser: ", error);
    }
  }

  render() {
    const { SearchBar } = Search;

    const { isEdit, deleteModal, categories } = this.state;

    const category = this.state.category;

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: categories?.length, // replace later with size(categories),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteUser(this.state.category.id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {this.state.errorMsg ? (
          <Toast isSuccess={false} message={this.state.errorMsg} />
        ) : this.state.successMsg ? (
          <Toast isSuccess={true} message={this.state.successMsg} />
        ) : (
          ""
        )}
        <Card className="h-100">
          <CardBody>
            <h4 className="mb-3 font-size-18 text-center">Diet Types</h4>
            <div className="ms-1">
              <label>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter :
              </label>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <label>Created by me:</label>
                  <input
                    type="checkbox"
                    className="ms-2 mb-2"
                    checked={this.state.selectCategoryByAdmin}
                    onChange={this.handleCheckboxChange}
                  ></input>
                </div>
                <div className="text-sm-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary mb-3 py-1 px-2 d-flex align-items-center"
                    onClick={this.handleUserClicks}
                  >
                    <i className="mdi mdi mdi-plus-circle fs-5 me-1" />
                    <span>Add New Diet Type</span>
                  </Button>
                </div>
              </div>
            </div>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={this.state.categoryListColumns}
              data={categories}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={this.state.categoryListColumns}
                  data={categories}
                  search
                >
                  {toolkitprops => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          sm="6"
                          className={`${
                            this.state.categories.length === 0
                              ? "display-none"
                              : ""
                          }`}
                        >
                          <div className="search-box ms-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitprops.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="d-flex justify-content-center">
                            <img
                              src={emptyContacts}
                              alt="empty-conatct"
                              className={`h-10rem ${
                                this.state.categories.length === 0
                                  ? ""
                                  : "display-none"
                              }`}
                            ></img>
                          </div>
                          <div
                            className={`table-responsive ${
                              this.state.categories.length === 0
                                ? "display-none"
                                : ""
                            }`}
                          >
                            {/* {this.state.categories.length === 0 ? <div className="d-flex justify-content-center">
                                      <img src={emptyContacts} alt="empty-conatct"></img>
                                    </div> : */}
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              defaultSorted={defaultSorted}
                              data={this.state.categories} // Use categories from the state
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              ref={this.node}
                            />
                            {/* }  */}

                            <Modal
                              isOpen={this.state.modal}
                              className={this.props.className}
                            >
                              <ModalHeader toggle={this.toggle} tag="h4">
                                {!!isEdit ? "Edit Category" : "Add Category"}
                              </ModalHeader>
                              <ModalBody>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    name: (category && category.name) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    name: Yup.string().required(
                                      "Please Enter Category Name"
                                    ),
                                  })}
                                  onSubmit={values => {
                                    if (isEdit) {
                                      if (category?.name !== values?.name) {
                                        const updateUser = {
                                          name: values.name,
                                        };
                                        this.handleUpdateUser(
                                          category?.id,
                                          updateUser
                                        );
                                      } else {
                                        this.toggle();
                                      }
                                    } else {
                                      const newUser = {
                                        name: values["name"],
                                      };
                                      // save new category
                                      this.handleAddUser(newUser);
                                    }
                                    this.setState({
                                      selectedUser: null,
                                    });
                                    // this.toggle();
                                  }}
                                >
                                  {({ errors, status, touched }) => (
                                    <Form>
                                      {this.state.errorMsg ? (
                                        <span className="text-danger ms-2">
                                          {this.state.errorMsg}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Name
                                            </Label>
                                            <Field
                                              name="name"
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.name && touched.name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="name"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </ModalBody>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

Categories.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Categories));
