import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import Select from "react-select";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../../src/assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import Toast from "components/Common/Toast";
import { languages, userRoles } from "constants/userRole";
import { LIMIT } from "constants/pagination";
import { defaultLanguageSelect, defaultSelect } from "constants/common";

class CustomerTable extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      user: "",
      userData: JSON.parse(sessionStorage.getItem("user")),
      customerList: [], // Initialize customerList state
      modal: false,
      deleteModal: false,
      errorMsg: "",
      selectUserBydietitian: "",
      deleteCustomerError: "",
      successMessage: "",
      dietitiansListOptions: [],
      selectedDietitian: defaultSelect,
      languageOptions: [],
      contactListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, user) => <>{user.id}</>,
        },
        // {
        //   dataField: "img",
        //   text: "#",
        //   formatter: (cellContent, user) => (
        //     <>
        //       {!user.img ? (
        //         <div className="avatar-xs">
        //           <span className="avatar-title rounded-circle">
        //             {user?.name.charAt(0)}
        //           </span>
        //         </div>
        //       ) : (
        //         <div>
        //           <img
        //             className="rounded-circle avatar-xs"
        //             src={images[user.img]}
        //             alt=""
        //           />
        //         </div>
        //       )}
        //     </>
        //   ),
        // },
        {
          text: "Medicinal No",
          dataField: "medicalNo",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <Link to={`/customers/${user?.id}`} className="text-dark">
                <p className="text-muted mb-0">{user?.medicalNo || "-"}</p>
              </Link>
            </>
          ),
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <Link to={`/customers/${user?.id}`} className="text-dark">
                <h5 className="font-size-14 mb-1">{user?.name}</h5>
                <p className="text-muted mb-0">{user?.designation}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "email",
          text: "Email",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <Link to={`/customers/${user?.id}`} className="text-dark">
                <p className="text-muted mb-0">{user?.email}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "phoneNumber",
          text: "Phone Number",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <Link to={`/customers/${user?.id}`} className="text-dark">
                <p className="text-muted mb-0">{user?.phoneNumber}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "city",
          text: "City",
          sort: true,
          formatter: (cellContent, user) => (
            <>
              <Link to={`/customers/${user?.id}`} className="text-dark">
                <p className="text-muted mb-0">{user?.city}</p>
              </Link>
            </>
          ),
        },
        {
          dataField: "menu",
          isDummyField: true,
          editable: false,
          text: "Action",
          formatter: (cellContent, user) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => this.handleUserClick(user)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => this.onClickDelete(user)}
                ></i>
              </Link>
            </div>
          ),
        },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleUserClicks = this.handleUserClicks.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
    this.handleDietitianSelect = this.handleDietitianSelect.bind(this);
  }

  componentDidMount() {
    this.handleGetAllDietitian();
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
    this.getCustomers();
    this.getLanguages();
    this.setState({ users });
  }

  async getLanguages() {
    try {
      const languages = await axiosGet(`/languages?page=1&limit=${LIMIT}`);
      if (languages?.message) {
        this.setState({ errorMsg: languages?.message });
      } else {
        if (languages?.results) {
          const options = [
            ...languages?.results?.map(dietitian => ({
              value: dietitian?.key,
              label: dietitian?.name,
            })),
          ];
          this.setState({ languageOptions: options });
        }
      }
    } catch (error) {
      console.error("error getLanguages: ", error);
    }
  }

  async getCustomers() {
    const customerList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/users?page=1&limit=${LIMIT}&role=${userRoles.USER}`
    );
    this.setState({ customerList: customerList?.results }); // Set the customerList in the state
  }

  async handleFilter(id) {
    const customerList = await axiosGet(
      `${process.env.REACT_APP_BASE_URL}/users?${
        id ? "createdBy=" + id : ""
      }&page=1&limit=${LIMIT}&role=${userRoles.USER}`
    );
    this.setState({ customerList: customerList?.results }); // Set the customerList in the state
  }

  handleCheckboxChange = () => {
    this.setState(
      prevState => ({
        selectUserBydietitian: !prevState.selectUserBydietitian,
      }),
      () => {
        this.handleFilter(this.state.userData?.id);
        if (!this.state.selectUserBydietitian) {
          this.getCustomers();
        }
      }
    );
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleUserClicks() {
    this.setState({ user: "", isEdit: false });
    this.toggle();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  /* Insert,Update Delete data */

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  onClickDelete(users) {
    this.setState({ users: users });
    this.setState({ deleteModal: true });
  }

  handleUserClick(arg) {
    const user = arg;

    this.setState({
      user: {
        id: user.id,
        language: user.language,
        name: user.name,
        designation: user.designation,
        email: user.email,
        tags: user.tags,
        projects: user.projects,
        phoneNumber: parseInt(user.phoneNumber, 10),
        role: user.role,
        password: user.password,
        weight: parseFloat(user.weight, 10),
        height: parseFloat(user.height, 10),
        age: parseFloat(user.age, 10),
        fat: parseFloat(user.fat, 10),
        medicalNo: parseFloat(user.medicalNo, 10),
        calorie: parseFloat(user.calorie, 10),
        carbohydrate: parseFloat(user.carbohydrate, 10),
        protein: parseFloat(user.protein, 10),
        address: user.address,
        city: user.city,
      },
      isEdit: true,
    });

    this.toggle();
  }

  async handleAddUser(data) {
    const response = await axiosPost(
      `${process.env.REACT_APP_BASE_URL}/users`,
      data
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if (this.state.selectedDietitian.value !== defaultSelect.value) {
        this.handleFilter(this.state.selectedDietitian.value);
      } else {
        this.getCustomers();
      }
      this.toggle();
      this.setState({
        successMessage: "Customer created successfully !",
      });
    }
  }

  async handleUpdateUser(id, data) {
    const response = await axiosPatch(
      `${process.env.REACT_APP_BASE_URL}/users/${id}`,
      data
    );
    if (response?.message) {
      this.setState({ errorMsg: response?.message });
    } else {
      if (this.state.selectedDietitian.value !== defaultSelect.value) {
        this.handleFilter(this.state.selectedDietitian.value);
      } else {
        this.getCustomers();
      }
      this.toggle();
      this.setState({
        successMessage: "Customer updated successfully !",
      });
    }
  }

  async handleDeleteUser(id) {
    const response = await axiosDel(
      `${process.env.REACT_APP_BASE_URL}/users/${id}`
    );
    if (response?.message) {
      this.setState({ deleteCustomerError: response?.message });
    } else {
      if (this.state.selectedDietitian.value !== defaultSelect.value) {
        this.handleFilter(this.state.selectedDietitian.value);
      } else {
        this.getCustomers();
      }
      this.setState({ deleteModal: false });
      this.setState({
        successMessage: "Customer deleted successfully !",
      });
    }
  }

  handleGetAllDietitian = async () => {
    try {
      const dietitians = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/users?page=1&limit=${LIMIT}&role=${userRoles.DIETITIAN}`
      );
      if (dietitians?.results) {
        const options = [
          { ...defaultSelect },
          { value: this.state.userData?.id, label: "Me" },
          ...dietitians?.results?.map(dietitian => ({
            value: dietitian?.id,
            label: dietitian?.email,
          })),
        ];
        this.setState({ dietitiansListOptions: options });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleDietitianSelect(event) {
    if (event?.value !== defaultSelect.value) {
      this.handleFilter(event?.value);
    } else {
      this.handleFilter();
    }
    this.setState({ selectedDietitian: event });
  }

  render() {
    //meta title
    document.title = "Customer List | Diet-Delights";

    // const { users } = this.state
    const { SearchBar } = Search;

    const {
      isEdit,
      deleteModal,
      customerList: users,
      selectedDietitian,
      dietitiansListOptions,
    } = this.state;

    const { onAddNewUser, onUpdateUser } = this.props;
    const user = this.state.user;

    const pageOptions = {
      sizePerPage: 8,
      totalSize: users.length, // replace later with size(users),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => this.handleDeleteUser(this.state.users.id)}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {this.state.deleteCustomerError ? (
          <Toast isSuccess={false} message={this.state.deleteCustomerError} />
        ) : this.state.successMessage ? (
          <Toast isSuccess={true} message={this.state.successMessage} />
        ) : (
          ""
        )}
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Diet Delights" breadcrumbItem="Customers" />
            <div className="ms-1">
              <label>
                <i className="mdi mdi-filter-variant me-1"></i>
                Filter :
              </label>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-50">
                  {this.state.userData?.role === userRoles.ADMIN ? (
                    <div className="select2-container d-flex justify-content-start align-items-center w-100">
                      <Label className="mb-1 me-3">Created by : </Label>
                      <Select
                        className="w-50"
                        value={selectedDietitian}
                        onChange={this.handleDietitianSelect}
                        defaultValue={selectedDietitian}
                        options={dietitiansListOptions}
                      />
                    </div>
                  ) : (
                    <div className="d-flex align-items-center">
                      <label>Created by me:</label>
                      <input
                        type="checkbox"
                        className="ms-2 mb-2"
                        checked={this.state.selectUserBydietitian}
                        onChange={this.handleCheckboxChange}
                      ></input>
                    </div>
                  )}
                </div>
                <div className="text-sm-end">
                  <Button
                    color="primary"
                    className="font-16 btn-block btn btn-primary mb-3"
                    onClick={this.handleUserClicks}
                  >
                    <i className="mdi mdi-plus-circle-outline me-1" />
                    Create New Customer
                  </Button>
                </div>
              </div>
            </div>
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={this.state.contactListColumns}
                      data={users}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={this.state.contactListColumns}
                          data={users}
                          search
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col
                                  sm="4"
                                  className={`${
                                    this.state.customerList.length === 0
                                      ? "display-none"
                                      : ""
                                  }`}
                                >
                                  <div className="search-box ms-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitprops.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                                {/* <Col sm="8">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={this.handleUserClicks}
                                    >
                                      <i className="mdi mdi-plus-circle-outline me-1" />
                                      Create New Customer
                                    </Button>
                                  </div>
                                </Col> */}
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="d-flex justify-content-center">
                                    <img
                                      src={emptyContacts}
                                      alt="empty-conatct"
                                      className={`${
                                        this.state.customerList.length === 0
                                          ? ""
                                          : "display-none"
                                      }`}
                                    ></img>
                                  </div>
                                  <div
                                    className={`table-responsive ${
                                      this.state.customerList.length === 0
                                        ? "display-none"
                                        : ""
                                    }`}
                                  >
                                    {/* {this.state.customerList.length === 0 ? <div className="d-flex justify-content-center">
                                    </div> : */}
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      data={this.state.customerList} // Use customerList from the state
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                      ref={this.node}
                                    />
                                    {/* }  */}

                                    <Modal
                                      isOpen={this.state.modal}
                                      className="add-user-modal"
                                    >
                                      <ModalHeader
                                        toggle={this.toggle}
                                        tag="h4"
                                      >
                                        {!isEdit ? "Add User" : "Edit User"}
                                      </ModalHeader>
                                      <ModalBody>
                                        <Formik
                                          enableReinitialize={true}
                                          initialValues={{
                                            name: (user && user.name) || "",
                                            email: (user && user.email) || "",
                                            phoneNumber:
                                              (user && user.phoneNumber) || "",
                                            weight: (user && user.weight) || "",
                                            height: (user && user.height) || "",
                                            age: (user && user.age) || "",
                                            fat: (user && user.fat) || "",
                                            medicalNo:
                                              (user && user.medicalNo) || "",
                                            carbohydrate:
                                              (user && user.carbohydrate) || "",
                                            calorie:
                                              (user && user.calorie) || "",
                                            protein:
                                              (user && user.protein) || "",
                                            address:
                                              (user && user.address) || "",
                                            city: (user && user.city) || "",
                                            password: "",
                                            language:
                                              (user && user.language) ||
                                              languages.AR,
                                          }}
                                          validationSchema={
                                            isEdit
                                              ? Yup.object().shape({
                                                  name: Yup.string().required(
                                                    "Please Enter Customer Name"
                                                  ),
                                                  phoneNumber:
                                                    Yup.number().required(
                                                      "Please Enter Phone Number"
                                                    ),
                                                  weight: Yup.number().required(
                                                    "Please Enter weight"
                                                  ),
                                                  height: Yup.number().required(
                                                    "Please Enter height"
                                                  ),
                                                  age: Yup.number().required(
                                                    "Please Enter age"
                                                  ),
                                                  fat: Yup.number().required(
                                                    "Please Enter fat"
                                                  ),
                                                  medicalNo:
                                                    Yup.number().required(
                                                      "Please Enter weimedicalNoght"
                                                    ),
                                                  carbohydrate:
                                                    Yup.number().required(
                                                      "Please Enter carbohydrate"
                                                    ),
                                                  calorie:
                                                    Yup.number().required(
                                                      "Please Enter calorie"
                                                    ),
                                                  protein:
                                                    Yup.number().required(
                                                      "Please Enter protein"
                                                    ),
                                                  address:
                                                    Yup.string().required(
                                                      "Please Enter address"
                                                    ),
                                                  city: Yup.string().required(
                                                    "Please Enter city name"
                                                  ),
                                                })
                                              : Yup.object().shape({
                                                  name: Yup.string().required(
                                                    "Please Enter Customer Name"
                                                  ),
                                                  email: Yup.string().required(
                                                    "Please Enter Customer Email"
                                                  ),
                                                  phoneNumber:
                                                    Yup.number().required(
                                                      "Please Enter Phone Number"
                                                    ),
                                                  password:
                                                    Yup.string().required(
                                                      "Please Enter the password"
                                                    ),
                                                  weight: Yup.number().required(
                                                    "Please Enter weight"
                                                  ),
                                                  height: Yup.number().required(
                                                    "Please Enter height"
                                                  ),
                                                  age: Yup.number().required(
                                                    "Please Enter age"
                                                  ),
                                                  fat: Yup.number().required(
                                                    "Please Enter fat"
                                                  ),
                                                  medicalNo:
                                                    Yup.number().required(
                                                      "Please Enter weimedicalNoght"
                                                    ),
                                                  carbohydrate:
                                                    Yup.number().required(
                                                      "Please Enter carbohydrate"
                                                    ),
                                                  calorie:
                                                    Yup.number().required(
                                                      "Please Enter calorie"
                                                    ),
                                                  protein:
                                                    Yup.number().required(
                                                      "Please Enter protein"
                                                    ),
                                                  address:
                                                    Yup.string().required(
                                                      "Please Enter address"
                                                    ),
                                                  city: Yup.string().required(
                                                    "Please Enter city name"
                                                  ),
                                                })
                                          }
                                          onSubmit={values => {
                                            if (isEdit) {
                                              if (
                                                user?.phoneNumber !==
                                                  values.phoneNumber ||
                                                user?.name !== values?.name ||
                                                user?.weight !==
                                                  values?.weight ||
                                                user?.height !==
                                                  values?.height ||
                                                user?.age !== values?.age ||
                                                user?.fat !== values?.fat ||
                                                user?.medicalNo !==
                                                  values?.medicalNo ||
                                                user?.protein !==
                                                  values?.protein ||
                                                user?.carbohydrate !==
                                                  values?.carbohydrate ||
                                                user?.calorie !==
                                                  values?.calorie ||
                                                user?.address !==
                                                  values?.address ||
                                                user?.city !== values?.city ||
                                                user?.language !==
                                                  values?.language
                                              ) {
                                                const updateUser = {
                                                  language: values.language,
                                                  name: values.name,
                                                  phoneNumber: `${values["phoneNumber"]}`,
                                                  weight: `${values["weight"]}`,
                                                  height: `${values["height"]}`,
                                                  fat: `${values["fat"]}`,
                                                  age: `${values["age"]}`,
                                                  medicalNo: `${values["medicalNo"]}`,
                                                  calorie: `${values["calorie"]}`,
                                                  carbohydrate: `${values["carbohydrate"]}`,
                                                  protein: `${values["protein"]}`,
                                                  address: values["address"],
                                                  city: values["city"],
                                                  updatedBy:
                                                    this.state.userData.id,
                                                };

                                                this.handleUpdateUser(
                                                  user?.id,
                                                  updateUser
                                                );
                                                this.setState({ errorMsg: "" });
                                              } else {
                                                this.toggle();
                                              }
                                            } else {
                                              const newUser = {
                                                language: values.language,
                                                name: values["name"],
                                                email: values["email"],
                                                phoneNumber: `${values["phoneNumber"]}`,
                                                role: "user",
                                                weight: `${values["weight"]}`,
                                                height: `${values["height"]}`,
                                                fat: `${values["fat"]}`,
                                                age: `${values["age"]}`,
                                                medicalNo: `${values["medicalNo"]}`,
                                                calorie: `${values["calorie"]}`,
                                                carbohydrate: `${values["carbohydrate"]}`,
                                                protein: `${values["protein"]}`,
                                                address: values["address"],
                                                password: values["password"],
                                                city: values["city"],
                                                createdBy:
                                                  this.state.userData.id,
                                              };
                                              // save new user

                                              this.handleAddUser(newUser);
                                            }
                                            this.setState({
                                              selectedUser: null,
                                              errorMsg: "",
                                            });
                                            // this.toggle();
                                          }}
                                        >
                                          {({
                                            errors,
                                            status,
                                            touched,
                                            values,
                                            setFieldValue,
                                          }) => (
                                            <Form>
                                              {this.state.errorMsg ? (
                                                <span className="text-danger ms-2">
                                                  {this.state.errorMsg}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                              <Row>
                                                <Col className="col-12">
                                                  <div className="mb-3 d-flex align-items-center">
                                                    <Label className="form-label">
                                                      Preferred Language :
                                                    </Label>
                                                    <Select
                                                      name="language"
                                                      className="ms-4 ps-2 ml-2rem w-50"
                                                      value={this.state.languageOptions.reduce(
                                                        (acc, val) => {
                                                          return val?.value ===
                                                            values.language
                                                            ? val
                                                            : acc;
                                                        },
                                                        {
                                                          value: "",
                                                          label: "",
                                                        }
                                                      )}
                                                      onChange={selectedOption => {
                                                        setFieldValue(
                                                          "language",
                                                          selectedOption?.value
                                                        );
                                                      }}
                                                      options={
                                                        this.state
                                                          .languageOptions
                                                      }
                                                    />
                                                    {/* <div className="d-flex justify-content-center align-items-center ms-4">
                                                      {Object.values(
                                                        languages
                                                      ).map(value => (
                                                        <div key={value}>
                                                          <Field
                                                            type="radio"
                                                            name="language"
                                                            value={value}
                                                            className="me-1 mb-2 "
                                                          />
                                                          <label className="me-4">
                                                            {value
                                                              .charAt(0)
                                                              .toUpperCase() +
                                                              value
                                                                .slice(1)
                                                                .toLowerCase()}
                                                          </label>
                                                        </div>
                                                      ))}
                                                    </div> */}
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Medicial No
                                                    </Label>
                                                    <Field
                                                      name="medicalNo"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.medicalNo &&
                                                        touched.medicalNo
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="medicalNo"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Name
                                                    </Label>
                                                    <Field
                                                      name="name"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.name &&
                                                        touched.name
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="name"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  {isEdit ? (
                                                    ""
                                                  ) : (
                                                    <div className="mb-3">
                                                      <Label className="form-label">
                                                        Email
                                                      </Label>
                                                      <Field
                                                        name="email"
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors.email &&
                                                          touched.email
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name="email"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </div>
                                                  )}
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Phone Number
                                                    </Label>
                                                    <Field
                                                      name="phoneNumber"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.phoneNumber &&
                                                        touched.phoneNumber
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="phoneNumber"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Weight (In kg)
                                                    </Label>
                                                    <Field
                                                      name="weight"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.weight &&
                                                        touched.weight
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="weight"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Height (In cm)
                                                    </Label>
                                                    <Field
                                                      name="height"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.height &&
                                                        touched.height
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="height"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Age
                                                    </Label>
                                                    <Field
                                                      name="age"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.age &&
                                                        touched.age
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="age"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Calorie
                                                    </Label>
                                                    <Field
                                                      name="calorie"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.calorie &&
                                                        touched.calorie
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="calorie"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Carbohydrate
                                                    </Label>
                                                    <Field
                                                      name="carbohydrate"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.carbohydrate &&
                                                        touched.carbohydrate
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="carbohydrate"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Protein
                                                    </Label>
                                                    <Field
                                                      name="protein"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.protein &&
                                                        touched.protein
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="protein"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Fat
                                                    </Label>
                                                    <Field
                                                      name="fat"
                                                      type="number"
                                                      className={
                                                        "form-control" +
                                                        (errors.fat &&
                                                        touched.fat
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="fat"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      City
                                                    </Label>
                                                    <Field
                                                      name="city"
                                                      type="text"
                                                      className={
                                                        "form-control" +
                                                        (errors.city &&
                                                        touched.city
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="city"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  <div className="mb-3">
                                                    <Label className="form-label">
                                                      Address
                                                    </Label>
                                                    <Field
                                                      name="address"
                                                      as="textarea"
                                                      className={
                                                        "form-control" +
                                                        (errors.address &&
                                                        touched.address
                                                          ? " is-invalid"
                                                          : "")
                                                      }
                                                    />
                                                    <ErrorMessage
                                                      name="address"
                                                      component="div"
                                                      className="invalid-feedback"
                                                    />
                                                  </div>
                                                  {isEdit ? (
                                                    ""
                                                  ) : (
                                                    <div className="mb-3">
                                                      <Label className="form-label">
                                                        Password
                                                      </Label>
                                                      <Field
                                                        name="password"
                                                        type="text"
                                                        className={
                                                          "form-control" +
                                                          (errors.password &&
                                                          touched.password
                                                            ? " is-invalid"
                                                            : "")
                                                        }
                                                      />
                                                      <ErrorMessage
                                                        name="password"
                                                        component="div"
                                                        className="invalid-feedback"
                                                      />
                                                    </div>
                                                  )}
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col>
                                                  <div className="text-end">
                                                    <button
                                                      type="submit"
                                                      className="btn btn-success save-user"
                                                    >
                                                      {!isEdit
                                                        ? "Save"
                                                        : "Edit"}
                                                    </button>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Form>
                                          )}
                                        </Formik>
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

CustomerTable.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
  onGetUsers: PropTypes.func,
  onAddNewUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onUpdateUser: PropTypes.func,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerTable));
