// export const categoryTitles = [
//   "Vegan",
//   "Non-vegetarian",
//   "Gluten-free",
//   "Dairy-free",
// ];

// export const units = ["Ex", "Calories"];

export const categoryShow = {
  BREAKFAST: "Breakfast",
  LUNCH: "Lunch",
  DINNER: "Dinner",
  // SNACKS: "Snacks",
  SNACK_AM: "Snack Am",
  SNACK_PM: "Snack Pm",
  SIDE_DISH: "Side Dish",
};
export const categoryTitles = {
  'Vegan': 'isVegan',
  'Vegetarian': 'isVegetarian',
  'Gluten-free': 'isGlutenFree',
  'Dairy-free': 'isDairyFree',
}

export const units = {
  EX: "ex",
  CALORIES: "calories"
}

// export const category = {
//   BREAKFAST: "BREAKFAST",
//   LUNCH: "LUNCH",
//   DINNER: "DINNER",
//   SNACKS: "SNACKS",
//   SIDE_DISH: "SIDE_DISH",
// }

// export const userRoles = {
//   ADMIN: 'admin',
//   DIETICIAN: 'dietitian',
//   USER: 'user',
// }

export const portions = {
  1: 'oneP',
  2: 'twoP',
  3: 'threeP'
}

export const showPortions = {
  "oneP": "1p",
  "twoP": "2p",
  "threeP": "3p",
}

export const nutriInfo = {
  "carbohydrate": "CHO (carbs)",
  "protein": "CHON (protein)",
  "fat": "Fat",
}

