import { showPortions } from "constants/diet";
import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

class PortionGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlanData: this.props.mealPlanData || {},
    };
  }

  capitalizeFirstLetter = string => {
    if (string.includes("_")) {
      const words = string.split("_");
      const capitalizedWords = words.map(
        word => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  render() {
    const { mealPlanData } = this.state;

    return (
      <div>
        <h1>Diet Plan</h1>
        <Table border="1" className="table table-striped table-bordered">
          <Thead>
            <Tr>
              <Th className="text-center">Meal Type</Th>
              <Th className="text-center">Image</Th>
              <Th className="text-center">Portion</Th>
              <Th className="text-center">Nutrition Info</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(mealPlanData).map(([mealType, mealDetails]) => {
              const portions = Object.keys(mealDetails).filter(key =>
                ["oneP", "twoP", "threeP"].includes(key)
              );

              const rowSpan = portions.length || 1; // Set a default of 1 if no valid portions found

              return (
                <React.Fragment key={mealType}>
                  {portions.map((portion, index) => (
                    <Tr key={`${mealType}-${portion}`}>
                      {index === 0 && (
                        <>
                          <Td rowSpan={rowSpan} className="fw-bold text-center">
                            {this.capitalizeFirstLetter(mealType)}
                          </Td>
                          <Td
                            rowSpan={rowSpan}
                            className="text-center align-middle"
                          >
                            <img
                              src={mealDetails?.destination}
                              alt="meal image"
                              className="meal-type-image"
                            ></img>
                          </Td>
                        </>
                      )}
                      <Td className="fw-bold">{showPortions[portion]}</Td>
                      <Td>
                        <ul>
                          {Object.entries(mealDetails[portion]).map(
                            ([nutrient, value]) => (
                              <li key={nutrient}>{`${nutrient}: ${value}`}</li>
                            )
                          )}
                        </ul>
                      </Td>
                    </Tr>
                  ))}
                </React.Fragment>
              );
            })}
          </Tbody>
        </Table>
      </div>
    );
  }
}

export default PortionGuide;
