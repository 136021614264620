import React, { Component } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { axiosGet } from "helpers/api_helpers";
import { category, categoryShow, portions } from "constants/diet";
import {
  ARABIC_BOLD,
  ARABIC_NORMAL,
  LOGO_BASE64,
  ROBOTO_BOLD,
  ROBOTO_REGULAR,
  ALL_NORMAL,
  FIRST_PAGE_BACKGROUND,
  BACKGROUD_IMAGE,
  HAZELNUT_IMAGE,
  ROBOTO_CONDENSED_SEMI_BOLD,
  DINNER_IMG,
  ALUMNI_REGULAR,
  HEADING_STYLE_IMG,
  COOKIE_REGULAR,
  CAP_IMAGE,
  BULB_IMG,
  CHECKED_IMG,
  UNCHECKED_IMG,
  BREAKFAST_PAGE_IMAGE,
  LUNCH_PAGE_IMAGE,
  DINNER_PAGE_IMAGE,
  LUNCH_IMG,
  BREAKFAST_IMG,
  SNACK_PM_IMAGE,
  SNACK_PM_PAGE_IMAGE,
  SNACK_AM_PAGE_IMAGE,
  SIDE_DISH_PAGE_IMAGE,
  ABRIL_NORMAL,
} from "../../../constants/pdf";
import { setIsLoading } from "../../../store/actions";
import { LIMIT } from "constants/pagination";
import { connect } from "react-redux";
import htmlToPdfmake from "html-to-pdfmake";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
window.pdfMake.vfs["NotoNaskhArabic-Regular.ttf"] = ARABIC_NORMAL;
window.pdfMake.vfs["NotoNaskhArabic-Bold.ttf"] = ARABIC_BOLD;
window.pdfMake.vfs["Roboto-Regular.ttf"] = ROBOTO_REGULAR;
window.pdfMake.vfs["Roboto-Bold.ttf"] = ROBOTO_BOLD;
window.pdfMake.vfs["Noto-Sans.ttf"] = ALL_NORMAL;
window.pdfMake.vfs["Alumni-Regular.ttf"] = ALUMNI_REGULAR;
window.pdfMake.vfs["Cookie-Regular.ttf"] = COOKIE_REGULAR;
window.pdfMake.vfs["Abril-Regular.ttf"] = ABRIL_NORMAL;
window.pdfMake.vfs["Roboto-Condensed-SemiBold.ttf"] =
  ROBOTO_CONDENSED_SEMI_BOLD;

class Pdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealTypes: [],
      userData: {},
      mealPlanData: {},
      portionKeyData: {},
      imageBase64: "",
      imageBase64ByTitle: {},
      categories: [],
    };
  }

  async componentDidMount() {
    "cutomerId", this.props.customerId;
    try {
      const mealTypes = await axiosGet(`/meal-types?limit=${LIMIT}&page=1`);
      if (mealTypes?.results) {
        this.setState({ mealTypes: mealTypes?.results });
      }
      const userData = await axiosGet(`/users/${this.props.customerId}`);
      "userData: ", userData;
      if (userData) {
        this.setState({ userData: userData });
      }
    } catch (error) {
      console.error(error);
    }
  }

  formatHtmlToText = content => {
    let processedContent = "";

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    // Process each element within the document body
    Array.from(doc.body.childNodes).forEach(childNode => {
      if (childNode.nodeName.toLowerCase() === "p" && childNode.textContent) {
        processedContent += `${childNode.textContent}\n`;
      } else if (childNode.nodeName.toLowerCase() === "ol") {
        const listItems = childNode.querySelectorAll("li");
        listItems.forEach((item, index) => {
          processedContent += `${index + 1}. ${item.textContent}\n`;
        });
      } else if (childNode.nodeName.toLowerCase() === "ul") {
        const listItems = childNode.querySelectorAll("li");
        listItems.forEach(item => {
          processedContent += `• ${item.textContent}\n`;
        });
      }
    });
    return processedContent;
  };

  async getPortionGuide() {
    try {
      const response = await axiosGet("/portion-guide");

      if (response?.message) {
        console.error("Invalid response structure in getPortionGuide");
      } else {
        this.setState({ mealPlanData: response });
      }
    } catch (error) {
      console.error("Error in getPortionGuide:", error);
    }
  }

  urlToBase64 = async url => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      throw new Error("Error converting URL to base64: " + error.message);
    }
  };

  async getBase64(url) {
    try {
      const base64url = await this.urlToBase64(url);
      return base64url; // Return the base64 URL
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  }

  async imageBase64ByRecipe(data, portionGuide) {
    try {
      let imageBase64ByRecipe = {};
      let imageBase64ByPortionGuide = {};
      const promiseArray = [];

      for (const dayTime of data.dayTimes) {
        for (const recipe of dayTime.recipes) {
          try {
            const base64Data = await this.getBase64(
              recipe?.recipeId?.destination
            );
            imageBase64ByRecipe[`${recipe?.title}`] = base64Data;
          } catch (error) {
            console.error(
              `Error fetching base64 for recipe ${recipe?.title}:`,
              error
            );
          }
        }
      }

      const portionGuidePromises = Object.entries(portionGuide).map(
        async ([key, value]) => {
          try {
            const base64Data = await this.getBase64(value?.destination);
            imageBase64ByPortionGuide[key] = base64Data;
            // Perform operations with key and value here
          } catch (error) {
            console.error(
              `Error fetching base64 for portion guide ${key}:`,
              error
            );
          }
        }
      );
      promiseArray.push(...portionGuidePromises);

      await Promise.all(promiseArray);

      this.setState({ imageBase64ByTitle: imageBase64ByRecipe });
      return { imageBase64ByRecipe, imageBase64ByPortionGuide };
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  getCategories = async () => {
    try {
      let categoriesArray = [];
      const categories = await axiosGet(`/categories?limit=${LIMIT}&page=1`);
      if (categories?.results) {
        categories.results.forEach(category => {
          if (!category?.isDeleted) {
            categoriesArray.push({ name: category?.name, key: category?.key });
          }
        });
      }
      this.setState({ categories: categoriesArray });
    } catch (error) {
      console.error(error);
    }
  };

  setDynamicFontSize = (text, minFontSize = 14, baseLength = 50) => {
    var textLength = text.length;
    if (textLength < baseLength) return minFontSize;
    return minFontSize - Math.floor(textLength / baseLength) || 5;
  };

  generateMealTypeContent = (
    categorie,
    mappedPortions,
    portionKeyData,
    index,
    portionGuideImages
  ) => {
    let xTextPosition,
      yTextPosition,
      xCanvasPosition,
      yCanvasPosition,
      xImagePosition,
      yImagePosition,
      xPosition,
      yPosition;

    if (index <= 2) {
      //table absolute positioning
      yPosition = 340 + index * 160; // Calculate the new y position
      xPosition = 220; // Default xPosition
      if (index > 0) {
        xPosition = index % 2 === 0 ? 280 + 60 * Math.floor(index / 2 - 1) : 90;
      }

      //canvas absolute positioning
      xCanvasPosition = (index % 3) * 30 + 10; // Canvas x position increments of 30 (10, 40, 70)
      yCanvasPosition = index === 0 ? 10 : 0; // Canvas y position 10 for the first, 0 for the rest

      //text absolute positioning
      xTextPosition = (index + 1) % 3 ? 0 : 80; // Text x position increments of 8 (0, 0, 8)
      yTextPosition = 315 + index * 160; // Text y position increments (315, 475, 635)

      //image absolute positioning
      xImagePosition = index === 0 ? 20 : index === 1 ? 380 : 80; // Image x position (20, 380, 80)
      yImagePosition = 315 + index * 160; // Image y position increments
    } else {
      //text absolute positioning
      xTextPosition = (index - 3 + 1) % 3 ? 0 : 80; // Text x position increments of 8 (0, 0, 8)
      yTextPosition = 115 + (index - 3) * 160; // Text y position increments (315, 475, 635)
      //canvas absolute positioning
      xCanvasPosition = (index % 3) * 30 + 10; // Canvas x position increments of 30 (10, 40, 70)
      yCanvasPosition = index === 0 ? 10 : 0; // Canvas y position 10 for the first, 0 for the rest

      //image absolute positioning
      xImagePosition = index - 3 === 0 ? 20 : index - 3 === 1 ? 380 : 80; // Image x position (20, 380, 80)
      yImagePosition = 115 + (index - 3) * 160; // Image y position increments

      //table absolute positioning
      yPosition = 140 + (index - 3) * 160; // Calculate the new y position
      xPosition = 220; // Default xPosition
      if (index - 3 > 0) {
        xPosition =
          (index - 3) % 2 === 0
            ? 280 + 60 * Math.floor((index - 3) / 2 - 1)
            : 90;
      }
    }

    return [
      {
        canvas: [
          {
            type: "rect",
            x: xCanvasPosition,
            y: yCanvasPosition,
            w: 410,
            h: 150,
            r: 5,
            dash: { length: 10 },
            lineWidth: 3,
            lineColor: "black",
          },
        ],
      },
      {
        text: categorie.toUpperCase(),
        // pageBreak: "after",
        fontSize: 20,
        color: "#F05941", // Set text color to orange
        alignment: "center",
        margin: [0, 10, 0, 0],
        absolutePosition: { x: xTextPosition, y: yTextPosition },
        font: "Alumni",
      },
      {
        // margin: [180, 0, 0, 0], // Center the table
        // absolutePosition: { x: 230, y: 190 },
        table: {
          body: [
            [
              {
                text: "Carbohydrates :",
                bold: true,
                fontSize: 12,
                margin: [10, 5],
              },
              {
                text: mappedPortions[categorie]?.carbs || 0,
                fontSize: 12,
                margin: [10, 5],
              },
              {
                text:
                  portionKeyData[categorie.toUpperCase()] === "oneP"
                    ? "1P"
                    : portionKeyData[categorie.toUpperCase()] === "twoP"
                    ? "2P"
                    : portionKeyData[categorie.toUpperCase()] === "threeP"
                    ? "3P"
                    : "", // Text for the new column
                rowSpan: 4, // Rowspan set to 4
                fontSize: 20,
                margin: [10, 40],
                color: "#F05941", // Set text color to orange
              },
            ],
            [
              {
                text: "Protein :",
                bold: true,
                fontSize: 12,
                margin: [10, 5],
              },
              {
                text: mappedPortions[categorie]?.protein || 0,
                fontSize: 12,
                margin: [10, 5],
              },
            ],
            [
              {
                text: "Fat :",
                bold: true,
                fontSize: 12,
                margin: [10, 5],
              },
              {
                text: mappedPortions[categorie]?.fat || 0,
                fontSize: 12,
                margin: [10, 5],
              },
            ],
            [
              {
                text: "Calorie :",
                bold: true,
                fontSize: 12,
                margin: [10, 5],
              },
              {
                text: mappedPortions[categorie]?.calories || 0,
                // pageBreak: "after",
                fontSize: 12,
                margin: [10, 5],
              },
            ],
          ],
        },
        absolutePosition: { x: xPosition, y: yPosition },
        layout: "noBorders", // Move outside the body array
        fillColor: "#f5f0e1",
      },
      {
        image: portionGuideImages[categorie] || DINNER_IMG,
        width: 150,
        absolutePosition: { x: xImagePosition, y: yImagePosition },
      },
      // Any other elements or sections specific to the meal type
    ];
  };

  generatePDF = async () => {
    try {
      pdfMake.fonts = {
        RobotoCondensed: {
          normal: "Roboto-Condensed-SemiBold.ttf",
          bold: "Roboto-Condensed-SemiBold.ttf",
        },
        NotoAll: {
          normal: "Noto-Sans.ttf",
        },
        Abril: {
          normal: "Abril-Regular.ttf",
        },
        NotoNaskhArabic: {
          normal: "NotoNaskhArabic-Regular.ttf",
          bold: "NotoNaskhArabic-Bold.ttf",
        },
        Roboto: {
          normal: "Roboto-Regular.ttf",
          bold: "Roboto-Bold.ttf",
        },
        Alumni: {
          normal: "Alumni-Regular.ttf",
        },
        Cookie: {
          normal: "Cookie-Regular.ttf",
        },
      };

      this.props.setIsLoading(true);
      await this.getPortionGuide();
      await this.getCategories();

      let mealTypeKeys = [];
      this.state.mealTypes.map(mealType => {
        if (mealType?.isShowInPdf) {
          "mealType: ", mealType;
          mealTypeKeys.push(mealType?.key);
        }
      });
      const dietId = this.props.dietId;

      const data = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/userdiets/${dietId}`
      );

      const allRecipeImage = await this.imageBase64ByRecipe(
        data,
        this.state.mealPlanData
      );

      let portionKeyData = {};
      let mealTypesInDiet = [];
      let categoriesToMap = [];
      data.dayTimes.forEach(dayTime => {
        portionKeyData[dayTime?.category] = dayTime?.recipes[0]?.portionKey;
        if (dayTime?.recipes?.length > 0) {
          categoriesToMap.push(dayTime?.category);
          mealTypesInDiet.push(dayTime?.category);
        }
      });
      this.setState({ portionKeyData: portionKeyData });

      let mappedPortions = {};

      categoriesToMap.forEach(category => {
        if (
          mealTypeKeys.includes(category) &&
          portionKeyData.hasOwnProperty(category) &&
          category !== "SIDE_DISH" &&
          category !== "SNACK_AM" &&
          category !== "SNACK_PM"
        ) {
          let portionKey = portionKeyData[category];

          let portions =
            this.state.mealPlanData?.[category.toLowerCase()][portionKey];

          mappedPortions[category.toLowerCase()] = portions;
        } else if (
          mealTypeKeys.includes(category) &&
          portionKeyData.hasOwnProperty(category) &&
          category === "SIDE_DISH"
        ) {
          // Save only the first presented object for SIDE_DISH
          let sideDishData =
            this.state.mealPlanData?.[category.toLowerCase()]["oneP"];

          mappedPortions[category.toLowerCase()] = sideDishData;
        } else if (
          mealTypeKeys.includes(category) &&
          portionKeyData.hasOwnProperty(category) &&
          category === "SNACK_AM"
        ) {
          let snackAmData =
            this.state.mealPlanData?.[category.toLowerCase()]["twoP"];
          mappedPortions[category.toLowerCase()] = snackAmData;
        } else if (
          mealTypeKeys.includes(category) &&
          portionKeyData.hasOwnProperty(category) &&
          category === "SNACK_PM"
        ) {
          let snackPmData =
            this.state.mealPlanData?.[category.toLowerCase()]["threeP"];
          mappedPortions[category.toLowerCase()] = snackPmData;
        }
      });

      const mealContents = Object.entries(mappedPortions).map(
        ([category, data], index) =>
          this.generateMealTypeContent(
            category,
            mappedPortions,
            portionKeyData,
            index,
            allRecipeImage?.imageBase64ByPortionGuide
          )
      );

      const hasArabicCharacters = text => {
        // Regular expression to match Arabic characters
        const arabicRegex = /[\u0600-\u06FF]/;

        return arabicRegex.test(text);
      };
      // Define a common table style with padding and layout settings
      const tableStyle = {
        margin: [0, 5, 0, 5], // Add padding to rows
        layout: "lightHorizontalLines", // Add horizontal lines between rows
      };

      const currentDate = new Date();
      const day = currentDate.getDate();
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      const formattedDate = `${month}/${day}/${year}`;

      const createHeader = function (currentPage, pageCount) {
        if (currentPage !== 1) {
          return [
            // Logo on the left
            {
              alignment: "left",
              image: LOGO_BASE64,
              width: 100,
            },
            // Date on the right
            {
              alignment: "right",
              text: `Date : ${formattedDate}`,
              margin: [0],
              bold: true,
            },
          ];
        }
      };

      const docDefinition = {
        // header: createHeader,

        content: [
          {
            text: this.state.userData?.name,
            pageBreak: "after",
            fontSize: 30,
            bold: true,
            alignment: "center",
            font: "RobotoCondensed",
            absolutePosition: { x: 50, y: 780 },
          },
          {
            text: "MY MEAL PLAN",
            // pageBreak: "after",
            fontSize: 50,
            decoration: "underline", // Add underline
            color: "#F05941", // Set text color to orange
            bold: true,
            alignment: "center",
            // margin: [0, 70, 0, 0],
          },
          {
            margin: [190, 10, 0, 10],
            table: {
              body: [
                [
                  {
                    text: "Carbohydrates :",
                    bold: true,
                    fontSize: 12,
                    margin: [10, 5],
                  },
                  {
                    text: this.state.userData?.carbohydrate + "g",
                    fontSize: 12,
                    margin: [10, 5],
                  },
                ],
                [
                  {
                    text: "Protein :",
                    bold: true,
                    fontSize: 12,
                    margin: [10, 5],
                  },
                  {
                    text: this.state.userData?.protein + "g",
                    fontSize: 12,
                    margin: [10, 5],
                  },
                ],
                [
                  {
                    text: "Fat :",
                    bold: true,
                    fontSize: 12,
                    margin: [10, 5],
                  },
                  {
                    text: this.state.userData?.fat + "g",
                    fontSize: 12,
                    margin: [10, 5],
                  },
                ],
                [
                  {
                    text: "Calorie :",
                    bold: true,
                    fontSize: 12,
                    margin: [10, 5],
                  },
                  {
                    text: this.state.userData?.calorie,
                    // pageBreak: "after",
                    fontSize: 12,
                    margin: [10, 5],
                  },
                ],
              ],
            },
            layout: "noBorders", // Move outside the body array
            fillColor: "#f5f0e1",
          },
          ...mealContents.flat(),
          {
            text: "MY MEAL PLAN SUMMARY",
            pageBreak: "before",
            style: "title",
            alignment: "center",
            // margin: [0, 0, 0, 0],
            fontSize: 30,
            color: "green", // Set text color to orange
          },
          {
            table: {
              headerRows: 1,
              widths: [100, 100, 60, 50, 50, 50, 50, "*"],
              body: [
                [
                  {
                    text: "Meal Type",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Recipes",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Quantity",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Calorie",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Carbs",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Protein",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                  {
                    text: "Fat",
                    bold: true,
                    fontSize: 14,
                    margin: [0, 10],
                    alignment: "center",
                    borderColor: ["white", "green", "white", "green"],
                  },
                ],
                // Add other rows here as needed
              ],
            },
            layout: {
              fillColor: function (rowIndex, node, columnIndex) {
                return rowIndex === 0 ? "green" : "lightgray"; // Conditionally set green color for header row
              },
              // fillColor: "lightgray", // Set the background color for the entire table
              hLineColor: "", // Set the horizontal line (border) color to white
              hLineWidth: function (i, node) {
                return 0; // Set line width to 0 for rows other than the header and last row
              },
              // vLineWidth: function (i, node) {
              //   return 0; // Set line width to 0 for rows other than the header and last row
              // },
              // vLineColor: "white", // Set the vertical line (border) color to white
            },
            style: "tableStyle", // Apply the common table style
            // pageBreak: "after",
          },
        ],
        styles: {
          title: {
            fontSize: 18,
            margin: [0, 0, 0, 10],
          },
          tableStyle: {
            margin: [0, 10], // Add margin to all tables
          },
        },
        pageMargins: [40, 115, 40, 0], // Adjust top margin (60 in this case) as needed

        background: function (currentPage, pageSize) {
          if (currentPage === 1) {
            return {
              image: FIRST_PAGE_BACKGROUND, // Replace 'url_to_your_image' with the actual image URL
              width: pageSize.width,
              height: pageSize.height,
            };
          } else {
            return {
              image: BACKGROUD_IMAGE, // Replace 'url_to_your_image' with the actual image URL
              width: pageSize.width,
              height: pageSize.height,
            };
          }
          return null; // No background for other pages
        },
      };

      data.dayTimes.forEach(dayTime => {
        if (mealTypeKeys.includes(dayTime.category)) {
          const recipes = dayTime?.recipes || [];
          "dayTime?.recipes: ", dayTime?.recipes;

          recipes.forEach((recipe, index) => {
            "recipe: ", recipe;
            const isLastRecipe = index === recipes.length - 1;
            let portionKey = recipe?.portionKey;
            "portionKey: ", portionKey;
            let otherData = recipe?.recipeId[portionKey];
            "otherData: ", otherData;

            // Prepare the row for the PDF table
            const row = [
              {
                text:
                  index === 0
                    ? categoryShow[dayTime.category].toUpperCase()
                    : "", // Only display category in the first row
                fontSize: 16,
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                alignment: "center",
                rowSpan: recipes.length || 1,
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
              {
                text: recipe.title,
                margin: [0, 10],
                font: hasArabicCharacters(recipe.title)
                  ? "NotoNaskhArabic"
                  : "Roboto",
                alignment: "center",
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
              {
                text: otherData?.totalCalories,
                alignment: "center",
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
              {
                text: otherData?.totalQuantity + "g",
                alignment: "center",
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },

              {
                text: otherData?.nutriInfo?.carbohydrate + "g",
                alignment: "center",
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
              {
                text: otherData?.nutriInfo?.protein + "g",
                alignment: "center",
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
              {
                text: otherData?.nutriInfo?.fat + "g",
                alignment: "center",
                bold: true,
                margin: [0, 10],
                fillColor: "lightgray",
                borderColor: [
                  "lightgray",
                  "lightgray",
                  "lightgray",
                  "lightgray",
                ],
              },
            ];

            // Add row for the PDF table
            const contentLength = mealTypeKeys.filter(element =>
              categoriesToMap.includes(element)
            );

            docDefinition.content[4 + contentLength.length * 4].table.body.push(
              row
            );

            if (isLastRecipe) {
              // Add an empty row with colSpan for the image
              docDefinition.content[
                4 + contentLength.length * 4
              ].table.body.push([
                {
                  colSpan: 7,
                  image: HAZELNUT_IMAGE,
                  width: 150,
                  height: 50,
                  alignment: "center",
                  fillColor: "lightgray",
                  borderColor: [
                    "lightgray",
                    "lightgray",
                    "lightgray",
                    "lightgray",
                  ],
                },
              ]);
            }
          });
        }
      });

      // docDefinition.content.push({ text: "\n", pageBreak: "before" });

      data.dayTimes.forEach((dayTime, index) => {
        if (
          mealTypeKeys.includes(dayTime.category) &&
          dayTime?.recipes?.length !== 0
        ) {
          // if (index > 0) {
          docDefinition.content.push({ text: "\n", pageBreak: "before" });
          // }

          docDefinition.content.push([
            {
              image: HEADING_STYLE_IMG,
              margin: [10, 0, 0, 0],
              alignment: "center",
              width: 420,
            },
            [
              {
                text: "My",
                fontSize: 40,
                absolutePosition: { x: 40, y: 120 },
                alignment: "center",
                margin: [10, 0, 0, 0],
                font: "Abril",
              },
              {
                text:
                  dayTime.category === "SNACK_PM"
                    ? "DESSERT"
                    : dayTime.category === "SNACK_AM"
                    ? "MORNING SNACK"
                    : categoryShow[dayTime.category]?.toUpperCase(),
                fontSize: 60,
                absolutePosition: { x: 40, y: 165 },
                alignment: "center",
                font: "Abril",
              },
              {
                image:
                  dayTime.category === "BREAKFAST"
                    ? BREAKFAST_PAGE_IMAGE
                    : dayTime.category === "LUNCH"
                    ? LUNCH_PAGE_IMAGE
                    : dayTime.category === "SNACK_PM"
                    ? SNACK_PM_PAGE_IMAGE
                    : dayTime.category === "SNACK_AM"
                    ? SNACK_AM_PAGE_IMAGE
                    : dayTime.category === "SIDE_DISH"
                    ? SIDE_DISH_PAGE_IMAGE
                    : DINNER_PAGE_IMAGE,
                width: 550,
                absolutePosition: { x: 40, y: 300 },
                alignment: "center",
                pageBreak: "after",
              },
            ],
          ]);

          let recipeCount = 1;

          dayTime.recipes.forEach(async (recipe, recipeIndex) => {
            if (recipe) {
              "recipe: ", recipe?.recipeId;
              const recipeData = recipe?.recipeId;
              if (recipeIndex > 0) {
                docDefinition.content.push({ text: "", pageBreak: "before" });
              }

              const recipeTitle = `${recipeCount}. ${recipe.recipeId.title}`;
              recipeCount++;

              docDefinition.content.push([
                {
                  text: "My",
                  style: "categoryTitle",
                  alignment: "center",
                  fontSize: 18, // Add the desired font size here
                  bold: true,
                  margin: [0, 10, 0, 0],
                  absolutePosition: { y: 55 }, // position of the image
                  //   pageBreak:"after"
                },
                {
                  text:
                    dayTime.category.charAt(0).toUpperCase() +
                    dayTime.category.slice(1).toLowerCase(),
                  style: "categoryTitle",
                  alignment: "center",
                  fontSize: 38, // Add the desired font size here
                  // bold: true,
                  font: "Cookie",
                  absolutePosition: { y: 80 }, // position of the image
                },
                {
                  image: CAP_IMAGE,
                  width: 50,
                  absolutePosition: { x: 295, y: 46 }, // position of the image
                },
              ]);

              let fontSizeHeading =
                recipeData?.ingredients.length > 24 ? 8 : 14;

              let fontSizeData = recipeData?.ingredients.length > 23 ? 6 : 12;

              const ingredientsTable = {
                table: {
                  // width: 150,
                  layout: "lightHorizontalLines",
                  widths: [90, 60, 60],
                  body: [
                    [
                      {
                        text: "Name",
                        bold: true,
                        fontSize: fontSizeHeading,
                        margin: [0, 2],
                        alignment: "center",
                        // width: "10", // Set a specific width for the first column
                      },
                      {
                        text: "Quantity",
                        bold: true,
                        fontSize: fontSizeHeading,
                        margin: [0, 2],
                      },
                      {
                        text: "Calories",
                        bold: true,
                        fontSize: fontSizeHeading,
                        margin: [0, 2],
                      },
                    ],
                    ...recipeData?.ingredients.map(ingredient => [
                      {
                        text: ingredient?.name,
                        margin: [0, 0],
                        fontSize: this.setDynamicFontSize(
                          ingredient?.name,
                          12,
                          4
                        ),
                        alignment: "center",
                      },
                      {
                        text:
                          typeof ingredient.quantity !== "undefined" &&
                          ingredient.quantity !== null
                            ? ingredient.quantity
                            : "-",
                        margin: [0, 0],
                        fontSize: fontSizeData,
                        alignment: "center",
                      },
                      {
                        text: ingredient.calories,
                        margin: [0, 0],
                        fontSize: fontSizeData,
                        alignment: "center",
                      },
                    ]),
                    // ...tableRows,
                  ],
                },
                layout: {
                  fillColor: "white", // Set the background color for the entire table
                },
              };
              const categorySections = {
                stack: data?.dietTypes?.map((categoryItem, index) => ({
                  columns: [
                    {
                      width: "auto",
                      image: recipeData?.dietTypes?.includes(
                        categoryItem?.value
                      )
                        ? CHECKED_IMG
                        : UNCHECKED_IMG,
                      width: 15, // Adjust the width of the checkbox image as needed
                    },
                    {
                      width: "*",
                      text:
                        categoryItem?.label?.charAt(0)?.toUpperCase() +
                        categoryItem?.label?.slice(1),
                      fontSize: this.setDynamicFontSize(
                        categoryItem?.label,
                        12,
                        8
                      ),
                      margin: [5, 0, 10, 0],
                    },
                  ],

                  absolutePosition: { x: 40 + index * 90, y: 815 }, // Adjust x position accordingly
                })),
              };

              const stepCount = recipeData?.recipe?.split(".").length;
              const adjustedStepCount = Math.max(stepCount, 25);
              const cookingDirections = recipeData?.recipe
                .replace(/&lt;/g, "<")
                .replace(/<\/?(p|ol|ul|li|br)>/g, "");

              const title = recipeData?.title;
              const imageBase64ByRecipe = allRecipeImage?.imageBase64ByRecipe;
              const contentTable = [
                {
                  absolutePosition: { x: 0, y: 0 },
                  canvas: [
                    {
                      type: "rect",
                      x: 20,
                      y: 125,
                      w: 565,
                      h: 720,
                      r: 50,
                      color: "white",
                    },
                  ],
                },
                {
                  absolutePosition: { x: 0, y: 0 },
                  canvas: [
                    {
                      type: "rect",
                      x: 290,
                      y: 165,
                      w: 200,
                      h: 200,
                      color: "orange",
                    },
                  ],
                },
                {
                  image: imageBase64ByRecipe[`${title}`] || DINNER_IMG,
                  width: 180,
                  height: 180,
                  absolutePosition: { x: 300, y: 175 },
                },
                {
                  text: `Recipe Name : ${recipeData?.title}`,
                  bold: true,
                  fontSize: 14,
                  margin: [0, 10],
                  font: hasArabicCharacters(recipeData?.title)
                    ? "NotoNaskhArabic"
                    : "Roboto",
                },
                {
                  canvas: [
                    {
                      type: "line",
                      x1: 0,
                      y1: 0,
                      x2: 485, // Adjust the length of the line as needed
                      y2: 0,
                      lineWidth: 1, // Width of the line
                      lineColor: "black", // Line color
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "auto",
                      text: "Serving",
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5, 10, 0],
                    },
                    {
                      width: "*",
                      text: `${recipeData?.servingPeople} people`,
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5],
                      decoration: "underline",
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: "auto",
                      text: "Preparation Time",
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5, 10, 0],
                    },
                    {
                      width: "*",
                      text: `${recipeData?.preparationTime} min`,
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5],
                      decoration: "underline",
                    },
                  ],
                },

                {
                  columns: [
                    {
                      width: "auto",
                      text: "Cooking Time",
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5, 10, 0],
                    },
                    {
                      width: "*",
                      text: `${recipeData?.cookingTime} min`,
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5],
                      decoration: "underline",
                    },
                  ],
                },

                {
                  columns: [
                    {
                      width: "auto",
                      text: "Main Ingredients",
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5, 10, 0],
                    },
                    {
                      width: 130,
                      text: recipeData?.mainIngredient,
                      bold: true,
                      fontSize:
                        recipeData?.mainIngredient?.length > 24
                          ? 10
                          : recipeData?.mainIngredient?.length > 14 &&
                            recipeData?.mainIngredient?.length < 24
                          ? 12
                          : 14,
                      margin: [0, 5],
                      font: hasArabicCharacters(recipeData?.mainIngredient)
                        ? "NotoNaskhArabic"
                        : "Roboto",
                      decoration: "underline",
                    },
                  ],
                },

                {
                  columns: [
                    {
                      width: "auto",
                      text: "Type of Cuisine",
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5, 10, 0],
                    },
                    {
                      width: "*",
                      text: `${recipeData?.typeOfCuisine}`,
                      bold: true,
                      fontSize: 14,
                      margin: [0, 5],
                      font: hasArabicCharacters(recipeData?.typeOfCuisine)
                        ? "NotoNaskhArabic"
                        : "Roboto",
                      decoration: "underline",
                    },
                  ],
                },
                {
                  columns: [
                    {
                      width: 250, // Set a smaller width for Ingredients
                      stack: [
                        {
                          text: "Ingredients :",
                          bold: true,
                          fontSize: 14,
                          margin: [0, 10],
                          font: "Roboto",
                        },
                        ingredientsTable,
                      ],
                    },

                    {
                      width: 300,
                      stack: [
                        {
                          text: `Shopping List `,
                          bold: true,
                          fontSize: 18,
                          margin: [0, 75, 0, 5],
                          font: hasArabicCharacters(
                            recipeData?.shopingList.join(",")
                          )
                            ? "NotoNaskhArabic"
                            : "Roboto",
                        },
                        {
                          text: `${recipeData?.shopingList.join(",")}`,
                          fontSize: this.setDynamicFontSize(
                            recipeData?.shopingList.join(","),
                            14,
                            50
                          ),
                          font: hasArabicCharacters(
                            recipeData?.shopingList.join(",")
                          )
                            ? "NotoNaskhArabic"
                            : "Roboto",
                        },
                        {
                          text: `Directions `,
                          margin: [0, 5, 0, 0],
                          bold: true,
                          fontSize: 18,
                          font: hasArabicCharacters(recipeData?.recipe)
                            ? "NotoNaskhArabic"
                            : "Roboto",
                        },

                        {
                          fontSize: this.setDynamicFontSize(
                            cookingDirections,
                            12,
                            350
                          ),

                          font: hasArabicCharacters(recipeData?.recipe)
                            ? "NotoNaskhArabic"
                            : "Roboto",
                          stack: htmlToPdfmake(
                            recipeData?.recipe.replace(/&lt;/g, "<")
                          ),
                        },
                      ],
                    },
                  ],
                },
                categorySections,
              ];

              docDefinition.content.push(...contentTable);
              // docDefinition.content.push(contentWithBackground);

              docDefinition.content.push({ text: "\n", pageBreak: "before" });

              const portionKey = recipe?.portionKey;
              "portionKey: ", portionKey;

              docDefinition.content.push([
                {
                  text: "My",
                  style: "categoryTitle",
                  alignment: "center",
                  fontSize: 18, // Add the desired font size here
                  bold: true,
                  margin: [0, 10, 0, 0],
                  absolutePosition: { y: 55 }, // position of the image
                },
                {
                  text: "Portion Guide",
                  style: "categoryTitle",
                  alignment: "center",
                  fontSize: 38, // Add the desired font size here
                  font: "Cookie",
                  absolutePosition: { y: 75 }, // position of the image
                },
                {
                  image: BULB_IMG,
                  width: 50,
                  absolutePosition: { x: 290, y: 30 }, // position of the image
                },
              ]);

              const itemsCount = recipe.recipeId[portionKey].items.length;
              const baseHeight = 250;
              const additionalHeight = Math.max(0, itemsCount - 4) * 30; // Calculate additional height

              const canvasHeight = baseHeight + additionalHeight;

              docDefinition.content.push([
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 10,
                      w: 515,
                      h: canvasHeight,
                      r: 50,
                      color: "lightgreen",
                    },
                  ],
                },
                {
                  absolutePosition: { x: 523, y: 240 },
                  text:
                    portionKey === "oneP"
                      ? "1P"
                      : portionKey === "twoP"
                      ? "2P"
                      : "3P",
                  bold: true,
                  fontSize: 24,
                },
                {
                  image: HAZELNUT_IMAGE,
                  width: 150,
                  height: 50,
                  alignment: "center",
                },
              ]);

              const portionTable = {
                table: {
                  layout: "lightHorizontalLines", // Add horizontal lines between rows
                  widths: [90, 75, 75],
                  body: [
                    [
                      {
                        text: "Item Name",
                        bold: true,
                        fontSize: 14,
                        margin: [5, 5],
                      },
                      {
                        text: "Quantity",
                        bold: true,
                        fontSize: 14,
                        margin: [5, 5],
                      },
                      {
                        text: "Calories",
                        bold: true,
                        fontSize: 14,
                        margin: [5, 5],
                      },
                    ],
                  ],
                  style: "tableStyle", // Apply the common table style
                },
                absolutePosition: { x: 55, y: 170 },
                layout: {
                  fillColor: "green", // Set the background color for the entire table
                },
              };

              // First Table - Items
              let totalCalories = recipe.recipeId[portionKey]?.totalCalories;
              let totalQuantity = recipe.recipeId[portionKey]?.totalQuantity;

              recipe.recipeId[portionKey].items.forEach(item => {
                portionTable.table.body.push([
                  {
                    text: item?.name, // Make the item.name bold
                    bold: true,
                    font: hasArabicCharacters(item.name)
                      ? "NotoNaskhArabic"
                      : "Roboto",
                    margin: [5, 5],
                    fontSize: item?.name?.length > 25 ? 10 : 12,
                  },
                  {
                    text:
                      typeof item.quantity !== "undefined" &&
                      item.quantity !== null
                        ? typeof item.quantity === "string"
                          ? item.quantity
                          : item.quantity + "g"
                        : "-",

                    bold: true,
                    margin: [5, 5],
                    alignment: "center",
                  },
                  {
                    text: item.calories, // Make the item.name bold
                    bold: true,

                    margin: [5, 5],
                    alignment: "center",
                  },
                ]);
              });

              // Last Row for Total Quantity and Total Calories
              portionTable.table.body.push([
                {
                  text: "Total",
                  bold: true,
                  fontSize: 14,
                  margin: [10, 5],
                },
                {
                  text: totalQuantity + "g",
                  bold: true,
                  fontSize: 14,
                  margin: [10, 5],
                  alignment: "center",
                },
                {
                  text: totalCalories,
                  bold: true,
                  fontSize: 14,
                  margin: [10, 5],
                  alignment: "center",
                },
              ]);

              const nutriTable = {
                table: {
                  layout: "lightHorizontalLines",
                  widths: [95, 75],
                  body: [
                    [
                      {
                        text: "Nutri. info.",
                        bold: true,
                        fontSize: 14,
                        margin: [10, 5],
                      },
                      {
                        text: "Amount",
                        bold: true,
                        fontSize: 14,
                        margin: [10, 5],
                      },
                    ],
                  ],
                  style: "tableStyle",
                },

                absolutePosition: { x: 330, y: 170 },
                layout: {
                  fillColor: "green",
                },
              };

              const nutriInfo = recipe.recipeId[portionKey].nutriInfo;
              "nutriInfo: ", nutriInfo;

              Object.entries(nutriInfo).forEach(([nutrient, value]) => {
                if (nutrient !== "starch" && nutrient !== "fiber") {
                  "value: ", value;
                  "nutrient: ", nutrient;
                  // Convert the value to a string, or handle it according to your needs
                  const formattedValue =
                    typeof value === "object" ? JSON.stringify(value) : value;

                  nutriTable.table.body.push([
                    {
                      text:
                        nutrient.charAt(0).toUpperCase() + nutrient.slice(1),
                      bold: true,
                      margin: [10, 5],
                    },
                    {
                      text: formattedValue + "g",
                      bold: true,
                      margin: [10, 5],
                      alignment: "center",
                    },
                  ]);
                }
              });

              docDefinition.content.push(nutriTable);
              docDefinition.content.push(portionTable);
              // docDefinition.content.push({ text: "\n", pageBreak: "after" });
            }
          });
        }
      });
      pdfMake.createPdf(docDefinition).download("recipe.pdf");
      this.props.setIsLoading(false);
    } catch (error) {
      this.props.setIsLoading(false);
      console.error(error);
    }
  };

  render() {
    return (
      <div>
        <i
          className="mdi mdi-file-pdf font-size-18"
          id="pdftooltip"
          onClick={this.generatePDF}
        ></i>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLoadingPdf: state.Recipe.isLoadingPdf, // Assuming your Redux state has a 'rows' property
});

const mapDispatchToProps = {
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(Pdf);
