import React, { Component } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Toast extends Component {
  showToast() {
    const { isSuccess, message } = this.props;

    if (isSuccess) {
      toast.success(message, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      toast.error(message, {
        autoClose: 3000,
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }

  componentDidMount() {
    this.showToast();
  }

  render() {
    return (
      <div>
        {/* This is the ToastContainer for toast notifications */}
        <ToastContainer />
      </div>
    );
  }
}

export default Toast;
