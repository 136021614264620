import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { LIMIT, SIZE_PER_PAGE } from "../../constants/pagination";
import Toast from "components/Common/Toast";

class MealType extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
    this.state = {
      users: [],
      mealType: {},
      userData: JSON.parse(sessionStorage.getItem("user")),
      mealTypes: [], // Initialize mealTypes state
      modal: false,
      deleteModal: false,
      errorMsg: "",
      successMsg: "",
      selectMealTypesByAdmin: "",
      languageListColumns: [
        {
          text: "id",
          dataField: "id",
          sort: true,
          hidden: true,
          formatter: (cellContent, mealType) => <>{mealType.id}</>,
        },
        {
          text: "Position",
          dataField: "index",
          sort: true,
          //   hidden: true,
          formatter: (cellContent, mealType) => <>{mealType.index}</>,
        },
        {
          text: "Name",
          dataField: "name",
          sort: true,
        },
        // {
        //   dataField: "menu",
        //   isDummyField: true,
        //   editable: false,
        //   text: "Action",
        //   formatter: (cellContent, mealType) => (
        //     <div className="d-flex gap-3">
        //       <Link className="text-success" to="#">
        //         <i
        //           className="mdi mdi-pencil font-size-18"
        //           id="edittooltip"
        //           onClick={() => this.handleUserClick(mealType)}
        //         ></i>
        //       </Link>
        //       <Link className="text-danger" to="#">
        //         <i
        //           className="mdi mdi-delete font-size-18"
        //           id="deletetooltip"
        //           onClick={() => this.onClickDelete(mealType)}
        //         ></i>
        //       </Link>
        //     </div>
        //   ),
        // },
      ],
    };
    this.handleUserClick = this.handleUserClick.bind(this);
    this.toggle = this.toggle.bind(this);
    this.saveChanges = this.saveChanges.bind(this);
    this.onClickDelete = this.onClickDelete.bind(this);
  }

  componentDidMount() {
    const { users, onGetUsers } = this.props;
    if (users && !users.length) {
      onGetUsers();
    }
    this.getMealTypes();
    this.setState({ users });
  }

  async getMealTypes() {
    try {
      const mealTypes = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/meal-types?page=1&limit=${LIMIT}`
      );
      if (mealTypes?.message) {
        this.setState({ errorMsg: mealTypes?.message });
      } else {
        this.setState({ mealTypes: mealTypes?.results }); // Set the mealTypes in the state
      }
    } catch (error) {
      console.error("error getMealTypes: ", error);
    }
  }

  async handleFilter() {
    try {
      const mealTypes = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/meal-types?createdBy=${this.state.userData.id}&page=1&limit=${LIMIT}`
      );
      if (mealTypes?.message) {
        this.setState({ errorMsg: mealTypes?.message });
      } else {
        this.setState({ mealTypes: mealTypes?.results }); // Set the mealTypes in the state
      }
    } catch (error) {
      console.error("error handleFilter: ", error);
    }
  }

  handleCheckboxChange = () => {
    this.setState(
      prevState => ({
        selectMealTypesByAdmin: !prevState.selectMealTypesByAdmin,
      }),
      () => {
        this.handleFilter();
        if (!this.state.selectMealTypesByAdmin) {
          this.getMealTypes();
        }
      }
    );
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  async saveChanges() {
    try {
      const response = await Promise.all(
        this.state.mealTypes.map(async mealType => {
          if (mealType?.id) {
            return await axiosPatch(`/meal-types/${mealType?.id}`, {
              isShowInPdf: mealType?.isShowInPdf || false,
            });
          }
          return { message: "Id is undefined" };
        })
      );
      this.setState({ successMsg: "Meal Types Updated" });
    } catch (error) {
      console.error("error saveChanges: ", error);
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { users } = this.props;
    if (!isEmpty(users) && size(prevProps.users) !== size(users)) {
      this.setState({ users: {}, isEdit: false });
    }
  }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  /* Insert,Update Delete data */

  toggleDeleteModal() {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal,
    }));
  }

  onClickDelete(mealType) {
    this.setState({ mealType: mealType });
    this.setState({ deleteModal: true });
  }

  handleUserClick(mealType) {
    this.setState({
      mealType: {
        id: mealType?.id,
        name: mealType.name,
      },
      isEdit: true,
    });

    this.toggle();
  }

  async handleAddUser(data) {
    try {
      const response = await axiosPost(
        `${process.env.REACT_APP_BASE_URL}/meal-types`,
        data
      );
      if (response?.message) {
        this.setState({ errorMsg: response?.message });
      } else {
        if ((this, this.state.selectMealTypesByAdmin)) {
          this.handleFilter();
        } else {
          this.getMealTypes();
        }
        this.toggle();
      }
    } catch (error) {
      console.error("error handleAddUser: ", error);
    }
  }

  async handleUpdateUser(id, data) {
    try {
      const response = await axiosPatch(
        `${process.env.REACT_APP_BASE_URL}/meal-types/${id}`,
        data
      );
      if (response?.message) {
        this.setState({ errorMsg: response?.message });
      } else {
        if ((this, this.state.selectMealTypesByAdmin)) {
          this.handleFilter();
        } else {
          this.getMealTypes();
        }
        this.toggle();
      }
    } catch (error) {
      console.error("error handleUpdateUser: ", error);
    }
  }

  //   async handleDeleteUser(id) {
  //     try {
  //       const response = await axiosDel(
  //         `${process.env.REACT_APP_BASE_URL}/meal-types/${id}`
  //       );
  //       if (response?.message) {
  //         this.setState({ errorMsg: response?.message });
  //       } else {
  //         if ((this, this.state.selectMealTypesByAdmin)) {
  //           this.handleFilter();
  //         } else {
  //           this.getMealTypes();
  //         }
  //         this.setState({ deleteModal: false });
  //       }
  //     } catch (error) {
  //       console.error("error handleDeleteUser: ", error);
  //     }
  //   }

  handleRowSelect(row, isSelected) {
    const { id } = row;
    if (id) {
      this.setState({
        mealTypes: this.state.mealTypes.map(mealType => {
          if (mealType?.id === id) {
            return { ...mealType, isShowInPdf: isSelected };
          }
          return mealType;
        }),
      });
    }
  }

  handleSelectAll(isSelected, rows) {
    this.setState({
      mealTypes: this.state.mealTypes.map(mealType => ({
        ...mealType,
        isShowInPdf: isSelected,
      })),
    });
  }

  render() {
    const { SearchBar } = Search;

    const { isEdit, deleteModal, mealTypes } = this.state;

    const mealType = this.state.mealType;

    const pageOptions = {
      sizePerPage: SIZE_PER_PAGE,
      totalSize: mealTypes?.length, // replace later with size(mealTypes),
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "index", // if dataField is not match to any column you defined, it will be ignored.
        order: "asc", // desc or asc
      },
    ];

    return (
      <React.Fragment>
        {this.state.errorMsg ? (
          <Toast isSuccess={false} message={this.state.errorMsg} />
        ) : this.state.successMsg ? (
          <Toast isSuccess={true} message={this.state.successMsg} />
        ) : (
          ""
        )}
        <Card className="h-100">
          <CardBody>
            <div className="ms-1">
              <h4 className="mb-3 font-size-18 text-center">Meal Types</h4>
            </div>
            <div className="d-flex justify-content-end">
              <div className="text-sm-end">
                <Button
                  color="primary"
                  className="font-16 btn-block btn btn-primary mb-3"
                  onClick={this.saveChanges}
                >
                  Save Changes
                </Button>
              </div>
            </div>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="id"
              columns={this.state.languageListColumns}
              data={mealTypes}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  columns={this.state.languageListColumns}
                  data={mealTypes}
                  search
                >
                  {toolkitprops => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col
                          sm="6"
                          className={`${
                            this.state.mealTypes.length === 0
                              ? "display-none"
                              : ""
                          }`}
                        >
                          <div className="search-box ms-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitprops.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="d-flex justify-content-center">
                            <img
                              src={emptyContacts}
                              alt="empty-mealTypes"
                              className={`h-10rem ${
                                this.state.mealTypes.length === 0
                                  ? ""
                                  : "display-none"
                              }`}
                            ></img>
                          </div>
                          <div
                            className={`table-responsive ${
                              this.state.mealTypes.length === 0
                                ? "display-none"
                                : ""
                            }`}
                          >
                            {/* {this.state.mealTypes.length === 0 ? <div className="d-flex justify-content-center">
                                      <img src={emptyContacts} alt="empty-conatct"></img>
                                    </div> : */}
                            <BootstrapTable
                              {...toolkitprops.baseProps}
                              {...paginationTableProps}
                              defaultSorted={defaultSorted}
                              data={this.state.mealTypes} // Use mealTypes from the state
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              bordered={false}
                              striped={false}
                              responsive
                              selectRow={{
                                mode: "checkbox",
                                clickToSelect: true,
                                selected: mealTypes.map(mealType =>
                                  mealType?.isShowInPdf ? mealType?.id : ""
                                ),
                                onSelect: (row, isSelect) =>
                                  this.handleRowSelect(row, isSelect),
                                onSelectAll: (isSelect, rows) =>
                                  this.handleSelectAll(isSelect, rows),
                              }}
                              ref={this.node}
                            />
                            {/* }  */}

                            <Modal
                              isOpen={this.state.modal}
                              className={this.props.className}
                            >
                              <ModalHeader toggle={this.toggle} tag="h4">
                                {!!isEdit ? "Edit MealTypes" : "Add MealTypes"}
                              </ModalHeader>
                              <ModalBody>
                                <Formik
                                  enableReinitialize={true}
                                  initialValues={{
                                    name: (mealType && mealType.name) || "",
                                  }}
                                  validationSchema={Yup.object().shape({
                                    name: Yup.string().required(
                                      "Please Enter MealTypes Name"
                                    ),
                                  })}
                                  onSubmit={values => {
                                    if (isEdit) {
                                      if (mealType?.name !== values?.name) {
                                        const updateUser = {
                                          name: values.name,
                                        };
                                        this.handleUpdateUser(
                                          mealType?.id,
                                          updateUser
                                        );
                                      } else {
                                        this.toggle();
                                      }
                                    } else {
                                      const newUser = {
                                        name: values["name"],
                                      };
                                      // save new mealType
                                      this.handleAddUser(newUser);
                                    }
                                    this.setState({
                                      selectedUser: null,
                                    });
                                    // this.toggle();
                                  }}
                                >
                                  {({ errors, status, touched }) => (
                                    <Form>
                                      {this.state.errorMsg ? (
                                        <span className="text-danger ms-2">
                                          {this.state.errorMsg}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                      <Row>
                                        <Col className="col-12">
                                          <div className="mb-3">
                                            <Label className="form-label">
                                              Name
                                            </Label>
                                            <Field
                                              name="name"
                                              type="text"
                                              className={
                                                "form-control" +
                                                (errors.name && touched.name
                                                  ? " is-invalid"
                                                  : "")
                                              }
                                            />
                                            <ErrorMessage
                                              name="name"
                                              component="div"
                                              className="invalid-feedback"
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <div className="text-end">
                                            <button
                                              type="submit"
                                              className="btn btn-success save-user"
                                            >
                                              Save
                                            </button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Form>
                                  )}
                                </Formik>
                              </ModalBody>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                      <Row className="align-items-md-center mt-30">
                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                          <PaginationListStandalone {...paginationProps} />
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </React.Fragment>
    );
  }
}

MealType.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MealType));
