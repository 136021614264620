import React, { Component } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { connect } from "react-redux";
import { portions } from "constants/diet";

class ItemsNutriInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  calculateTotal = array => {
    let totalQuantity = 0;
    let totalCalories = 0;

    array.forEach(item => {
      const quantityValue = parseFloat(item?.quantity);
      const caloriesValue = parseFloat(item?.calories);

      // Check if the extracted values are valid numbers
      if (!isNaN(quantityValue)) {
        totalQuantity += quantityValue;
      }

      if (!isNaN(caloriesValue)) {
        totalCalories += caloriesValue;
      }
    });

    return {
      totalCalories,
      totalQuantity,
    };
  };

  getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
  };

  render() {
    const { moreItems, nutriInfo } = this.props;

    return (
      <Form>
        <div className="">
          {Object.keys(moreItems).map(portionKey => (
            <>
              <h3 className="me-2 text-light text-dark">{`${this.getKeyByValue(
                portions,
                portionKey
              )}p`}</h3>
              <div key={portionKey} className="d-flex">
                <Table className="table table-striped table-bordered w-75">
                  <Thead>
                    <Tr>
                      <Th>Item Name</Th>
                      <Th>Quantity (in g)</Th>
                      <Th>Calories (in cal)</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {moreItems[portionKey].map((item, index) => (
                      <Tr key={index}>
                        <Td>{item.name}</Td>
                        <Td>{item.quantity}</Td>
                        <Td>{item.calories}</Td>
                      </Tr>
                    ))}
                    <Tr>
                      <Th>Total</Th>
                      <Th>
                        {this.calculateTotal(moreItems[portionKey])
                          ?.totalQuantity || "0"}{" "}
                      </Th>
                      <Th>
                        {this.calculateTotal(moreItems[portionKey])
                          ?.totalCalories || "0"}{" "}
                      </Th>
                    </Tr>
                  </Tbody>
                </Table>
                <Table className="table table-striped table-bordered w-25 ms-4">
                  <Thead>
                    <Tr>
                      <Th>Nutri Info</Th>
                      <Th>Amount</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    <Tr>
                      <Th>CHO (carbs)</Th>
                      <Td>{nutriInfo[portionKey].carbohydrate}</Td>
                    </Tr>
                    <Tr>
                      <Th>CHON (protein)</Th>
                      <Td>{nutriInfo[portionKey].protein}</Td>
                    </Tr>
                    <Tr>
                      <Th>Fat</Th>
                      <Td>{nutriInfo[portionKey].fat}</Td>
                    </Tr>
                    {/* <Tr>
                      <Th>Starch</Th>
                      <Td>{nutriInfo[portionKey].starch}</Td>
                    </Tr>
                    <Tr>
                      <Th>Fiber</Th>
                      <Td>{nutriInfo[portionKey].fiber}</Td>
                    </Tr> */}
                  </Tbody>
                </Table>
              </div>
            </>
          ))}
        </div>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  ingredients: state.Recipe.ingredients,
  showItemModal: state.Recipe.showItemModal,
  moreItems: state.Recipe.items,
  nutriInfo: state.Recipe.nutriInfo,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ItemsNutriInfo);
