import Breadcrumbs from "components/Common/Breadcrumb";
import { showPortions } from "constants/diet";
import React, { Component } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Card, CardBody, CardTitle, Container, Label, Row } from "reactstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import Toast from "components/Common/Toast";

class EditPortionGuide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mealPlanData: {},
      mealTypeImage: {},
      apiResponse: "",
    };
  }

  async componentDidMount() {
    this.getPortionGuide();
  }

  capitalizeFirstLetter = string => {
    if (string.includes("_")) {
      const words = string.split("_");
      const capitalizedWords = words.map(
        word => word.charAt(0).toUpperCase() + word.slice(1)
      );
      return capitalizedWords.join(" ");
    } else {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  };

  async getPortionGuide() {
    try {
      const response = await axiosGet("/portion-guide");
      if (response?.message) {
        console.error("response error in getPortionGuide: ", response?.message);
      } else {
        const imageData = {};
        Object.entries(response).forEach(([mealType, details]) => {
          // Assuming `details.destination` contains the image URL
          imageData[mealType] = {
            destination: details?.destination,
            originalname: details?.originalname,
            filename: details?.filename,
            mimetype: details?.mimetype,
          };
        });

        this.setState({ mealPlanData: response, mealTypeImage: imageData });
      }
    } catch (error) {
      console.error("error getPortionGuide: ", error);
    }
  }

  handleFileChange = async (event, mealType) => {
    const formData = new FormData();
    const file = event.target.files[0];

    formData.append("file", file);

    for (const entry of formData.entries()) {
      // console.log(entry[0], entry[1]);
    }

    try {
      const response = await axiosPost(`/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.setState({
        mealTypeImage: {
          ...this.state.mealTypeImage,
          [mealType]: response,
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  handleSubmit = async values => {
    try {
      const transformedData = Object.entries(values).reduce(
        (acc, [key, value]) => {
          const [mealType, portion, nutrient] = key.split("-");
          if (!acc[mealType]) {
            acc[mealType] = {};
          }
          if (!acc[mealType][portion]) {
            acc[mealType][portion] = {};
          }
          acc[mealType][portion][
            nutrient === "carbohydrate" ? "carbs" : nutrient
          ] = value;
          acc[mealType]["originalname"] =
            this.state.mealTypeImage[mealType]?.originalname;
          acc[mealType]["mimetype"] =
            this.state.mealTypeImage[mealType]?.mimetype;
          acc[mealType]["filename"] =
            this.state.mealTypeImage[mealType]?.filename;
          acc[mealType]["destination"] =
            this.state.mealTypeImage[mealType]?.destination;
          return acc;
        },
        {}
      );

      const updatePortionGuide = await axiosPatch(
        "/portion-guide/update/",
        transformedData
      );
      if (updatePortionGuide?.message) {
        this.setState({
          apiResponse: {
            isSuccess: false,
            message: updatePortionGuide?.message,
          },
        });
      } else {
        this.setState({
          apiResponse: {
            isSuccess: true,
            message: "Portion Guide updated successfully",
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
    // Handle form submission logic here
  };

  render() {
    const { mealPlanData } = this.state;

    return (
      <div className="page-content">
        <Breadcrumbs
          title="Diet-Delights"
          breadcrumbItem={`${
            this.props.match.params.recipeId
              ? this.state.title
              : "Portion Guide"
          }`}
        />
        <Container fluid={true}>
          <Row>
            {/* <Col lg={6}> */}
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-2">Edit Portion Guide</CardTitle>
                {this.state.apiResponse && (
                  <Toast
                    isSuccess={this.state.apiResponse?.isSuccess}
                    message={this.state.apiResponse?.message}
                  />
                )}
                <Formik
                  enableReinitialize
                  initialValues={Object.keys(mealPlanData).reduce(
                    (acc, mealType) => {
                      const portions = Object.keys(
                        mealPlanData[mealType]
                      ).filter(key => ["oneP", "twoP", "threeP"].includes(key));

                      portions.forEach(portion => {
                        acc[`${mealType}-${portion}-carbohydrate`] =
                          mealPlanData[mealType]?.[portion]?.carbs || 0;
                        acc[`${mealType}-${portion}-protein`] =
                          mealPlanData[mealType]?.[portion]?.protein || 0;
                        acc[`${mealType}-${portion}-fat`] =
                          mealPlanData[mealType]?.[portion]?.fat || 0;
                        acc[`${mealType}-${portion}-calories`] =
                          mealPlanData[mealType]?.[portion]?.calories || 0;
                      });
                      return acc;
                    },
                    {}
                  )}
                  validationSchema={Yup.object().shape(
                    Object.keys(mealPlanData).reduce((acc, mealType) => {
                      const portions = Object.keys(
                        mealPlanData[mealType]
                      ).filter(key => ["oneP", "twoP", "threeP"].includes(key));

                      portions.forEach(portion => {
                        acc[`${mealType}-${portion}-carbohydrate`] =
                          Yup.string().required(
                            `${this.capitalizeFirstLetter(
                              mealType
                            )} ${portion} Carbohydrate is required`
                          );
                        acc[`${mealType}-${portion}-protein`] =
                          Yup.string().required(
                            `${this.capitalizeFirstLetter(
                              mealType
                            )} ${portion} Protein is required`
                          );
                        acc[`${mealType}-${portion}-fat`] =
                          Yup.string().required(
                            `${this.capitalizeFirstLetter(
                              mealType
                            )} ${portion} Fat is required`
                          );
                        acc[`${mealType}-${portion}-calories`] =
                          Yup.string().required(
                            `${this.capitalizeFirstLetter(
                              mealType
                            )} ${portion} Calories is required`
                          );
                      });

                      return acc;
                    }, {})
                  )}
                  onSubmit={this.handleSubmit}
                >
                  {({ values, errors, touched }) => (
                    <Form className="mt-3 edit-portion-guide-table">
                      <Table
                        border="1"
                        className=" table table-striped table-bordered"
                      >
                        <Thead>
                          <Tr>
                            <Th className="text-center">Meal Type</Th>
                            <Th className="text-center">Image</Th>
                            <Th className="text-center">Portion</Th>
                            <Th className="text-center">Nutrition Info</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {Object.entries(mealPlanData).map(
                            ([mealType, mealDetails]) => {
                              const portions = Object.keys(mealDetails).filter(
                                key => ["oneP", "twoP", "threeP"].includes(key)
                              );

                              const rowSpan = portions.length || 1; // Set a default of 1 if no valid portions found

                              return (
                                <React.Fragment key={mealType}>
                                  {portions.map((portion, index) => (
                                    <Tr key={`${mealType}-${portion}`}>
                                      {index === 0 && (
                                        <>
                                          <Td
                                            rowSpan={rowSpan}
                                            className="fw-bold text-center align-middle"
                                          >
                                            {this.capitalizeFirstLetter(
                                              mealType
                                            )}
                                          </Td>
                                          <Td
                                            rowSpan={rowSpan}
                                            className="text-center "
                                          >
                                            <div className="mb-5">
                                              <img
                                                src={
                                                  this.state.mealTypeImage[
                                                    mealType
                                                  ]?.destination
                                                }
                                                alt="meal image"
                                                className="meal-type-image"
                                              ></img>
                                            </div>
                                            <div className="mb-3">
                                              {/* <Label className="form-label mt-2 me-5">
                                                Upload Image
                                              </Label> */}
                                              <div className="d-flex flex-column ">
                                                <input
                                                  type="file"
                                                  className="ms-3 btn btn-primary "
                                                  onChange={event => {
                                                    this.handleFileChange(
                                                      event,
                                                      mealType
                                                    );
                                                  }}
                                                ></input>
                                              </div>
                                            </div>
                                          </Td>
                                        </>
                                      )}
                                      <Td className="fw-bold">
                                        {showPortions[portion]}
                                      </Td>
                                      <Td>
                                        <ul>
                                          <div className="mb-3 d-flex">
                                            <Label className="form-label me-5">
                                              Calories :
                                            </Label>
                                            <div>
                                              <Field
                                                name={`${mealType}-${portion}-calories`}
                                                type="text"
                                                placeholder="ex : 50 g"
                                                className={
                                                  "form-control width-10rem" +
                                                  (errors[
                                                    `${mealType}-${portion}-calories`
                                                  ] &&
                                                  touched[
                                                    `${mealType}-${portion}-calories`
                                                  ]
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${mealType}-${portion}-calories`}
                                                component="div"
                                                className="invalid-feedback "
                                              />
                                            </div>
                                          </div>
                                          <div className="mb-3 d-flex">
                                            <Label className="form-label me-2">
                                              Carbohydrate :
                                            </Label>
                                            <div>
                                              <Field
                                                name={`${mealType}-${portion}-carbohydrate`}
                                                type="text"
                                                placeholder="ex : 50 g"
                                                className={
                                                  "form-control width-10rem" +
                                                  (errors[
                                                    `${mealType}-${portion}-carbohydrate`
                                                  ] &&
                                                  touched[
                                                    `${mealType}-${portion}-carbohydrate`
                                                  ]
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${mealType}-${portion}-carbohydrate`}
                                                component="div"
                                                className="invalid-feedback "
                                              />
                                            </div>
                                          </div>
                                          <div className="mb-3 d-flex ">
                                            <Label className="form-label me-5">
                                              Protein :
                                            </Label>
                                            <div>
                                              <Field
                                                name={`${mealType}-${portion}-protein`}
                                                type="text"
                                                placeholder="ex : 40 g"
                                                className={
                                                  "form-control width-10rem ms-2" +
                                                  (errors[
                                                    `${mealType}-${portion}-protein`
                                                  ] &&
                                                  touched[
                                                    `${mealType}-${portion}-protein`
                                                  ]
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${mealType}-${portion}-protein`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>
                                          <div className="mb-3 d-flex">
                                            <Label className="form-label me-5">
                                              Fat :
                                            </Label>
                                            <div>
                                              <Field
                                                name={`${mealType}-${portion}-fat`}
                                                type="text"
                                                placeholder="ex : 50 g"
                                                className={
                                                  "form-control width-10rem ml-2rem" +
                                                  (errors[
                                                    `${mealType}-${portion}-fat`
                                                  ] &&
                                                  touched[
                                                    `${mealType}-${portion}-fat`
                                                  ]
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              />
                                              <ErrorMessage
                                                name={`${mealType}-${portion}-fat`}
                                                component="div"
                                                className="invalid-feedback"
                                              />
                                            </div>
                                          </div>
                                        </ul>
                                      </Td>
                                    </Tr>
                                  ))}
                                </React.Fragment>
                              );
                            }
                          )}
                        </Tbody>
                      </Table>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    );
  }
}

export default EditPortionGuide;
