import React, { Component, Fragment } from "react";

import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Formik, Field, Form, ErrorMessage, useFormikContext } from "formik";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import htmlToPdfmake from "html-to-pdfmake";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { categoryShow, categoryTitles, portions } from "constants/diet";
import ItemTable from "./ItemTable";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import Toast from "components/Common/Toast";
import { languages } from "constants/userRole";
import Ingredients from "./tables/Ingredients";
import { connect } from "react-redux";
import Items from "./tables/Items";
import {
  setIngredients,
  setShowItemModal,
  editItems,
  setNutriInfo,
  setItems,
} from "../../store/actions"; // Import your action
import ItemsNutriInfo from "./tables/ItemsNutriInfo";
import { object } from "prop-types";
import Select from "react-select";
import { LIMIT } from "constants/pagination";
import Multiselect from "multiselect-react-dropdown";
import { defaultSelect } from "constants/common";

class FormValidations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation: "",
      language: "",
      title: "",
      category: "",
      subCategory: "",
      typeOfMeal: "",
      mainIngrediants: "",
      shoppingList: "",
      recipe: "",
      typeOfCuisine: "",
      preparationTime: "",
      cookingTime: "",
      totalTime: "",
      modal: false,
      item: "",
      items: [],
      dietTypes: [],
      isEdit: false,
      errorMessage: "",
      editItem: false,
      addItem: false,
      ingredientsValidationError: "",
      moreItemsValidationError: "",
      imageUploaded: "",
      uploadImageInfo: "",
      imageValidationError: "",
      languageOptions: [],
      categoryOptions: [],
      selectedCategoryOptions: [],
      recipeList: [],
      matchingRecipes: []
    };
  }

  async componentDidMount() {
    const recipeId = this.props.match.params.recipeId;
    await this.getCategories();
    await this.getLanguages();
    await this.getRecipes();
    if (recipeId) {
      this.handleGetRecipeById();
    }
  }

  async getRecipes() {
    const recipeList = await axiosGet(
      `/recipes`
    );
    this.setState({ recipeList: recipeList?.recipes }); // Set the recipeList in the state
  }

  async getLanguages() {
    try {
      const languages = await axiosGet(`/languages?page=1&limit=${LIMIT}`);
      if (languages?.message) {
        this.setState({ errorMsg: languages?.message });
      } else {
        if (languages?.results) {
          const options = [
            ...languages?.results?.map(dietitian => ({
              value: dietitian?.key,
              label: dietitian?.name,
            })),
          ];
          this.setState({ languageOptions: options });
        }
      }
    } catch (error) {
      console.error("error getLanguages: ", error);
    }
  }

  async getCategories() {
    try {
      const categories = await axiosGet(`/categories?page=1&limit=${LIMIT}`);
      if (categories?.message) {
        this.setState({ errorMsg: languages?.message });
      } else {
        if (categories?.results) {
          const options = [
            ...categories?.results?.map((category, index) => ({
              value: category?.key,
              label: category?.name,
            })),
          ];
          this.setState({ categoryOptions: options });
        }
      }
    } catch (error) {
      console.error("error getCategories: ", error);
    }
  }

  componentWillUnmount() {
    this.props.setNutriInfo("");
    this.props.setIngredients("");
    this.props.setItems("");
    this.props.editItems(false);
  }

  removeIdFromObjects = array => {
    return array.map(obj => {
      const { _id, ...rest } = obj;
      return rest;
    });
  };

  removeIdFromObject = obj => {
    const { _id, ...rest } = obj;
    return rest;
  };

  handleGetRecipeById = async () => {
    try {
      const response = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/recipes/${this.props.match.params.recipeId}`
      );
      if (response) {
        const sanitizedNutriInfo = {
          oneP: this.removeIdFromObject(response?.oneP?.nutriInfo),
          twoP: this.removeIdFromObject(response?.twoP?.nutriInfo),
          threeP: this.removeIdFromObject(response?.threeP?.nutriInfo),
        };
        this.props.setNutriInfo(sanitizedNutriInfo);

        // Remove _id from each object in items
        const sanitizedItems = {
          oneP: this.removeIdFromObjects(response?.oneP?.items),
          twoP: this.removeIdFromObjects(response?.twoP?.items),
          threeP: this.removeIdFromObjects(response?.threeP?.items),
        };
        this.props.setItems(sanitizedItems);

        // Remove _id from each object in ingredients
        const sanitizedIngredients = this.removeIdFromObjects(
          response?.ingredients
        );
        this.props.setIngredients(sanitizedIngredients);
        const selectedCategoryOptions = this.state.categoryOptions.map(
          categoryOption => {
            const { value = "" } = categoryOption;

            if (value) {
              const included = response?.dietTypes?.some(
                dietType => dietType === value
              );
              if (included) return categoryOption;
            }
          }
        );


        const formattedRecipe = response?.recipe.replace(/&lt;/g, '<');
        const pdfmaketext = htmlToPdfmake(formattedRecipe)

        this.setState({
          language: response?.language,
          title: response?.title,
          category: response?.category,
          subCategory: response?.subCategory,
          typeOfMeal: response?.typeOfMeal,
          carbohydrate: response?.carbohydrate,
          protein: response?.protein,
          fat: response?.fat,
          mainIngrediants: response?.mainIngredient,
          shoppingList: response?.shopingList?.join(","),
          recipe: formattedRecipe,

          typeOfCuisine: response?.typeOfCuisine,
          preparationTime: response?.preparationTime,
          cookingTime: response?.cookingTime,
          totalTime: response?.totalTime,
          servesPeople: response?.servingPeople,
          isEdit: true,
          uploadImageInfo: {
            originalname: response?.originalname,
            destination: response?.destination,
            mimetype: response?.mimetype,
            filename: response?.filename,
          },
          imageUploaded: true,
          dietTypes: response?.dietTypes,
          selectedCategoryOptions: selectedCategoryOptions.filter(
            option => option
          ),
        });
      }
    } catch (error) {
      console.error("Error while getting recide by Id:", error);
    }
  };

  handlePreparationTimeChange = e => {
    const preparationTime = parseInt(e.target.value) || 0;
    this.setState({ preparationTime }, () => {
      this.calculateTotalTime();
    });
  };

  handleCookingTimeChange = e => {
    const cookingTime = parseInt(e.target.value) || 0;
    this.setState({ cookingTime }, () => {
      this.calculateTotalTime();
    });
  };

  calculateTotalTime() {
    const { preparationTime, cookingTime } = this.state;
    const total = preparationTime + cookingTime;
    this.setState({ totalTime: total });
  }

  modalToggle = () => {
    this.props.setShowItemModal(!this.props.showItemModal); // Toggle the showItemModal prop
    this.setState({ modal: !this.state.modal });
  };

  handleUserClick = arg => {
    const item = arg;

    // this.setState({
    //   item: {
    //     itemId: item.id,
    //     itemName: item.itemName,
    //     onePEx: parseInt(item.onePEx, 10),
    //     onePCalories: parseInt(item.onePCalories, 10),
    //     twoPEx: parseInt(item.twoPEx, 10),
    //     twoPCalories: parseInt(item.twoPCalories, 10),
    //     threePEx: parseInt(item.threePEx, 10),
    //     threePCalories: parseInt(item.threePCalories, 10),
    //   },
    //   isEdit: true,
    //   addItem: true
    // });

    this.modalToggle();
  };

  handleDeleteItem = itemToDelete => {
    // Filter out the item to be deleted
    const updatedItems = this.state.items.filter(item => item !== itemToDelete);
    this.setState({ items: updatedItems });
  };

  handleEditItem = item => {
    this.setState({ item: item, editItem: true });
    this.modalToggle();
  };

  handleAddRecipe = async data => {
    try {
      const response = await axiosPost(
        `${process.env.REACT_APP_BASE_URL}/recipes`,
        data
      );
      if (response?.message) {
        this.setState({
          errorMessage: { isSuccess: false, message: response?.message },
        });
      } else {
        this.setState({
          errorMessage: {
            isSuccess: true,
            message: "Recipe created successfully !",
          },
        });
        this.props.history.push(`/recipe-list`);
      }
    } catch (error) {
      console.error("Error while creating recipe:", error);
    }
  };

  handleEditRecipe = async data => {
    try {
      const response = await axiosPatch(
        `${process.env.REACT_APP_BASE_URL}/recipes/${this.props.match.params.recipeId}`,
        data
      );
      if (response?.message) {
        this.setState({
          errorMessage: { isSuccess: false, message: response?.message },
        });
      } else {
        this.setState({
          errorMessage: {
            isSuccess: true,
            message: "Recipe updated successfully !",
          },
        });
        this.props.history.push(`/recipe-list`);
      }
    } catch (error) {
      console.error("Error while creating recipe:", error);
    }
  };

  generateRandomString = length => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  handleFileChange = async event => {
    const formData = new FormData();
    const file = event.target.files[0];

    formData.append("file", file);

    for (const entry of formData.entries()) {
      // console.log(entry[0], entry[1]);
    }

    this.setState({ imageUploaded: file });

    if (this.state.imageValidationError) {
      this.setState({ imageValidationError: "" });
    }

    try {
      const response = await axiosPost(`/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      this.setState({ uploadImageInfo: response });
    } catch (error) {
      console.error(error);
    }
  };

  calculateTotal = array => {
    let totalQuantity = 0;
    let totalCalories = 0;

    array.forEach(item => {
      const quantityValue = parseFloat(item?.quantity);
      const caloriesValue = parseFloat(item?.calories);

      // Check if the extracted values are valid numbers
      if (!isNaN(quantityValue)) {
        totalQuantity += quantityValue;
      }

      if (!isNaN(caloriesValue)) {
        totalCalories += caloriesValue;
      }
    });

    return {
      totalCalories,
      totalQuantity,
    };
  };


  handleTitleChange = (e) => {
    // const formik = useFormikContext();
    const title = e.target.value;
    // setFieldValue("name", title); // Update Formik field value

    // Filter the list of all recipes based on the user input
    if (title) {
      const matching = this.state.recipeList.filter((recipe) =>
        recipe.title.toLowerCase().includes(title.toLowerCase())
      );
      this.setState({ matchingRecipes: matching })
    } else {
      this.setState({ matchingRecipes: [] })
    }
    // setMatchingRecipes(matching);
  };

  formatHtmlToText = (content) => {
    let processedContent = '';

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Process each element within the document body
    Array.from(doc.body.childNodes).forEach((childNode) => {
      if (childNode.nodeName.toLowerCase() === 'p' && childNode.textContent) {
        processedContent += `${childNode.textContent}\n`;
      } else if (childNode.nodeName.toLowerCase() === 'ol') {
        const listItems = childNode.querySelectorAll('li');
        listItems.forEach((item, index) => {
          processedContent += `${index + 1}. ${item.textContent}\n`;
        });
      } else if (childNode.nodeName.toLowerCase() === 'ul') {
        const listItems = childNode.querySelectorAll('li');
        listItems.forEach((item) => {
          processedContent += `• ${item.textContent}\n`;
        });
      }
    });
    return processedContent
  };

  render() {
    //meta title
    document.title = "Recipe | Diet-Delights";

    const { item, items, imageUploaded, uploadImageInfo } = this.state;
    const { ingredients, moreItems, nutriInfo, editItems, setShowItemModal } =
      this.props;

    return (
      <React.Fragment>
        <div className="page-content">
          {this.state.errorMessage ? (
            <Toast
              isSuccess={this.state.errorMessage?.isSuccess}
              message={this.state.successMessage?.message}
            />
          ) : (
            ""
          )}
          <Container fluid={true}>
            <Breadcrumbs
              title="Forms"
              breadcrumbItem={`${this.props.match.params.recipeId
                ? this.state.title
                : "Create Recipe"
                }`}
            />
            <Row>
              {/* <Col lg={6}> */}
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">Create Recipe</CardTitle>
                  <div className="mb-3">
                    <span className="text-danger">
                      {this.state.errorMessage?.message}
                    </span>
                  </div>
                  <Formik
                    enableReinitialize={true}
                    initialValues={{
                      language: this.state && this.state.language,
                      title: (this.state && this.state.title) || "",
                      category: (this.state && this.state.category) || "",
                      subCategory: (this.state && this.state.subCategory) || "",
                      typeOfMeal: (this.state && this.state.typeOfMeal) || "",
                      carbohydrate:
                        (this.state && this.state.carbohydrate) || "",
                      protein: (this.state && this.state.protein) || "",
                      fat: (this.state && this.state.fat) || "",
                      servesPeople: (this.state && this.state.servesPeople) || "",
                      mainIngrediants:
                        (this.state && this.state.mainIngrediants) || "",
                      shoppingList:
                        (this.state && this.state.shoppingList) || "",
                      recipe: (this.state && this.state.recipe) || "",
                      typeOfCuisine:
                        (this.state && this.state.typeOfCuisine) || "",
                      preparationTime:
                        (this.state && this.state.preparationTime) || 10,
                      cookingTime: (this.state && this.state.cookingTime) || 10,
                      totalTime:
                        (this.state &&
                          this.state.preparationTime +
                          this.state.cookingTime) ||
                        0,
                      dietTypes: (this.state && this.state.dietTypes) || [],
                    }}
                    validationSchema={Yup.object().shape({
                      title: Yup.string().required("Please enter the title"),
                      language: Yup.string().required(
                        "Please select a language"
                      ),
                      category: Yup.string().required(
                        "Please select a category"
                      ),
                      subCategory: Yup.string().required(
                        "Please select a sub-category"
                      ),
                      typeOfMeal: Yup.string().required(
                        "Please give the type of meal"
                      ),
                      carbohydrate: Yup.number().required(
                        "Please provide value for carbohydrate"
                      ),
                      protein: Yup.number().required(
                        "Please provide value for protein"
                      ),
                      fat: Yup.number().required(
                        "Please provide value for fat"
                      ),
                      servesPeople: Yup.number().required(
                        "Please provide the number of people this serves."
                      ),
                      mainIngrediants: Yup.string().required(
                        "Please give the main ingredients"
                      ),
                      shoppingList: Yup.string().required(
                        "Please give shopping list"
                      ),
                      recipe: Yup.string().required(
                        "Please provide with recipe method"
                      ),
                      typeOfCuisine: Yup.string().required(
                        "Please Enter the cuisine type"
                      ),
                      preparationTime: Yup.number().required(
                        "Please Enter Your Preparation Time"
                      ),
                      cookingTime: Yup.string().required(
                        "Please Enter Cooking Time"
                      ),
                      totalTime: Yup.string().required(""),
                      dietTypes: Yup.array().min(
                        1,
                        "Select at least one dietTypes"
                      ),
                    })}
                    onSubmit={values => {
                      try {


                        if (
                          ingredients &&
                          ingredients.length !== 0 &&
                          moreItems &&
                          nutriInfo &&
                          imageUploaded
                        ) {
                          const transformedItems = [];

                          // Iterate through the existing items
                          for (const item of items) {
                            // Create an object to represent the item
                            const newItem = {
                              name: item.itemName,
                              portions: [],
                            };

                            // Loop through portions (1 to 3)
                            for (let portion = 1; portion <= 3; portion++) {
                              // Add the portion's exValue and calories to the item
                              newItem.portions.push({
                                portion: portion,
                                // exValue: item[`${portions[portion]}Ex`],
                                calories: item[`${portions[portion]}Calories`],
                              });
                            }

                            transformedItems.push(newItem);
                          }

                          // const dietTypes = [];

                          // // Check each condition and add the corresponding value to the dietTypes array
                          // if (values.isVegetarian) {
                          //   dietTypes.push("Vegetarian");
                          // }

                          // if (values.isVegan) {
                          //   dietTypes.push("Vegan");
                          // }

                          // if (values.isDairyFree) {
                          //   dietTypes.push("Dairy-Free");
                          // }

                          // if (values.isGlutenFree) {
                          //   dietTypes.push("Gluten-Free");
                          // }


                          const data = {
                            language: values.language,
                            title: values["title"],
                            category: values["category"],
                            subCategory: values["subCategory"],
                            typeOfMeal: values["typeOfMeal"],
                            carbohydrate: values["carbohydrate"],
                            protein: values["protein"],
                            fat: values["fat"],
                            servingPeople: values["servesPeople"],
                            mainIngredient: values["mainIngrediants"],
                            shopingList: values["shoppingList"]?.split(",")?.filter((val) => val),
                            recipe: values["recipe"],
                            typeOfCuisine: values["typeOfCuisine"],
                            preparationTime: values["preparationTime"],
                            cookingTime: values["cookingTime"],
                            totalTime: values["totalTime"],
                            oneP: {
                              totalQuantity: this.calculateTotal(
                                moreItems["oneP"]
                              )?.totalQuantity,
                              totalCalories: this.calculateTotal(
                                moreItems["oneP"]
                              )?.totalCalories,
                              nutriInfo: nutriInfo["oneP"],
                              items: moreItems["oneP"],
                            },
                            twoP: {
                              totalQuantity: this.calculateTotal(
                                moreItems["twoP"]
                              )?.totalQuantity,
                              totalCalories: this.calculateTotal(
                                moreItems["twoP"]
                              )?.totalCalories,
                              nutriInfo: nutriInfo["twoP"],
                              items: moreItems["twoP"],
                            },
                            threeP: {
                              totalQuantity: this.calculateTotal(
                                moreItems["threeP"]
                              )?.totalQuantity,
                              totalCalories: this.calculateTotal(
                                moreItems["threeP"]
                              )?.totalCalories,
                              nutriInfo: nutriInfo["threeP"],
                              items: moreItems["threeP"],
                            },
                            ingredients: ingredients,
                            dietTypes: values["dietTypes"],
                            originalname: uploadImageInfo?.originalname,
                            destination: uploadImageInfo?.destination,
                            mimetype: uploadImageInfo?.mimetype,
                            filename: uploadImageInfo?.filename,
                          };

                          if (this.props.match.params.recipeId) {
                            this.handleEditRecipe(data);
                          } else {
                            this.handleAddRecipe(data);
                          }
                        } else {
                          if (!ingredients || ingredients.length === 0) {
                            this.setState({
                              ingredientsValidationError:
                                "Please provide ingredients for the recipe.",
                            });
                          } else {
                            this.setState({ ingredientsValidationError: "" });
                          }
                          if (!moreItems || !nutriInfo) {
                            this.setState({
                              moreItemsValidationError:
                                "Please provide portion and nutrition information",
                            });
                          } else {
                            this.setState({ moreItemsValidationError: "" });
                          }
                          if (!imageUploaded) {
                            this.setState({
                              imageValidationError: "Please upload an image",
                            });
                          } else {
                            this.setState({ imageValidationError: "" });
                          }
                        }
                      } catch (error) {
                        console.error(error)
                      }
                    }}
                  >
                    {({ errors, status, touched, values, setFieldValue }) => (
                      <Form className="needs-validation">
                        <div className="mb-3 d-flex align-items-center">
                          <Label className="form-label">
                            Preferred Language :
                          </Label>
                          <Select
                            name="language"
                            className="ms-4 ml-2rem w-25"
                            value={this.state.languageOptions.reduce(
                              (acc, val) => {
                                return val?.value === values.language
                                  ? val
                                  : acc;
                              },
                              { ...defaultSelect }
                            )}
                            onChange={selectedOption =>
                              setFieldValue("language", selectedOption.value)
                            }
                            options={this.state.languageOptions}
                          />
                          <div className="text-sm-end ms-3">
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary py-1 px-2 d-flex align-items-center"
                              onClick={() =>
                                this.props.history.push("/settings")
                              }
                            >
                              <i className="mdi mdi mdi-plus fs-5" />
                              <span>Add Language</span>
                            </Button>
                          </div>
                        </div>
                        <div className="mb-3 d-flex ">
                          <Label className="form-label mt-2 me-5">Name :</Label>
                          <div>
                            <Field
                              name="title"
                              type="text"
                              placeholder="Enter the recipe title"
                              onChange={(e) => { setFieldValue("title", e.target.value), this.handleTitleChange(e) }}
                              onBlur={() => this.setState({ matchingRecipes: [] })}
                              value={values["title"]}
                              className={
                                "form-control ml-4-4rem width-50rem" +
                                (errors.title && touched.title
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {this.state.matchingRecipes.length > 0 && (
                              <div className="ml-4-4rem width-50rem matching-recipe">
                                <p className="d-flex flex-column ms-3 mt-3">
                                  {this.state.matchingRecipes.map((recipe, index) => (
                                    <Fragment key={recipe.title}>
                                      <span key={index} className="fw-bold m-1">{recipe.title}</span>
                                      <hr className="m-1" />
                                    </Fragment>
                                  ))}
                                </p>
                              </div>
                            )}
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="invalid-feedback ms-5 ml-4-4rem"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Category :
                          </Label>
                          <div>
                            {/* <i
                              className="mdi mdi-chevron-down font-size-18"
                              id="deletetooltip"
                              onClick={() => this.onClickDelete(user)}
                            ></i> */}
                            <Field
                              as="select"
                              name="category"
                              className={
                                "form-control ms-5" +
                                (errors.category && touched.category
                                  ? " is-invalid "
                                  : "")
                              }
                            >
                              <option
                                key="select a category"
                                value="select a category"
                                label="Select a category"
                              />
                              {Object.entries(categoryShow).map(
                                ([key, value]) => (
                                  <option key={key} value={key} label={value} />
                                )
                              )}
                            </Field>
                            <ErrorMessage
                              name="category"
                              component="div"
                              className="invalid-feedback ms-5"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Sub Category :
                          </Label>
                          <div>
                            <Field
                              name="subCategory"
                              type="text"
                              autoComplete="off"
                              placeholder="Enter the sub-category ..."
                              className={
                                "form-control ms-3 ml-1rem width-50rem" +
                                (errors.subCategory && touched.subCategory
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="subCategory"
                              component="div"
                              className="invalid-feedback ms-3 ml-1rem"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Type Of Meal :
                          </Label>
                          <div>
                            <Field
                              name="typeOfMeal"
                              type="text"
                              placeholder="ex: balanced ..."
                              className={
                                "form-control ms-4 width-50rem" +
                                (errors.typeOfMeal && touched.typeOfMeal
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="typeOfMeal"
                              component="div"
                              className="invalid-feedback ms-4"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Main Ingrediants :
                          </Label>
                          <div>
                            <Field
                              name="mainIngrediants"
                              type="text"
                              placeholder="Enter main ingredients for the recipe"
                              className={
                                "form-control width-50rem" +
                                (errors.mainIngrediants &&
                                  touched.mainIngrediants
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="mainIngrediants"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Shopping List :
                          </Label>
                          <div>
                            <Field
                              name="shoppingList"
                              label="shoppingList"
                              placeholder="Add shopping list for the recipe"
                              as="textarea"
                              rows="3"
                              className={
                                "form-control ms-4 width-50rem" +
                                (errors.shoppingList && touched.shoppingList
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="shoppingList"
                              component="div"
                              className="invalid-feedback ms-4"
                            />
                            <p className="ms-4">
                              <Label className="text-danger">Note :</Label>
                              <span>
                                To add another item separate them with comma .
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 ">
                            Cooking Direction :
                          </Label>
                          <div>
                            {/* <Field
                            >
                              {({ field }) => ( */}
                            <ReactQuill
                              // {...field}
                              // name="recipe"
                              theme="snow"
                              className={
                                "form-control ml-2-6rem width-50rem" +
                                (errors.recipe && touched.recipe ? " is-invalid" : "")
                              }
                              value={values.recipe}
                              onChange={(content) => {
                                // Manually update Formik's state for the ReactQuill field
                                setFieldValue('recipe', content);
                              }}
                              modules={{
                                toolbar: [
                                  [{ list: 'ordered' }, { list: 'bullet' }],
                                ],
                              }}
                            />
                            {/* )}
                            </Field> */}
                            {errors.recipe && touched.recipe ? <p
                              name="recipe"
                              // component="div"
                              className="invalid-feedback ml-2-6rem"
                            >Please provide with recipe method</p> : ""}
                          </div>

                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Ingredients :
                          </Label>
                          <div className="table-rep-plugin ml-2rem">
                            <div
                              className="table-responsive mb-0 width-50rem"
                              data-pattern="priority-columns"
                              style={{
                                backgroundColor: "#dae3ef",
                                padding: "1rem",
                              }}
                            >
                              <Ingredients />
                              <span className="text-danger">
                                {this.state.ingredientsValidationError
                                  ? this.state.ingredientsValidationError
                                  : ""}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Type Of Cuisine :
                          </Label>
                          <div>
                            <Field
                              name="typeOfCuisine"
                              type="text"
                              placeholder="ex : indian , asian ..."
                              className={
                                "form-control ms-2 width-50rem" +
                                (errors.typeOfCuisine && touched.typeOfCuisine
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="typeOfCuisine"
                              component="div"
                              className="invalid-feedback ms-2"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Carbohydrate :
                          </Label>
                          <div>
                            <Field
                              name="carbohydrate"
                              type="number"
                              placeholder="ex : 50 g"
                              className={
                                "form-control ms-3 width-50rem" +
                                (errors.carbohydrate && touched.carbohydrate
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="carbohydrate"
                              component="div"
                              className="invalid-feedback ms-3"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Protein :
                          </Label>
                          <div>
                            <Field
                              name="protein"
                              type="number"
                              placeholder="ex : 40 g"
                              className={
                                "form-control width-50rem ml-4rem " +
                                (errors.protein && touched.protein
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name="protein"
                              component="div"
                              className="invalid-feedback ml-4rem "
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">Fat :</Label>
                          <div>
                            <Field
                              name="fat"
                              type="number"
                              placeholder="ex : 50 g"
                              className={
                                "form-control width-50rem ml-5-5rem" +
                                (errors.fat && touched.fat ? " is-invalid" : "")
                              }
                            />
                            <ErrorMessage
                              name="fat"
                              component="div"
                              className="invalid-feedback ml-5-5rem"
                            />
                          </div>
                        </div>

                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">
                            Upload Image :
                          </Label>
                          <div className="d-flex flex-column">
                            <input
                              type="file"
                              className="ms-3 btn btn-primary"
                              onChange={event => {
                                this.handleFileChange(event);
                              }}
                            ></input>
                            <span className="text-danger ms-3 mt-1">
                              {this.state.imageValidationError
                                ? this.state.imageValidationError
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex flex-column">
                          <div className="mb-3 d-flex me-5">
                            <Label className="form-label preparation-time-label mt-2 me-4">
                              Preparation Time :
                            </Label>
                            <Field
                              name="preparationTime"
                              type="number"
                              // value={this.state.preparationTime}
                              // onChange={this.handlePreparationTimeChange}
                              className={
                                "form-control recipe-time ml-0rem" +
                                (errors.preparationTime &&
                                  touched.preparationTime
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <Label className="mt-2 ms-1">min</Label>
                            <ErrorMessage
                              name="preparationTime"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 d-flex me-5">
                            <Label className="form-label time-label cooking-time-label mt-2 me-5">
                              Cooking Time :
                            </Label>
                            <Field
                              name="cookingTime"
                              type="number"
                              // value={this.state.cookingTime}
                              // onChange={this.handleCookingTimeChange}
                              className={
                                "form-control recipe-time ms-1" +
                                (errors.cookingTime && touched.cookingTime
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <Label className="mt-2 ms-1">min</Label>
                            <ErrorMessage
                              name="cookingTime"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="mb-3 d-flex">
                            <Label className="form-label time-label total-time-label mt-2 me-5">
                              Total Time :
                            </Label>
                            <Field
                              name="totalTime"
                              type="number"
                              value={
                                values["preparationTime"] +
                                values["cookingTime"]
                              }
                              disabled={true}
                              className={
                                "form-control recipe-time ms-5 ml-2rem" +
                                (errors.totalTime && touched.totalTime
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <Label className="mt-2 ms-1">min</Label>
                            <ErrorMessage
                              name="totalTime"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex">
                          <Label className="form-label mt-2 me-5">Serves People :</Label>
                          <div>
                            <Field
                              name="servesPeople"
                              type="number"
                              placeholder="ex : 50 g"
                              className={
                                "form-control recipe-time ms-3" +
                                (errors.servesPeople && touched.servesPeople ? " is-invalid" : "")
                              }
                            />
                            <ErrorMessage
                              name="servesPeople"
                              component="div"
                              className="invalid-feedback ms-3"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex align-items-center w-100">
                          <Label className="form-label me-5 pe-3">
                            Diet Type :
                          </Label>

                          <Multiselect
                            className="ms-5 ml-2rem w-75"
                            options={this.state.categoryOptions}
                            selectedValues={this.state.selectedCategoryOptions}
                            onSelect={(selectedList, selectedItem) => {
                              const dietTypes = selectedList?.map(
                                selectedItem => selectedItem?.value
                              );
                              setFieldValue("dietTypes", dietTypes);
                              this.setState({
                                selectedCategoryOptions: selectedList,
                              });
                            }}
                            onRemove={(selectedList, removedItem) => {
                              const dietTypes = selectedList?.map(
                                selectedItem => selectedItem?.value
                              );
                              setFieldValue("dietTypes", dietTypes);
                              this.setState({
                                selectedCategoryOptions: selectedList,
                              });
                            }}
                            displayValue="label"
                            showCheckbox={true}
                            showArrow={true}
                          />
                          <div className="text-sm-end ms-3">
                            <Button
                              color="primary"
                              className="font-16 btn-block btn btn-primary py-1 px-2 d-flex align-items-center"
                              onClick={() =>
                                this.props.history.push("/settings")
                              }
                            >
                              <i className="mdi mdi mdi-plus fs-5" />
                              <span>Add Diet Type</span>
                            </Button>
                          </div>
                        </div>
                        {Object.keys(moreItems).length > 0 &&
                          Object.keys(nutriInfo).length > 0 ? (
                          <div className="mb-3 d-flex">
                            <div
                              color="primary"
                              className="border border-primary add-item-btn d-flex justify-content-center align-items-center"
                              onClick={() => {
                                this.props.editItems(true), this.modalToggle();
                              }}
                            >
                              <i
                                className="mdi mdi-pencil font-size-18 me-2"
                                id="addItem"
                              ></i>
                              Edit Item
                            </div>{" "}
                          </div>
                        ) : (
                          <div className="mb-3 d-flex">
                            <div
                              color="primary"
                              className="border border-primary add-item-btn d-flex justify-content-center align-items-center"
                              onClick={this.handleUserClick}
                            >
                              <i
                                className="mdi mdi-plus font-size-18 me-2"
                                id="addItem"
                              ></i>
                              More Action
                            </div>{" "}
                          </div>
                        )}
                        <span className="text-danger">
                          {this.state.moreItemsValidationError
                            ? this.state.moreItemsValidationError
                            : ""}
                        </span>

                        <ItemTable
                          items={this.state.items}
                          onDeleteItem={this.handleDeleteItem}
                          onEditItem={this.handleEditItem}
                        />
                        {Object.keys(moreItems).length > 0 &&
                          Object.keys(nutriInfo).length > 0 ? (
                          <div
                            style={{
                              backgroundColor: "#dae3ef",
                              padding: "1rem",
                            }}
                          >
                            <ItemsNutriInfo />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="more-action-model-div">
                          <Modal
                            isOpen={this.props.showItemModal}
                            className="more-action-model"
                          >
                            <ModalHeader toggle={this.modalToggle} tag="h4">
                              <h3>{editItems ? "Edit Item" : "Add Item"}</h3>
                            </ModalHeader>
                            <ModalBody>
                              <Items />
                            </ModalBody>
                          </Modal>
                        </div>
                        <div className="d-flex flex-wrap gap-2 mt-2">
                          <Button type="submit" color="primary">
                            Save
                          </Button>{" "}
                          <Button
                            type="reset"
                            color="secondary"
                            onClick={() =>
                              this.props.history.push("/recipe-list")
                            }
                          >
                            Cancel
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
              {/* </Col> */}
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  ingredients: state.Recipe.ingredients, // Assuming your Redux state has a 'rows' property
  showItemModal: state.Recipe.showItemModal,
  moreItems: state.Recipe.items,
  nutriInfo: state.Recipe.nutriInfo,
  editItems: state.Recipe.editItems,
});

const mapDispatchToProps = {
  setIngredients, // This connects the setIngredients action to your component
  setShowItemModal,
  setNutriInfo,
  setItems,
  editItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(FormValidations);
