import React, { Component } from "react";
import CarouselPage from "./CarouselPage";

// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

export default class ConfirmMail2 extends Component {
  render() {
    //meta title
    document.title =
      "Confirm Mail 2 | Skote - React Admin & Dashboard Template";
    return (
      <React.Fragment>
        <div>
          <Container fluid className="p-0">
            <Row className="row g-0">
              <CarouselPage />

              <Col xl={3}>
                <div className="auth-full-page-content p-md-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5">
                        <Link to="/" className="d-block auth-logo">
                          <img
                            src={logodark}
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="18"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>
                      <div className="my-auto">
                        <div className="text-center">
                          <div className="avatar-md mx-auto">
                            <div className="avatar-title rounded-circle bg-light">
                              <i className="bx bx-mail-send h1 mb-0 text-primary"></i>
                            </div>
                          </div>
                          <div className="p-2 mt-4">
                            <h4>Success !</h4>
                            <p className="text-muted">
                              At vero eos et accusamus et iusto odio dignissimos
                              ducimus qui blanditiis praesentium voluptatum
                              deleniti atque corrupti quos dolores et
                            </p>
                            <div className="mt-4">
                              <Link to="dashboard" className="btn btn-success">
                                Back to Home
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mt-4 mt-md-5 text-center">
                        <p className="mb-0">
                          © {new Date().getFullYear()} Skote. Crafted with{" "}
                          <i className="mdi mdi-heart text-danger"></i> by
                          Themesbrand
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
