import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
//Import Breadcrumb
import { portions, units } from "constants/diet";
import { axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import Toast from "components/Common/Toast";
import Multiselect from "multiselect-react-dropdown";
import { LIMIT } from "../../constants/pagination";

class ResponsiveTables extends Component {
  constructor(props) {
    super(props);
    this.multiselectRef = React.createRef();
    this.state = {
      editDataUserId: "",
      totalNutritionlValue: "",
      openDropdown: {
        // BREAKFAST: false,
        // LUNCH: false,
        // DINNER: false,
        // SNACKS: false,
        // SIDE_DISH: false,
      },
      selectedRecipes: {
        // BREAKFAST: [],
        // LUNCH: [],
        // DINNER: [],
        // SNACKS: [],
        // SIDE_DISH: [],
      },
      selectedValues: {
        // BREAKFAST: "",
        // LUNCH: "",
        // DINNER: "",
        // SNACKS: "",
        // SIDE_DISH: "",
      }, // Keep track of selected values for each unit
      selectedPortion: {
        // BREAKFAST: "",
        // LUNCH: "",
        // DINNER: "",
        // SNACKS: "",
        // SIDE_DISH: "",
      },
      selectedRecipeByEvent: {
        // BREAKFAST: [],
        // LUNCH: [],
        // DINNER: [],
        // SNACKS: [],
        // SIDE_DISH: [],
      },
      showRecipes: {
        // BREAKFAST: false,
        // LUNCH: false,
        // DINNER: false,
        // SNACKS: false,
        // SIDE_DISH: false,
      },
      mealTypeKeyArray: [],
      mealTypeObj: {},
      finalDiet: [],
      dietTitle: this.props.titleFromCategories || "",
      errorMessage: "",
      selectedValuesHistory: [], // New state variable to store selected options as an array
      toEditDietData: [], // saves the data of diet which is requested to edit
      responseMessage: { isSuccess: false, message: "" },
      totalCalories: 0,
      totalQuantity: 0,
      selectedRecipesInSelect: {},
      removedRecipesInSelect: {}
    };

    this.getAllMealTypes = this.getAllMealTypes.bind(this);
  }

  componentDidMount() {
    this.getAllMealTypes();
  }

  componentDidUpdate(prevProps) {
    // this.calculateCaloriesTotal()
    // if (this.state.selectedRecipes) {
    //   this.calculateCaloriesTotal()
    // }
    if (this.props.titleFromCategories !== prevProps.titleFromCategories) {
      this.setState({ dietTitle: this.props.titleFromCategories });
    }
    if (this.props.isFilterChanged && this.props.selectedDietTypeOptions.length
      > 1) {
      this.state.mealTypeKeyArray.forEach(async (key) => {
        await this.callAPI(key, this.state.selectedDietTypeOptions, this.state.selectedPortion[key])
      })
      this.props.updateState(false);
    }
    if (this.props.deletedDietType && this.props.isDietTypeDeleted) {

      const { selectedRecipes } = this.state;
      const { selectedDietTypeOptions, deletedDietType } = this.props;

      const filteredRecipes = {};

      for (const category in selectedRecipes) {
        if (selectedRecipes.hasOwnProperty(category)) {
          const categoryRecipes = selectedRecipes[category];

          const filteredCategoryRecipes = categoryRecipes.filter(recipe => {
            const dietTypes = recipe?.dietTypes || [];

            // Check if any diet type of the recipe matches any of the selected diet types
            const matchedDietType = dietTypes.some(
              type => selectedDietTypeOptions.some(opt => opt.value === type)
            );

            // Exclude recipes that don't have any selected diet type
            const hasSelectedType = dietTypes.some(
              type => selectedDietTypeOptions.some(opt => opt.value === type)
            );

            return matchedDietType || hasSelectedType;
          });

          filteredRecipes[category] = filteredCategoryRecipes;
        }
      }

      this.state.mealTypeKeyArray.forEach(async (key) => {
        await this.callAPI(key, this.state.selectedDietTypeOptions, this.state.selectedPortion[key])
      })

      this.setState({ selectedRecipes: filteredRecipes, })
      this.props.updateRemovedDeletedDietType()
      this.props.updateIsDietTypeDeleted(false)
    }
  }

  getAllMealTypes = async () => {
    try {
      const response = await axiosGet(`/meal-types?limit=${LIMIT}&page=1&sortBy=index`);

      if (response?.message) {
        console.error("Response Error in getAllMealTypes: ", response?.message);
      } else {
        const mealTypesArray = response?.results;

        const keyArray = mealTypesArray?.map(mealType => mealType?.key);

        this.setState({
          mealTypeKeyArray: keyArray,
          openDropdown: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {}
          ),
          selectedRecipes: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: [] }),
            {}
          ),
          selectedValues: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: "" }),
            {}
          ),
          selectedPortion: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: "" }),
            {}
          ),
          selectedRecipeByEvent: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: [] }),
            {}
          ),
          showRecipes: keyArray.reduce(
            (acc, key) => ({ ...acc, [key]: false }),
            {}
          ),
          mealTypeObj: mealTypesArray?.reduce(
            (acc, mealType) => ({ ...acc, [mealType?.key]: mealType }),
            {}
          ),
        });

        const dietId = this.props.dietId;
        if (dietId) {
          this.getDietById(dietId);
        }
        if (this.props.titleFromCategories) {
          this.setState({ dietTitle: this.props.titleFromCategories });
        }
      }
    } catch (error) {
      console.error("error in getAllMealTypes: ", error);
    }
  };

  handleCheckboxChange = (key, recipe) => event => {
    const isChecked = event.target.checked;
    this.setState(
      prevState => {
        const selectedRecipes = { ...prevState.selectedRecipes };

        if (isChecked) {
          // If the checkbox is checked, add the recipe to the selectedRecipes
          selectedRecipes[key] = [...selectedRecipes[key], recipe];
        } else {
          // If the checkbox is unchecked, remove the recipe from the selectedRecipes
          selectedRecipes[key] = selectedRecipes[key].filter(
            selectedRecipe => selectedRecipe !== recipe
          );
        }
        return { selectedRecipes };
      },
      () => {
        // This callback will be executed after the state is updated
        this.calculateCaloriesTotal();
      }
    );
  };

  // Function to handle the change of the selected option
  handleValueChange(key, selectedOption) {
    this.setState(
      prevState => ({
        selectedValues: {
          ...prevState.selectedValues,
          [key]: selectedOption,
        },
        selectedValuesHistory: [
          ...prevState.selectedValuesHistory,
          { key, selectedOption },
        ],
      }),
      () => {
        if (this.props.dietId) {
          this.callAPI(key, selectedOption, this.state.selectedPortion[key]);
        }
      }
    );
  }


  handlePortionChange(key, selectedOption) {
    this.setState(
      prevState => {
        // Create a copy of the previous state
        const updatedState = { ...prevState };

        // Update the selectedPortion for the specified key
        updatedState.selectedPortion[key] = selectedOption;

        // Empty the selectedRecipes for the specified key
        updatedState.selectedRecipes[key] = [];

        return updatedState;
      },
      () => {
        // This callback will be executed after the state is updated
        this.callAPI(key, this.state.selectedValues[key], selectedOption);
      }
    );
  }

  sumSelectedValues() {
    // Calculate the sum of all selected values, handling strings and numbers
    return Object.values(this.state.selectedValues).reduce(
      (acc, selectedValue) => {
        const parsedValue = parseFloat(selectedValue);
        if (!isNaN(parsedValue)) {
          return acc + parsedValue;
        }
        return acc;
      },
      0
    );
  }

  callAPI = async (key, selectedOption, selectedPortion, dietTypes = []) => {
    const { selectedLanguage, selectedDietTypeOptions } = this.props;

    // Create an object to hold the query parameters
    const queryParams = {};
    // Add selected dietTypes to query parameters if true
    if (selectedDietTypeOptions?.length !== 0) {
      queryParams["dietTypes"] = selectedDietTypeOptions?.map(
        dietType => dietType?.value
      );
    } else {
      queryParams["dietTypes"] = dietTypes?.map(
        dietType => dietType?.value
      );
    }

    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }

    // Add the selected value based on the key
    queryParams["category"] = key;

    if (portions[selectedPortion]) {
      queryParams["portionKey"] = portions[selectedPortion];
    }

    // queryParams.exValue = selectedOption;
    if (this.props.selectedLanguage !== "all") {
      queryParams.language = selectedLanguage;
    }



    // Construct the query string
    const queryString = Object.keys(queryParams)
      .map(param => `${param}=${queryParams[param]}`)
      .join("&");

    // Construct the final URL
    const apiUrlWithFilters = `${process.env.REACT_APP_BASE_URL}/recipes?${queryString}`;

    // Construct the final URL
    try {
      const recipes = await axiosGet(apiUrlWithFilters);

      // Update the state based on the event (key)
      if (!recipes.message) {
        this.setState(prevState => ({
          selectedRecipeByEvent: {
            ...prevState.selectedRecipeByEvent,
            [key]: recipes?.recipes || [],
          },
        }));
      }
    } catch (error) {
      console.error("API request error callAPI:", error);
    }
  };

  getDietById = async id => {
    try {
      const response = await axiosGet(
        `${process.env.REACT_APP_BASE_URL}/userdiets/${id}`
      );
      if (!response?.message) {
        const selectedRecipeByEvent = { ...this.state.selectedRecipeByEvent };
        const selectedRecipes = { ...this.state.selectedRecipes };
        const categoryData = response.dayTimes.reduce((result, dayTime) => {
          result[dayTime.category] =
            dayTime.totalCategoryCalories || dayTime.totalCategoryQuantity;
          return result;
        }, {});
        const portionData = response.dayTimes.reduce((result, dayTime) => {
          if (dayTime?.recipes[0]?.portionKey === portions[1]) {
            result[dayTime.category] = 1;
          } else if (dayTime?.recipes[0]?.portionKey === portions[2]) {
            result[dayTime.category] = 2;
          } else if (dayTime?.recipes[0]?.portionKey === portions[3]) {
            result[dayTime.category] = 3;
          }
          return result;
        }, {});

        response.dayTimes.forEach(dayTime => {
          const category = dayTime.category;
          let totalCategoryCalories, totalCategoryQuantity;

          if (dayTime.totalCategoryCalories) {
            totalCategoryCalories = dayTime.totalCategoryCalories;
          } else {
            totalCategoryQuantity = dayTime.totalCategoryQuantity;
          }

          const recipes = dayTime.recipes.map(recipe => {
            // Include totalCategoryCalories or totalCategoryQuantity in each recipe object
            const recipeInfo = {
              title: recipe.title,
              recipeId: recipe.recipeId.id,
              dietTypes: recipe?.recipeId?.dietTypes,
              totalCategoryCalories, // Include totalCategoryCalories if available
              totalCategoryQuantity, // Include totalCategoryQuantity if available
            };
            if (portionData[category] === 1) {
              recipeInfo.oneP = recipe?.recipeId?.oneP;
            } else if (portionData[category] === 2) {
              recipeInfo.twoP = recipe?.recipeId?.twoP;
            } else if (portionData[category] === 3) {
              recipeInfo.threeP = recipe?.recipeId?.threeP;
            }
            return recipeInfo;
          });

          // selectedRecipeByEvent[category] = recipes;
          selectedRecipes[category] = recipes;
        });

        // const showRecipes = {
        //   BREAKFAST: true,
        //   LUNCH: true,
        //   DINNER: true,
        //   SNACKS: true,
        //   SIDE_DISH: true,
        // };
        const showRecipes = this.state.mealTypeKeyArray.reduce(
          (acc, key) => ({ ...acc, [key]: true }),
          {}
        );
        this.props.updateOptions(response?.dietTypes)

        this.setState({
          toEditDietData: response,
          selectedRecipeByEvent,
          selectedRecipes: selectedRecipes,
          totalCalories: response?.totalCalories,
          totalQuantity: response?.totalQuantity,
          dietTitle: response?.title,
          selectedValues: categoryData,
          editDataUserId: response?.userId,
          // totalNutritionlValue: response?.totalCalories || response?.totalExValue,
          selectedPortion: portionData,
          showRecipes: showRecipes,
        });
        this.state.mealTypeKeyArray.forEach(async (key, index) => {
          if (categoryData[key] && portionData[key]) {
            const selectedRecipeByEvent = await this.callAPI(
              key,
              categoryData[key],
              portionData[key],
              response?.dietTypes
            );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  handleDeleteRecipe = (key, index) => {
    this.setState(
      prevState => {
        const updatedRecipes = prevState.selectedRecipes[key].filter(
          (recipe, i) => i !== index
        );

        return {
          selectedRecipes: {
            ...prevState.selectedRecipes,
            [key]: updatedRecipes,
          },
        };
      },
      () => {
        this.calculateCaloriesTotal();
        this.multiselectRef.current.resetSelectedValues();
      }
    );
  };

  getErrorMessage() {
    let errorMessage = "";

    if (!this.state.dietTitle) {
      errorMessage += "Title is missing. ";
    }

    const isEmpty = Object.keys(this.state.selectedRecipeByEvent).every(
      key => this.state.selectedRecipeByEvent[key].length === 0
    );

    if (isEmpty) {
      errorMessage += "Data is empty.";
    }

    return errorMessage || null;
  }

  handleSaveDiet = async () => {
    const { selectedDietTypeOptions } = this.props;

    const errorMessage = this.getErrorMessage();
    if (errorMessage) {
      this.setState({ errorMessage: errorMessage });
    } else {
      // const total = this.sumSelectedValues()

      const userId = this.props.userId;
      const dayTimes = Object.keys(this.state.selectedRecipes).map(category => {
        const selectedValues = this.state.selectedValues[category];
        const selectedRecipe = this.state.selectedRecipes[category];

        const totalObj = this.calculateCaloriesTotal(category);
        if (selectedRecipe.length > 0) {
          return {
            category,
            categoryName: this.state.mealTypeObj[category]?.name,

            totalCategoryCalories: totalObj.totalCalories,
            totalCategoryQuantity: totalObj.totalQuantity,
            recipes: selectedRecipe.map(recipe => ({
              recipeId: recipe._id || recipe?.recipeId,
              title: recipe.title,
              portionKey: portions[this.state.selectedPortion[category]],
            })),
          };
        }

        return null; // Return null for categories with empty recipes
      });

      const filteredDayTimes = dayTimes.filter(dayTime => dayTime !== null);



      const totalObj = this.calculateCaloriesTotal();

      let dietTypes = selectedDietTypeOptions || [];

      const mealPlan = {
        title: this.state.dietTitle,
        ...(this.props.dietId ? "" : { userId: userId }),
        totalCalories: totalObj.totalCalories,
        totalQuantity: totalObj.totalQuantity,
        dietTypes,
        dayTimes: filteredDayTimes,
      };

      if (this.props.dietId) {
        const response = await axiosPatch(
          `${process.env.REACT_APP_BASE_URL}/userdiets/${this.props.dietId}`,
          mealPlan
        );
        if (!response?.message) {
          this.setState({
            responseMessage: {
              isSuccess: true,
              message: "Diet Updated Successfully !",
            },
          });
          this.props.redirectToCustomerDiet();
        } else {
          this.setState({
            responseMessage: { isSuccess: false, message: response?.message },
          });
        }
      } else {
        const response = await axiosPost(
          `${process.env.REACT_APP_BASE_URL}/userdiets`,
          mealPlan
        );
        if (!response?.message) {
          this.setState({
            selectedValues: {}, // Keep track of selected values for each unit
            // selectedRecipeByEvent: {
            //   BREAKFAST: [],
            //   LUNCH: [],
            //   DINNER: [],
            //   SNACKS: [],
            //   SIDE_DISH: [],
            // },
            // selectedRecipes: {
            //   BREAKFAST: [],
            //   LUNCH: [],
            //   DINNER: [],
            //   SNACKS: [],
            //   SIDE_DISH: [],
            // },
            selectedRecipeByEvent: this.state.mealTypeKeyArray.reduce(
              (acc, key) => ({ ...acc, [key]: [] }),
              {}
            ),
            selectedRecipes: this.state.mealTypeKeyArray.reduce(
              (acc, key) => ({ ...acc, [key]: [] }),
              {}
            ),
            finalDiet: [],
            dietTitle: "",
            errorMessage: "",
          });
          this.setState({
            responseMessage: {
              isSuccess: true,
              message: "Diet created Successfully !",
            },
          });
          this.props.redirectToCustomerDiet();
        } else {
          this.setState({
            responseMessage: { isSuccess: false, message: response?.message },
          });
        }
      }
    }
  };

  calculateCaloriesTotal(category) {
    let totalCalories = 0;
    let totalQuantity = 0;

    Object.keys(this.state.selectedRecipes).forEach(cat => {
      if (!category || cat === category) {
        // Check if the category matches the provided category or if no category is provided
        const recipesInCategory = this.state.selectedRecipes[cat];
        ``;
        recipesInCategory.forEach(recipe => {
          if (recipe.oneP) {
            totalCalories += recipe.oneP.totalCalories;
            totalQuantity += recipe.oneP.totalQuantity;
          }
          if (recipe.twoP) {
            totalCalories += recipe.twoP.totalCalories;
            totalQuantity += recipe.twoP.totalQuantity;
          }
          if (recipe.threeP) {
            totalCalories += recipe.threeP.totalCalories;
            totalQuantity += recipe.threeP.totalQuantity;
          }
        });
      }
    });
    this.setState({
      totalCalories: totalCalories,
      totalQuantity: totalQuantity,
    });
    return { totalCalories, totalQuantity };
  }


  handleTitleChange = event => {
    if (this.state.errorMessage) {
      this.setState({ errorMessage: "" });
    }

    if (this.props.dietId) {
      // If dietId is truthy, copy the existing toEditDietData and update only the title property.
      const updatedToEditDietData = { ...this.state.toEditDietData };
      updatedToEditDietData.title = event.target.value;
      this.setState({
        toEditDietData: updatedToEditDietData,
        dietTitle: event.target.value,
      });
    } else {
      // If dietId is falsy, update the dietTitle as before.
      this.setState({ dietTitle: event.target.value });
    }
  };

  toggleDropdown = key => {
    this.setState(prevState => ({
      openDropdown: {
        ...prevState.openDropdown,
        [key]: !prevState.openDropdown[key],
      },
    }));
  };

  handleShowRecipe(key) {
    this.setState(prevState => {
      // Clone the showRecipes object to make changes without modifying the original state
      const selectedRecipes = { ...prevState.selectedRecipes };
      selectedRecipes[key] = this.state.selectedRecipesInSelect[key]

      // Set the value for the specified key to true
      // updatedShowRecipes[key] = true;

      return { selectedRecipes: selectedRecipes };
    });
  }

  onSelect(key, selectedList, selectedItem) {
    // const updatedSelectedRecipes = { ...this.state.selectedRecipes };
    this.setState(prevState => {
      const updatedSelectedRecipesInSelect = { ...prevState.selectedRecipesInSelect }
      updatedSelectedRecipesInSelect[key] = selectedList
      return { selectedRecipesInSelect: updatedSelectedRecipesInSelect }
    }, () => {
      this.calculateCaloriesTotal()
    })
  }

  onRemove(key, selectedList, removedItem) {

    this.setState(prevState => {
      const updatedSelectedRecipesInSelect = { ...prevState.selectedRecipesInSelect }
      updatedSelectedRecipesInSelect[key] = selectedList
      return { selectedRecipesInSelect: updatedSelectedRecipesInSelect }
    }, () => {
      this.calculateCaloriesTotal()
    })
  }

  getFilteredOptions(key) {
    // Create an array of recipe IDs from this.state.selectedRecipes[key]
    const selectedRecipeIds = this.state.selectedRecipes[key].map(
      recipe => recipe.recipeId || recipe._id
    );

    // if (this.props.dietId) {
    // Filter the options to exclude recipes whose IDs are in the selectedRecipeIds array
    return this.state.selectedRecipeByEvent[key].filter(
      option => !selectedRecipeIds.includes(option?._id || option?.recipeId)
    );
    // } else {
    //   // If this.props.dietId is not defined, show all available options
    //   return this.state.selectedRecipeByEvent[key];
    // }
  }

  render() {
    document.title = "Diet Table | Diet-Delights";
    const {
      selectedValues,
      selectedRecipeByEvent,
      selectedRecipes,
      mealTypeKeyArray,
    } = this.state;
    const {
      selectedUnit,
      selectedLanguage,
      userId,
      isFilterChanged,
      titleFromCategories,
    } = this.props;
    return (
      <React.Fragment>
        {this.state.responseMessage?.message ? (
          <Toast
            isSuccess={this.state.responseMessage?.isSuccess}
            message={this.state.responseMessage?.message}
          />
        ) : (
          ""
        )}
        <div className="mb-3 d-flex align-items center justify-content-between">
          <div className="d-flex">
            <label htmlFor="example-text-input" className="me-2 mt-2">
              Title
            </label>
            <input
              className="form-control h-100"
              type="text"
              placeholder="Enter the diet title..."
              value={
                this.props.dietId && !this.props.titleFromCategories
                  ? this.state.toEditDietData?.title
                  : this.state.dietTitle
              }
              onChange={event => this.handleTitleChange(event)}
            />
          </div>
        </div>
        <div className="">
          <div className="container-fluid">
            <Row>
              <Col>
                <div className="table-rep-plugin">
                  <div
                    className="table-responsive mb-0"
                    data-pattern="priority-columns"
                  >
                    <Table
                      id="tech-companies-1"
                      className="table table-striped table-bordered"
                    >
                      <Thead>
                        <Tr>
                          <Th>Meal Type Category</Th>
                          <Th data-priority="3">Filters</Th>
                          <Th data-priority="1">Recipes</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {/* {Object.keys(category).map(key => ( */}
                        {mealTypeKeyArray.map(key => (
                          <Tr key={key} className="px-5">
                            <Th>
                              <span className="co-name">
                                {this.state.mealTypeObj[key]?.name}
                              </span>
                            </Th>
                            <Td className="filter-td">
                              <div className="d-flex">

                                <label className="mr-0rem mt-2">Portion </label>
                                <select
                                  className="form-select w-50"
                                  onChange={e =>
                                    this.handlePortionChange(
                                      key,
                                      e.target.value
                                    )
                                  }
                                  value={this.state.selectedPortion[key] || ""}
                                // disabled={!this.state.selectedValues[key]}
                                >
                                  <option value="">Select protion</option>
                                  <option value={1}>1</option>
                                  <option value={2}>2</option>
                                  <option value={3}>3</option>
                                </select>
                              </div>

                              <div className="d-flex mt-2">
                                <label className="me-2 mt-2">Recipes</label>

                                <Multiselect
                                  options={this.getFilteredOptions(key)} // Use a function to provide filtered options
                                  selectedValues={
                                    this.state.selectedRecipes[key]
                                  } // Preselected value to persist in dropdown
                                  onSelect={(selectedList, selectedItem) =>
                                    this.onSelect(
                                      key,
                                      selectedList,
                                      selectedItem
                                    )
                                  } // Function will trigger on select event
                                  onRemove={(selectedList, removedItem) =>
                                    this.onRemove(
                                      key,
                                      selectedList,
                                      removedItem
                                    )
                                  } // Function will trigger on remove event
                                  displayValue="title" // Property name to display in the dropdown options
                                  showCheckbox={true}
                                  showArrow={true}
                                  ref={this.multiselectRef}
                                  // disablePreSelectedValues={false}
                                  disable={
                                    this.state.selectedRecipeByEvent[key]
                                      .length === 0 ||
                                    !this.state.selectedPortion[key]
                                  }
                                />
                                <i
                                  className="mdi mdi-checkbox-marked-circle font-size-22 text-success ms-2"
                                  id="markedcircletooltip"
                                  onClick={() => this.handleShowRecipe(key)}
                                ></i>
                              </div>
                            </Td>
                            <Td className="recipe-td">
                              {
                                // this.state.showRecipes[key] &&
                                this.state.selectedRecipes[key]?.length > 0 ? (
                                  this.state.selectedRecipes[key].map(
                                    (recipe, index) => (
                                      <div
                                        key={index}
                                        className="d-flex justify-content-between"
                                      >
                                        <span>{recipe.title}</span>
                                        <i
                                          className="mdi mdi-close font-size-18 text-danger"
                                          id="deletetooltip"
                                          onClick={() =>
                                            this.handleDeleteRecipe(key, index)
                                          }
                                        ></i>
                                      </div>
                                    )
                                  )
                                ) : this.state.selectedPortion[key] ? (
                                  <div className="d-flex justify-content-center">
                                    <span className="text-danger ms-2">
                                      Select Recipes to add them.
                                    </span>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-center">
                                    <span className="ms-2">
                                      Select a Value to get recipe.
                                    </span>
                                  </div>
                                )}
                            </Td>
                          </Tr>
                        ))}
                        <Tr>
                          <Th>Total</Th>
                          <Td colSpan={2} className="font-weight-bold">
                            {this.state.totalCalories} Calories
                          </Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="ms-1">
            <span className="text-danger">
              {this.state.errorMessage ? this.state.errorMessage : ""}
            </span>
          </div>
          <div className="d-flex justify-content-end">
            <Button
              color="primary me-2"
              className="font-16 btn-block btn btn-primary"
              onClick={() => this.handleSaveDiet()}
            >
              Save
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function generateOptions(unit) {
  const options = [];
  const { EX, CALORIES } = units;
  if (unit === EX) {
    for (let i = 1; i <= 50; i++) {
      options.push(i.toString()); // Convert to string
    }
  } else if (unit === CALORIES) {
    for (let i = 50; i <= 1000; i += 50) {
      options.push(i.toString()); // Convert to string
    }
  }
  return options;
}

export default ResponsiveTables;
