import React, { Component } from "react";
// import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS

class Loader extends Component {
  render() {
    return (
      <div
        className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center"
        style={{
          top: 0,
          left: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent grey background
          zIndex: 9999, // Higher z-index to appear above other elements
        }}
      >
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }
}

export default Loader;
