import React from "react";
import { Component } from "react";
import { Formik, Field, ErrorMessage, Form, FieldArray } from "formik";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { nutriInfo, portions } from "constants/diet";
import * as Yup from "yup"; // Import Yup
import {
  setShowItemModal,
  setItems,
  setNutriInfo,
} from "../../../store/actions"; // Import your action
import { connect } from "react-redux";

const validationSchema = Yup.object().shape({
  ...Object.keys(portions).reduce((acc, portion) => {
    acc[portion] = Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().required(`${portion}p, Item Name is required`),
          quantity: Yup.string().required(`${portion}p, Quantity is required`),
          calories: Yup.string().required(`${portion}p, Calories is required`),
        })
      )
      .test(
        `${portion}-at-least-one-item`,
        `At least one item is required for ${portions[portion]}`,
        value => value.length >= 1
      );

    acc[`${portion}-nutri`] = Yup.object().shape(
      Object.keys(nutriInfo).reduce((nutriAcc, nutriKey) => {
        nutriAcc[nutriKey] = Yup.string().required(
          `${portion}p, ${nutriKey} is required`
        );
        return nutriAcc;
      }, {})
    );

    return acc;
  }, {}),
});

class Items extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        1: [
          {
            name: "",
            quantity: "",
            calories: "",
          },
        ],
        2: [
          {
            name: "",
            quantity: "",
            calories: "",
          },
        ],
        3: [
          {
            name: "",
            quantity: "",
            calories: "",
          },
        ],
      },
      nutriData: {
        1: {},
        2: {},
        3: {},
      },
      validationError: "",
    };
  }

  handleSave = () => {
    // Here you can access the entire data and save it
  };

  handleAddRow = portion => {
    const data = { ...this.state.data };
    data[portion].push({
      name: "",
      quantity: "",
      calories: "",
    });
    this.setState({ data });
  };

  handleRemoveRow = (portion, index) => {
    const data = { ...this.state.data };
    data[portion].splice(index, 1);
    this.setState({ data });
  };

  handleDataChange = (portion, index, field, value) => {
    const data = { ...this.state.data };
    data[portion][index][field] = value;
    this.setState({ data });
  };

  handleNutriChange = (portion, nutriKey, value) => {
    const nutriData = { ...this.state.nutriData };
    nutriData[portion][nutriKey] = value;
    this.setState({ nutriData });
  };

  render() {
    const {
      setShowItemModal,
      setItems,
      setNutriInfo,
      editItems,
      moreItems,
      nutriInfoData,
    } = this.props;
    return (
      <Formik
        enableReinitialize
        initialValues={{
          ...Object.keys(portions).reduce((acc, portion) => {
            acc[portion] = editItems
              ? moreItems[portions[portion]]
              : this.state.data[portion];
            acc[`${portion}-nutri`] = editItems
              ? nutriInfoData[portions[portion]]
              : this.state.nutriData[portion];
            return acc;
          }, {}),
        }}
        onSubmit={async values => {
          // Handle form submission here
          try {
            await validationSchema.validate(values);
            setItems({
              oneP: values["1"],
              twoP: values["2"],
              threeP: values["3"],
            });
            setNutriInfo({
              oneP: values["1-nutri"],
              twoP: values["2-nutri"],
              threeP: values["3-nutri"],
            });
            setShowItemModal(false);
            // Dispatch the action to save the data to Redux or perform other actions
            // You can access the entire data in values and send it to the server.
          } catch (error) {
            this.setState({ validationError: error?.message });
          }
        }}
      >
        {({ values, errors, touched, handleSubmit, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <h3 className="text-danger item-validation-error">
              {this.state.validationError ? (
                <span>
                  {this.state.validationError}
                  <i
                    className="mdi mdi-close-box font-size-24 text-danger cursor-pointer ms-2"
                    onClick={() => this.setState({ validationError: "" })}
                  ></i>
                </span>
              ) : (
                ""
              )}
            </h3>
            {Object.keys(portions).map((portion, index) => (
              <>
                <h2 className="mb-3 mt-5">
                  {portion === "1" ? "1p" : portion === "2" ? "2p" : "3p"} :
                </h2>
                <div key={`${portion}-${index}`} className="d-flex">
                  <div className="w-75 me-3">
                    <Table className="table table-striped table-bordered">
                      <Thead>
                        <tr>
                          <th data-priority="1">Ingredients</th>
                          <th data-priority="1">Quantity (in g)</th>
                          <th data-priority="1">Calories (in cal)</th>
                          <th data-priority="1">Actions</th>
                        </tr>
                      </Thead>

                      <Tbody>
                        <FieldArray name={`${portion}`}>
                          {({ push, remove }) => (
                            <>
                              {values[`${portion}`].map((obj, index) => (
                                <Tr key={`${portion}-${index}`}>
                                  <Td>
                                    <Field
                                      type="text"
                                      name={`${portion}[${index}].name`}
                                      value={
                                        values[`${portion}`][`${index}`]["name"]
                                      }
                                      onChange={handleChange}
                                      className={
                                        "form-control" +
                                        (errors[`${portion}[${index}].name`] &&
                                        touched[`${portion}[${index}].name`]
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name={`${portion}[${index}].name`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Td>
                                  <Td>
                                    <Field
                                      type="text"
                                      name={`${portion}[${index}].quantity`}
                                      value={
                                        values[`${portion}`][`${index}`][
                                          "quantity"
                                        ]
                                      }
                                      onChange={handleChange}
                                      className={
                                        "form-control recipe-time" +
                                        (errors[
                                          `${portion}[${index}].quantity`
                                        ] &&
                                        touched[`${portion}[${index}].quantity`]
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name={`${portion}[${index}].quantity`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Td>
                                  <Td>
                                    <Field
                                      type="text"
                                      name={`${portion}[${index}].calories`}
                                      value={
                                        values[`${portion}`][`${index}`][
                                          "calories"
                                        ]
                                      }
                                      onChange={handleChange}
                                      className={
                                        "form-control recipe-time" +
                                        (errors[
                                          `${portion}[${index}].calories`
                                        ] &&
                                        touched[`${portion}[${index}].calories`]
                                          ? " is-invalid"
                                          : "")
                                      }
                                    />
                                    <ErrorMessage
                                      name={`${portion}[${index}].calories`}
                                      component="div"
                                      className="invalid-feedback"
                                    />
                                  </Td>
                                  <Td>
                                    <i
                                      className="mdi mdi-minus-box font-size-24 text-danger"
                                      onClick={() => remove(index)}
                                    ></i>
                                  </Td>
                                </Tr>
                              ))}
                              {/* <Tr>
                                <Th>Total</Th>
                                <Td>0</Td>
                                <Td>0</Td>
                                <Td>-</Td>
                              </Tr> */}
                              <div className="d-flex justify-content-end mb-5 position-absolute mt-3">
                                <button
                                  type="button"
                                  className="btn btn-success me-2"
                                  onClick={() => {
                                    push({
                                      name: "",
                                      quantity: "",
                                      calories: "",
                                    });
                                  }}
                                >
                                  Add Row
                                </button>
                              </div>
                            </>
                          )}
                        </FieldArray>
                      </Tbody>
                    </Table>
                  </div>
                  <div className="w-50">
                    <Table className="table table-striped table-bordered">
                      <Thead>
                        <Tr>
                          <Th data-priority="1">Nutri Info</Th>
                          <Th data-priority="1">Amount in (g)</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {Object.keys(nutriInfo).map(nutriKey => (
                          <Tr key={nutriKey}>
                            <Td>
                              <Th data-priority="1">{nutriInfo[nutriKey]}</Th>
                            </Td>
                            <Td>
                              <Field
                                type="number"
                                name={`${portion}-nutri.${nutriKey}`}
                                value={
                                  values[`${portion}-nutri`][nutriKey] || ""
                                }
                                className={
                                  "form-control" +
                                  (errors[`${portion}.nutri.${nutriKey}`] &&
                                  touched[`${portion}.nutri.${nutriKey}`]
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name={`${portion}.nutri.${nutriKey}`}
                                component="div"
                                className="invalid-feedback"
                              />
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              </>
            ))}
            <div className="mt-2 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary me-2"
                onClick={this.handleSave}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setShowItemModal(false)}
              >
                Close
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = state => {
  return {
    showItemModal: state.Recipe.showItemModal,
    moreItems: state.Recipe.items,
    nutriInfoData: state.Recipe.nutriInfo,
    editItems: state.Recipe.editItems,
  };
};

const mapDispatchToProps = {
  setShowItemModal, // This connects the setIngredients action to your component
  setItems,
  setNutriInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Items);
