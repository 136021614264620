export const userRoles = {
  ADMIN: "admin",
  DIETITIAN: "dietitian",
  USER: "user",
};


export const languages = {
  AR: 'arabic',
  EN: 'english'
}