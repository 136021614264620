import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";

import images from "assets/images";
import emptyContacts from "../../assets/images/emptyContact.png";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import DeleteModal from "components/Common/DeleteModal";

import {
  getUsers,
  addNewUser,
  updateUser,
  deleteUser,
} from "store/contacts/actions";

import { isEmpty, size, map } from "lodash";
import { axiosDel, axiosGet, axiosPatch, axiosPost } from "helpers/api_helpers";
import { languages, userRoles } from "constants/userRole";
import { LIMIT } from "../../constants/pagination";
import Languages from "./Languages";
import Categories from "./Categories";
import MealTypes from "./MealTypes";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.node = React.createRef();
  }

  onPaginationPageChange(page) {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  }

  render() {
    //meta title
    document.title = "Settings";

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Diet Delights" breadcrumbItem="Settings" />
            <Row>
              <Col lg="4" className="mh-75vh">
                <Languages />
              </Col>
              <Col lg="4" className="mh-75vh">
                <Categories />
              </Col>
              <Col lg="4" className="mh-75vh">
                <MealTypes />
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Settings.propTypes = {
  users: PropTypes.array,
  className: PropTypes.any,
};

const mapStateToProps = ({ contacts }) => ({
  users: contacts.users,
});

const mapDispatchToProps = dispatch => ({
  onGetUsers: () => dispatch(getUsers()),
  onAddNewUser: user => dispatch(addNewUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onDeleteUser: user => dispatch(deleteUser(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Settings));
