import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import DeleteModal from "components/Common/DeleteModal";

class ItemTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: false,
      deleteItem: {},
    };
  }

  componentDidMount() {
    const dietId = this.props.dietId;

    if (dietId) {
      this.getDietById(dietId);
    }
  }

  render() {
    // document.title = "Item List | Diet-Delights";

    const items = this.props.items;

    return (
      <React.Fragment>
        <DeleteModal
          show={this.state.deleteModal}
          onDeleteClick={() => {
            this.props.onDeleteItem(this.state.deleteItem),
              this.setState({ deleteModal: false });
          }}
          onCloseClick={() => this.setState({ deleteModal: false })}
        />
        {items.length > 0 ? (
          <div className="">
            <div className="container-fluid">
              <Row>
                <Col>
                  <div className="table-rep-plugin">
                    <div
                      className="table-responsive mb-0 item-table-div"
                      data-pattern="priority-columns"
                    >
                      <Table className="table item-table table-bordered ">
                        <Thead>
                          <Tr>
                            <Th
                              data-priority="1"
                              className="text-center text-light fs-5"
                              rowSpan={2}
                            >
                              Item Name
                            </Th>
                            <Th
                              data-priority="2"
                              // colSpan={2}
                              className="text-center text-light fs-5"
                            >
                              1p
                            </Th>
                            <Th
                              data-priority="2"
                              // colSpan={2}
                              className="text-center text-light fs-5"
                            >
                              2p
                            </Th>

                            <Th
                              data-priority="2"
                              // colSpan={2}
                              className="text-center text-light fs-5"
                            >
                              3p
                            </Th>
                            <Th
                              data-priority="2"
                              className="text-center text-light fs-5"
                            >
                              Action
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr className="px-5">
                            <Th></Th>
                            {/* <Td className="text-center fw-bold fs-1rem">Ex</Td> */}
                            <Td
                              className="text-center fw-bold fs-1rem"
                              // colSpan={2}
                            >
                              Calories
                            </Td>
                            {/* <Td className="text-center fw-bold fs-1rem">Ex</Td> */}
                            <Td className="text-center fw-bold fs-1rem">
                              Calories
                            </Td>
                            {/* <Td className="text-center fw-bold fs-1rem">Ex</Td> */}
                            <Td className="text-center fw-bold fs-1rem">
                              Calories
                            </Td>
                            <Td></Td>
                          </Tr>
                          {items.map((item, index) => (
                            <Tr className="px-5" key={item?.itemName}>
                              <Th className="text-center ">
                                <span className="co-name">
                                  {item?.itemName}
                                </span>
                              </Th>
                              {/* <Td className="text-center">{item?.onePEx}</Td> */}
                              <Td className="text-center">
                                {item?.onePCalories}
                              </Td>
                              {/* <Td className="text-center">{item?.twoPEx}</Td> */}
                              <Td className="text-center">
                                {item?.twoPCalories}
                              </Td>
                              {/* <Td className="text-center">{item?.threePEx}</Td> */}
                              <Td className="text-center">
                                {item?.threePCalories}
                              </Td>
                              <Td className="text-center ">
                                <span
                                  className="me-3"
                                  onClick={() => this.props.onEditItem(item)}
                                >
                                  <i
                                    className="text-success mdi mdi-pencil font-size-18"
                                    id="edittooltip"
                                  ></i>
                                </span>
                                <span
                                  className=""
                                  onClick={() =>
                                    this.setState({
                                      deleteModal: true,
                                      deleteItem: item,
                                    })
                                  }
                                >
                                  <i
                                    className="text-danger mdi mdi-delete font-size-18"
                                    id="deletetooltip"
                                  ></i>
                                </span>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}

export default ItemTable;
