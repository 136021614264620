import { SET_EDIT_ITEMS, SET_INGREDIENTS, SET_IS_LOADING, SET_ITEMS, SET_NUTRI_INFO, SET_SHOW_ITEM_MODAL } from "./actionTypes";

export const setIngredients = (ingredients) => {
    return {
        type: SET_INGREDIENTS,
        payload: ingredients,
    };
}

export const setShowItemModal = (bool) => {
    return {
        type: SET_SHOW_ITEM_MODAL,
        payload: bool,
    };
}
export const setItems = (items) => {
    return {
        type: SET_ITEMS,
        payload: items,
    };
}
export const setNutriInfo = (info) => {
    return {
        type: SET_NUTRI_INFO,
        payload: info,
    };
}
export const editItems = (bool) => {
    return {
        type: SET_EDIT_ITEMS,
        payload: bool,
    };
}
export const setIsLoading = (bool) => {
    return {
        type: SET_IS_LOADING,
        payload: bool,
    };
}
