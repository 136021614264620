import { nutriInfo } from "constants/diet"
import { SET_EDIT_ITEMS, SET_INGREDIENTS, SET_IS_LOADING, SET_ITEMS, SET_NUTRI_INFO, SET_SHOW_ITEM_MODAL } from "./actionTypes"


const INIT_STATE = {
    projects: [],
    projectDetail: {},
    error: {},
    ingredients: "",
    showItemModal: false,
    items: "",
    nutriInfo: "",
    editItems: false,
    isLoadingPdf: false
}

const recipes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_INGREDIENTS:
            return {
                ...state,
                ingredients: action.payload,
            }
        case SET_SHOW_ITEM_MODAL:
            return {
                ...state,
                showItemModal: action.payload,
            }
        case SET_ITEMS:
            return {
                ...state,
                items: action.payload,
            }
        case SET_NUTRI_INFO:
            return {
                ...state,
                nutriInfo: action.payload,
            }
        case SET_EDIT_ITEMS:
            return {
                ...state,
                editItems: action.payload,
            }
        case SET_IS_LOADING:
            return {
                ...state,
                isLoadingPdf: action.payload,
            }

        default:
            return state
    }
}

export default recipes
